/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 12-07-2024
 * @description 
 */
import { useCallback, useRef } from "react";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 12-07-2024
 * @description 
 * @param {}
 * @return {}
 */
const useThrottle = (callback, delay) => {
    const lastCall = useRef(0);

    return useCallback((...args) => {
        const now = new Date().getTime();

        if (now - lastCall.current >= delay) {
            lastCall.current = now;
            callback(...args);
        }
    }, [callback, delay]);
};

export default useThrottle;