/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @date 06-07-2024
 * @description This file is to handle all type of actions & handle the toast message for response as well
 */

import { toast } from "react-toastify";
import customConsole from "../../config/customConsole";
import useAxios from "../../hooks/useAxios";
const baseURL = process.env.REACT_APP_BASE_URL;
/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @date 06-07-2024
 * @description Function to display the toast message
 * @param {type,message}
 * @returns {toast message}
 */
export function showToastMessage({ type, message }) {
  switch (type) {
    case "info":
      toast.info(message);
      break;
    case "warn":
      toast.warn(message);
      break;
    case "success":
      toast.success(message);
      break;
    case "error":
      toast.error(message);
      break;
    default:
      toast(message);
  }
}
/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @date 06-07-2024
 * @description Handle different type of actions with the useAxios custom Hook
 * @param {api, https_method, params, show_toast, action_type, is_file}
 * @returns {dispatch} dipatch the action to the reducer
 */
export function HandleApiActions(props) {
  const api = useAxios();
  customConsole("-- HandleApiActions --");
  customConsole(props);
  let resp = { error: true, msg: "", data: { error: true, msg: "", data: {} } };
  return async (dispatch) => {
    try {
      let apiActionPerformed = true;
      //Based on type of API perform the action
      switch (props.https_method) {
        case "GET":
          if (props.is_file) {
            resp = await api.get(`${baseURL}` + props.api_name, {
              params: { filename: props.file_name },
              responseType: "blob", // Important for handling binary data
            });
          } else {
            resp = await api.get(`${baseURL}` + props.api_name, {
              params: props.params,
            });
          }

          break;
        case "PUT":
          resp = await api.put(`${baseURL}` + props.api_name, {
            ...props.params,
          });
          break;
        case "POST":
          if (props.is_file) {
            let file = new FormData();
            file.append("name", "filename");
            file.append("filename", props.file);
            resp = await api.post(`${baseURL}` + props.api_name, file);
          } else {
            resp = await api.post(`${baseURL}` + props.api_name, {
              ...props.params,
            });
          }
          break;
        default:
          apiActionPerformed = false;
          break;
      }
      if (props.action_type) {
        if (props.is_file && props.https_method === "GET") {
          // Create a URL for the image blob data
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          if (props.action_type) {
            dispatch({
              type: props.action_type,
              payload: { error: false, msg: "File downloaded", file_path: url },
            });
          } else {
            return resp.data;
          }
        } else {
          if (props.action_type) {
            dispatch({
              type: props.action_type,
              payload: resp.data,
            });
          } else {
            return resp.data;
          }
        }
      }
      if (apiActionPerformed) {
        if (props.show_toast && resp.data.msg) {
          showToastMessage({
            type: resp.data.error ? "error" : "success",
            message: resp.data.msg,
          });
        }
      }
      // if (!props.action_type) {
        return resp.data;
      // }
    } catch (error) {
      customConsole("-- API catch block --");
      customConsole(error);
      if (
        props.show_toast &&
        error.response &&
        error.response.data &&
        error.response.data.msg
      ) {
        showToastMessage({ type: "error", message: error.response.data.msg });
      }
      return resp.data;
    }
    return resp.data;
  };
}
/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @date 31/07/2024
 * @description API handler to get Multiple Images at once
 * @param {[{id:1, image:""}]}
 * @returns {details:[{id:1, image:"", url:""}]}
 */
export async function GetMultipleImages(props) {
  const api = useAxios();
  customConsole("-- GetMultipleImages --");
  customConsole(props);
  try {
    const result = [];
    //Based on type of API perform the action
    for (const imageDetails of props) {
      let resp = { data: "" };
      if (imageDetails.image.length) {
        resp = await api.get(`${baseURL}/UCM/files/details`, {
          params: { filename: imageDetails.image },
          responseType: "blob", // Important for handling binary data
        });
      }
      result.push({
        id: imageDetails.id,
        image: imageDetails.image,
        url: resp?.data
          ? window.URL.createObjectURL(new Blob([resp.data]))
          : "",
      });
    }
    return result;
  } catch (error) {
    customConsole("-- API catch block --");
    customConsole(error);
    if (
      props.show_toast &&
      error.response &&
      error.response.data &&
      error.response.data.msg
    ) {
      showToastMessage({ type: "error", message: error.response.data.msg });
    }
  }
}
/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @date 31/07/2024
 * @description API handler to get Single Image at once
 * @param { image:""}
 * @returns {url:""}}
 */
export async function GetSingleImages(props) {
  const api = useAxios();
  customConsole("-- GetSingleImages --");
  customConsole(props);
  try {
    //Based on type of API perform the action
    const resp = await api.get(`${baseURL}/UCM/files/details`, {
      params: { filename: props.image },
      responseType: "blob", // Important for handling binary data
    });
    return resp?.data ? window.URL.createObjectURL(new Blob([resp.data])) : "";
  } catch (error) {
    customConsole("-- API catch block --");
    customConsole(error);
    if (
      props.show_toast &&
      error.response &&
      error.response.data &&
      error.response.data.msg
    ) {
      showToastMessage({ type: "error", message: error.response.data.msg });
    }
  }
}
