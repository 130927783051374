/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 10-09-2024
 * @description : Reusable filter component
 */

import React from "react";
import { Box, Menu, Typography, Divider, IconButton } from "@mui/material";
import { SelectTextFieldN } from "../index";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

function CustomFilterIconN({
  anchorEl,
  open,
  handleClose,
  handleFilterIconClick,
  // SelectTextFieldN
  size,
  label,
  variant,
  required,
  disabled,
  optionsList,
  selectedValue,
  sltFldStyle,
  handleOnChange,
}) {
  return (
    <Box sx={styles.mainContainer}>
      <IconButton
        onClick={handleFilterIconClick}
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <FilterAltIcon sx={{ color: "" }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 12,
          sx: {
            mt: 0.4,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography sx={{ ...styles.heading, ml: "10px" }}>Filter</Typography>
        <Divider />
        {/* Sort fields */}
        <Box sx={styles.container}>
          <Box>
            <SelectTextFieldN
              size={size}
              label={label}
              variant={variant}
              optionsList={optionsList}
              selectedValue={selectedValue}
              handleOnChange={handleOnChange}
              sltFldStyle={sltFldStyle}
            />
          </Box>
        </Box>
      </Menu>
    </Box>
  );
}

export default CustomFilterIconN;

// Styling
const styles = {
  mainContainer: {
    // mr: "10px ",
  },
  container: {
    py: 1.4,
    px: 1.4,
  },
  heading: {
    fontWeight: "bold",
  },
};
