import customConsole from "../../config/customConsole";
import actionType from "../actionTypes";
/**
 * @author : Abdul Rahim M - abdul@au79consulting.com
 * @Date : 04-07-2024
 * @description Reducer function for Admin Settings related actions and state changes.
 */
const initialState = {
  error: false,
  msg: "",
  adminSettings: {},
};
/**
 * @author : Abdul Rahim M - abdul@au79consulting.com
 * @Date : 04-07-2024
 * @description
 * @param {}
 * @return New state after processing the action
 */
const adminSettingsReducer = (state = initialState, action) => {
  customConsole(action);
  switch (action.type) {
    case actionType.GET_ADMIN_SETTINGS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        adminSettings: action.payload,
      };

    default:
      return state;
  }
};

export default adminSettingsReducer;
