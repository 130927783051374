/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @date 11-06-2024
 * @description If user trying to enter some wrong URL -> Show the UI as Page not found
 */

//Importing the required components/functions from the libraries
import React from "react";

//Main Component
function PageNotFound() {
  return (
    <div>
      <h1>PageNotFound</h1>
    </div>
  );
}

export default PageNotFound;
