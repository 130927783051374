/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-06-2024
 * @description Reusable text field component.
 */

import { Box, Input, TextField, Typography } from "@mui/material";
import React from "react";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-06-2024
 * @description Reusable text field component.
 * @param { size, label, placeholder, value, setValue, isMultiline = false, rows = 1, txtFldStyle }
 * @return The rendered text field component.
 */
const CustomTextField = ({
  size,
  label,
  placeholder,
  variant,
  value,
  setValue,
  isMultiline = false,
  minRows = 1,
  maxRows = 3,
  inputProps,
  txtFldStyle,
}) => {
  // Handles change events for the text field.
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <Box>
      {/* <Typography variant="body1" sx={styles.txtLbs}>
        {label}
      </Typography> */}

      <TextField
        fullWidth
        size={size}
        label={label}
        variant={variant}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        multiline={isMultiline}
        minRows={minRows}
        maxRows={maxRows}
        InputProps={inputProps}
        sx={txtFldStyle}
      />
    </Box>
  );
};

export default CustomTextField;

// Styling for the CustomTextField component
const styles = {
  txtLbs: {
    textAlign: "start",
    mt: 1,
    mb: 0.5,
    pl: 0.5,
  },
};
