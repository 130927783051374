import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { HandleApiActions, projectConfigAPI } from "../../../redux/actions";
import { CustomButton, SearchSelectTextField } from "../../../components";
import VariantDetailsTab from "./VariantDetailsTab";
import ProjectDetails from "./ProjectDetails";
import AddIcon from "@mui/icons-material/Add";
import CreateVariant from "./CreateVariant";

function ViewDialogCopy({ projectId }) {
  const projectDetails = useSelector((state) => state.projectConfigReducer);
  console.log("projectDetails", projectDetails?.projectConfig);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //1.React useState
  const [selectVariant, setSelectVariant] = useState(null);
  const [openCreateVariant, setOpenCreateVariant] = useState(false);

  useEffect(() => {
    dispatch(HandleApiActions({ ...projectConfigAPI.get_project }));
  }, []);

  const selectedProjectDetails = useMemo(() => {
    if (projectDetails?.projectConfig?.length > 0) {
      return projectDetails.projectConfig.find(
        (data) => data?.project_id == projectId
      );
    }
    return null;
  }, [projectDetails, projectId]);

  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <CustomButton
          startIcon={<ArrowBackIcon />}
          size={"medium"}
          variant={"contained"}
          btnName={"Back"}
          handleOnClick={() => navigate(`/dashboard/project-config`)}
        />
      </Box>

      <Box
        sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}
      >
        <CustomButton
          startIcon={<AddIcon />}
          size={"medium"}
          variant={"contained"}
          btnName={"Add Variant"}
          handleOnClick={() => setOpenCreateVariant(true)}
        />
      </Box>

      <Box sx={{ padding: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item>
              <ProjectDetails />
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <SearchSelectTextField
                size={"small"}
                label={"Search Variant"}
                placeholder={"Search Variant"}
                noOptionsText={"No variant found"}
                // optionsList={projectOptionsList}
                selectedValue={selectVariant}
                setSelectedValue={setSelectVariant}
                txtFldStyle={{
                  width: "215px",
                  backgroundColor: "white",
                }}
              />
              <CustomButton
                startIcon={<AddIcon />}
                size={"medium"}
                variant={"contained"}
                btnName={"Recreate Project"}
                //handleOnClick={() => navigate(`/dashboard/project-config`)}
              />
            </Box>

            <Item sx={{ mt: "10px" }}>xs=4</Item>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <VariantDetailsTab />
            </Item>
          </Grid>
        </Grid>
      </Box>
      {openCreateVariant && (
        <CreateVariant
          openCreateVariant={openCreateVariant}
          setOpenCreateVariant={setOpenCreateVariant}
        />
      )}
    </div>
  );
}

export default ViewDialogCopy;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  boxShadow: theme.shadows[3], // Add shadow with default level 3, you can adjust as needed
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
    boxShadow: theme.shadows[6], // Different shadow for dark mode (stronger shadow)
  }),
}));
