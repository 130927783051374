/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 08-07-2024
 * @description Reusable table withe multiple filter and sorting options
 */
import { styled, Typography } from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import React from "react";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal.css-1rtad1": {
    display: "block",
  },
  "& .MuiDataGrid-columnHeaders": {
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
    },
  },
  "& .MuiDataGrid-row": {
    cursor: "default",
  },
  // Disable the data-grid pagination footer
  "& .MuiDataGrid-footerContainer": {
    display: "none !important",
  },
  "& .MuiDataGrid-row.rowUnread": {
    backgroundColor: "#f0f0f0", // Grey color for read notifications
  },
  "& .MuiDataGrid-row.rowRead": {
    backgroundColor: "white", // Normal color for unread notifications
  },
}));

// const CustomToolbar = ({ heading }) => (
//   <GridToolbarContainer>
//     <GridToolbar
//       printOptions={{
//         disableToolbarButton: true, // Disable the print option
//       }}
//     />
//     {heading && <Typography sx={styles.tableHeading}>{heading}</Typography>}
//   </GridToolbarContainer>
// );

const CustomToolbar = () => (
  <GridToolbarContainer>
    {/* <GridToolbarExport
      printOptions={{
        disableToolbarButton: true, // Disable the print option
      }}
    /> */}
  </GridToolbarContainer>
);

function CustomDataGridTableB({
  tableRowsData,
  columns,
  handleTableRowClick,
  isMultiLine = false,
  tableHeading,
  disableDensity,
  getRowClassName,
}) {
  return (
    <StyledDataGrid
      localeText={{ toolbarDensity: "View" }}
      // autoHeight={true}
      rows={tableRowsData}
      columns={columns}
      getRowHeight={isMultiLine ? (params) => "auto" : undefined}
      // autoPageSize
      disableColumnMenu
      disableDensity={disableDensity}
      // disableDensitySelector={isMultiLine ? (params) => "auto" : undefined}
      // disableColumnSorting
      disableColumnSelector
      disableColumnFilter
      disableRowSelectionOnClick
      // slots={{ toolbar: GridToolbar }}
      slots={{ toolbar: () => <CustomToolbar heading={tableHeading} /> }}
      slotProps={{
        toolbar: { printOptions: { disableToolbarButton: true } },
      }}
      onRowClick={(params) => handleTableRowClick({ params })}
      getRowClassName={getRowClassName} // Use the prop here
      sx={{
        "& .MuiDataGrid-cell": {
          ...(isMultiLine && {
            py: 1, // Add padding to cells
          }),
          borderBottom: "1px solid #e0e0e0", // Add border to cells
        },
      }}
    />
  );
}

export default CustomDataGridTableB;

// Styles
const styles = {
  tableHeading: {
    fontSize: "18px",
    fontWeight: "bold",
    flexGrow: 1,
  },
};

// /**
//  * @author : Narender - narender@au79consulting.com
//  * @Date : 08-07-2024
//  * @description Reusable table withe multiple filter and sorting options
//  */
// import styled from "@mui/system/styled";
// import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import React from "react";

// const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
//   "& .MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal.css-1rtad1": {
//     display: "block",
//   },
//   "& .MuiDataGrid-columnHeaders": {
//     "& .MuiDataGrid-columnHeaderTitle": {
//       fontWeight: "bold",
//     },
//   },
//   "& .MuiDataGrid-row": {
//     cursor: "pointer",
//   },
//   // Disable the data-grid pagination footer
//   "& .MuiDataGrid-footerContainer.MuiDataGrid-withBorderColor.css-wop1k0-MuiDataGrid-footerContainer":
//     {
//       display: "none",
//     },
// }));

// function CustomDataGridTable({
//   tableRowsData,
//   checkboxSelection,
//   columns,
//   handleTableRowClick,
//   onSelectionModelChange,
//   disableSelectionOnClick,
//   isMultiLine = false,
// }) {
//   return (
//     <StyledDataGrid
//       localeText={{ toolbarDensity: "View" }}
//       checkboxSelection={checkboxSelection}
//       onSelectionModelChange={onSelectionModelChange}
//       disableSelectionOnClick={disableSelectionOnClick}
//       // autoHeight={true}
//       rows={tableRowsData}
//       columns={columns}
//       getRowHeight={isMultiLine ? (params) => "auto" : undefined}
//       // autoPageSize
//       disableColumnMenu
//       disableDensitySelector={isMultiLine ? (params) => "auto" : undefined}
//       // disableColumnSorting
//       disableColumnSelector
//       disableRowSelectionOnClick
//       slots={{ toolbar: GridToolbar }}
//       slotProps={{
//         toolbar: { printOptions: { disableToolbarButton: true } },
//       }}
//       onRowClick={(params) => handleTableRowClick({ params })}
//       sx={{
//         "& .MuiDataGrid-cell": {
//           ...(isMultiLine && {
//             py: 1, // Add padding to cells
//           }),
//           borderBottom: "1px solid #e0e0e0", // Add border to cells
//         },
//       }}
//     />
//   );
// }

// export default CustomDataGridTable;
