/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 30-08-2024
 * @description : Reusable functions
 */

const helper = {
    // Get the key by the value of a object
    getObjectKeyByValue: (object, value) => {
        // Check if the object is valid (not null or undefined), otherwise return "-"
        if (!object || typeof object !== 'object') {
            return "-";
        }

        return Object.keys(object)?.find(key => object[key] === value) || "-";
    },
    // Get the value by the key of a array of objects
    getValueByKey: (array, key) => {
        const result = array.find(item => item.key === key);
        return result ? result.value : "-";
    },
    // Function to convert the structure
    convertKeyValueToLabel: (array) => {
        // Check if the array is valid (not null or undefined and is an array), otherwise return an empty array
        if (!Array.isArray(array)) {
            return [];
        }

        return array?.map(item => ({
            value: item.key,
            label: item.value
        }));
    },
    // Function to convert the structure
    convertKeyValueToLabelFiltered: (array, removeList) => {
        // Check if the array is valid (not null or undefined and is an array), otherwise return an empty array
        if (!Array.isArray(array)) {
            return [];
        }

        // Ensure removeList is an array
        removeList = Array.isArray(removeList) ? removeList : [];

        return array
            ?.filter(item => item.key !== undefined && !removeList.includes(item.key))
            ?.map(item => ({
                value: item.key,
                label: item.value
            }));
    },
    getUserDetailsByIds: (usersDetailsList, userIds) => {
        // Ensure that the usersDetailsList, userIds is a valid array, otherwise return an empty array
        if (!Array.isArray(usersDetailsList) || !Array.isArray(userIds)) {
            return [];
        }
    
        // Filter the user details list to get only the users that match the provided user IDs
        return usersDetailsList?.filter(user => userIds?.includes(user?.user_id));
    },
}

export default helper;

