/**
 * @author : Narender - narender@au79consulting.com, Abdul - abdul@au79consulting.com
 * @Date : 19-10-2024
 * @description : Show the linked ee to legal list
 */
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

function LinkedEEToLegalTable({ selectedEELinkedLRList }) {
  console.log("-->Software LR<---", selectedEELinkedLRList);
  return (
    <div>
      {" "}
      <Paper
        elevation={24}
        sx={{
          mt: "40px",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px",
          border: "1px solid black", // Add border here
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            boxShadow: 2,
            mt: "20px",
            padding: "10px",
            // Add border here
          }}
        >
          <Paper
            sx={{
              display: "flex",
              padding: "10px",
              border: "1px solid black",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              Selected EE Component
            </Typography>
            <strong>:</strong>
            <Typography>{selectedEELinkedLRList?.[0]?.description}</Typography>
          </Paper>
          <Paper
            sx={{
              display: "flex",
              padding: "10px",
              border: "1px solid black",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>Version</Typography>
            <strong>:</strong>
            <Typography> {selectedEELinkedLRList?.[0]?.version}</Typography>
          </Paper>
        </Box>

        <TableContainer
          component={Paper}
          sx={{
            mt: "20px",
            maxHeight: "300px",
            overflowY: "auto",
            border: "1px solid black",
          }} // Add border here
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid black",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>S.No</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid black",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Legal Standard
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid black",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>Chapter</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid black",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Legal Requirement
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedEELinkedLRList?.[0]?.linked_lr_details?.length > 0 ? (
                selectedEELinkedLRList?.[0]?.linked_lr_details?.map(
                  (linkedModule, linkedIdx) => (
                    <TableRow>
                      <TableCell sx={{ border: "1px solid black" }}>
                        <Typography>{linkedIdx + 1}</Typography>
                      </TableCell>

                      <TableCell sx={{ border: "1px solid black" }}>
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Name
                          </Typography>
                          <strong>:</strong>
                          <Typography>
                            {linkedModule?.ls_details?.name}
                          </Typography>
                        </Box>

                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Number
                          </Typography>
                          <strong>:</strong>
                          <Typography>
                            {linkedModule?.ls_details?.standard_number}
                          </Typography>
                        </Box>

                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Revision
                          </Typography>
                          <strong>:</strong>
                          <Typography>
                            {linkedModule?.ls_details?.revision}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ border: "1px solid black" }}>
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Chapter Name
                          </Typography>
                          <strong>:</strong>
                          <Typography>{linkedModule?.chapter}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ border: "1px solid black" }}>
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Number
                          </Typography>
                          <strong>:</strong>
                          <Typography>{linkedModule?.req_number}</Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Description
                          </Typography>
                          <strong>:</strong>
                          <Typography>{linkedModule?.description}</Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                )
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography textAlign="center" sx={{ color: "red" }}>
                    Data Not Found
                  </Typography>
                </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default LinkedEEToLegalTable;
