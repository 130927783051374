import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  CustomAddDialog,
  CustomButton,
  CustomTextFieldN,
  SelectTextField,
} from "../../components";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { adminSettingsAPI, HandleApiActions } from "../../redux/actions";
function DeliveryMarket({ open, handleClick }) {
  const dispatch = useDispatch();
  const DMDetails = useSelector((state) => state.adminSettingsReducer);
  const [name, setName] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [nameError, setNameError] = useState("");
  console.log("DMDetails", DMDetails);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };
  const handleDialogClose = () => {
    setIsDialogOpen(false);

    setName("");
  };
  //   const isFormValid = () => {
  //     return ZoneName?.length > 0 && selectedPosition;
  //   };

  const handleAddDeliveryMarketName = () => {
    const reqObj = {
      current_time: moment().format(),
      name: name,
    };
    console.log("----reqObj----", reqObj);
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.add_dellivery_market_details,
        show_toast: true,
        params: reqObj,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...adminSettingsAPI.get_admin_settings,
          show_toast: false,
        })
      );
    });
    handleDialogClose();
  };

  const handleRemoveMarket = (index) => {
    const reqObj = {
      current_time: moment().format(),
      name: index,
    };
    console.log("reqObjj", reqObj);
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.delete_deliver_market_detials,
        show_toast: true,
        params: reqObj,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...adminSettingsAPI.get_admin_settings,
          show_toast: false,
        })
      );
    });
  };

  return (
    <div>
      <List
        sx={styled.list}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={() => handleClick("7")}>
          <ListItemText
            sx={styled.listText}
            primary="Delivery Markets"
            primaryTypographyProps={styled.typographyProps}
          />
          {open === "7" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open === "7"} timeout="auto" unmountOnExit>
          <Divider sx={{ margin: "10px" }} />
          <List component="div" disablePadding sx={styled.mainList}>
            <Box sx={styled.tblFun}>
              <CustomButton
                size={"medium "}
                variant={"contained"}
                btnName={"Add "}
                startIcon={<AddIcon />}
                handleOnClick={handleDialogOpen}
                btnStyle={{
                  ml: 2,
                }}
              />
            </Box>
            <Box sx={styled.zoneBox}>
              {DMDetails?.adminSettings?.data?.product_delivery_markets?.map(
                (data) => {
                  return (
                    <>
                      <Box sx={styled.subZoneBox}>
                        <Typography padding="7px">{data}</Typography>
                        <IconButton
                          onClick={() => handleRemoveMarket(data)}
                          size="small"
                          sx={{ color: "red" }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </>
                  );
                }
              )}
            </Box>
          </List>
        </Collapse>
      </List>
      <CustomAddDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        dialogTitle={"Add Delivery Market"}
        dialogContent={
          <Stack spacing={4} mb={2} width="220px">
            <CustomTextFieldN
              fldType={"text0"}
              size={"small"}
              label={"Name*"}
              placeholder={"Name"}
              type={"text"}
              value={name}
              setValue={setName}
              valueError={nameError}
              setValueError={setNameError}
              variant={"standard"}
            />
          </Stack>
        }
        onConfirm={handleAddDeliveryMarketName}
        onDisabled={name?.trim()?.length > 2 ? false : true}
      />
    </div>
  );
}

export default DeliveryMarket;

const styled = {
  list: {
    width: "100%",
    marginTop: "15px",
    backgroundColor: "whitesmoke",
    boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.2)",
    borderRadius: "0px",
    maxWidth: "100%",
  },
  listText: {
    textAlign: "start",

    color: "black",
  },
  typographyProps: {
    fontWeight: 600,
    fontSize: "18px",
  },
  mainList: {
    background: "whitesmoke",
    margin: "10px",
    padding: "10px",
    borderRadius: "10px",
    overflowY: "auto",
    maxHeight: "500px",
  },
  mainBox: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px",
  },
  zoneBox: {
    display: "flex",
    flexWrap: "wrap",
    gap: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  subZoneBox: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "0px",
    backgroundColor: "green",
    alignItems: "center",
    justifyContent: "center",

    color: "white",
    fontWeight: "bold",
  },
  notFound: {
    color: "red",
    fontSize: "12px",
    fontWeight: "bold",
  },
  tblFun: {
    mb: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};
