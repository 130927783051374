/**
 * @author Narender - narender@au79consulting.com
 * @date 13-06-2024
 * @description Defines constants and configurations for various functionalities in the application.
 */

const appMeta = {
  httpStatus: {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  },
  authView: {
    LOGIN: 100,
    SIGNUP: 200,
    SIGNUP_VERIFY_OTP: 300,
    FORGOT_PASSWORD: 400,
    RESET_PASSWORD: 500,
  },
  textFieldTypes: {
    FULL_NAME: 100,
    EMAIL: 200,
    PASSWORD: 300,
    AUTHPASSWORD: 400,
    CONFIRM_PASSWORD: 500,
    OTP: 600,
  },
};

export default appMeta;
