/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 16-08-2024
 * @description : Reusable text field to add/remove text
 */

import { Box, Chip, TextField } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

function ChipsTextField({
  size,
  label,
  placeholder,
  variant,
  txtFldStyle,
  chips,
  setChips,
}) {
  const [inputValue, setInputValue] = useState("");

  const handleAddChip = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      // Check if the chip already exists in the list
      if (!chips.includes(inputValue.trim())) {
        setChips((prevChips) => [...prevChips, inputValue.trim()]);
      }
      setInputValue("");
    }
  };

  const handleDeleteChip = (chipToDelete) => () => {
    setChips((prevChips) => prevChips.filter((chip) => chip !== chipToDelete));
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <TextField
        size={size}
        label={label}
        placeholder={placeholder}
        variant={variant}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleAddChip}
        fullWidth
        sx={txtFldStyle}
      />
      <Box
        sx={{
          ...styles.chipsContainer,
          ...(chips?.length > 0 && {
            border: "1px solid lightgrey",
            borderRadius: 0.5,
          }),
        }}
      >
        {chips.map((chip, index) => (
          <Chip
            key={index}
            label={chip}
            size="small"
            onDelete={handleDeleteChip(chip)}
            deleteIcon={<CloseIcon />}
            sx={styles.chip}
          />
        ))}
      </Box>
    </Box>
  );
}

export default ChipsTextField;

// Styling
const styles = {
  chipsContainer: {
    maxHeight: "95px",
    maxWidth: "270px",
    overflow: "auto",
    px: 0.5,
    pt: 0.5,
    mb: 3,
  },
  chip: {
    mb: 0.5,
  },
};
