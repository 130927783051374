/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 29-06-2024
 * @description Reusable Multi-select dropdown component with checkboxes.
 */
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React from "react";
import CustomButton from "../buttons/CustomButton";
import RemoveIcon from "@mui/icons-material/Remove";

const CustomMenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 350,
      overflowX: "auto",
    },
    // Style the scrollbar to be smaller
    sx: {
      "& .MuiMenuItem-root": {
        whiteSpace: "normal",
        wordWrap: "break-word",
      },
      "&::-webkit-scrollbar": {
        height: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#555",
      },
    },
  },
};

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 29-06-2024
 * @description Reusable Multi-select dropdown component with checkboxes.
 * @param { size, label, optionsList, selectedValues, setSelectedValues, sltFldStyle }
 * @return The rendered multi-select dropdown component.
 */
function MultiSelectTextFieldNRemove({
  size,
  label,
  optionsList,
  emptyOptionListText,
  selectedValues,
  setSelectedValues,
  sltFldStyle,
  handleRemoveClick,
}) {
  // Handles change events for the multi-select dropdown.
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValues(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl variant="standard" size={size} sx={sltFldStyle}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={selectedValues}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={CustomMenuProps}
      >
        {optionsList?.map((option, index) => (
          <MenuItem key={index} value={option}>
            <Checkbox checked={selectedValues.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}

        <MenuItem>
          {optionsList?.length > 0 ? (
            <Box sx={styles.btnContainer}>
              <CustomButton
                startIcon={<RemoveIcon />}
                size={"small"}
                variant={"contained"}
                btnName={"Remove Chapters"}
                handleOnClick={handleRemoveClick}
                disabled={selectedValues?.length <= 0}
              />
            </Box>
          ) : (
            <Box sx={styles.emptyOLTContainer}>{emptyOptionListText}</Box>
          )}
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default MultiSelectTextFieldNRemove;

// Styles
const styles = {
  btnContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  emptyOLTContainer: {
    textAlign: "center",
  },
};
