import React, { useEffect, useState } from "react";
import { Paper, Box, Typography, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import notificationsAction from "../../redux/actions/notification";
import customConsole from "../../config/customConsole";
import { useDispatch } from "react-redux";
import { HandleApiActions } from "../../redux/actions";
import ViewAllNotifications from "./ViewAllNotifications";
import appTheme from "../../assets/AppTheme/appTheme";

const Notifications = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [recentNotificationDetails, setRecentNotificationDetails] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5);

  const handleViewAll = () => {
    console.log("Bharathan", ViewAllNotifications)
    onClose();
    navigate("/dashboard/view-all-notifications");
  };



  const getRecentNotificationDetails = async () => {
    let apiDetails = notificationsAction.get_recent_notifications;
    delete apiDetails.action_type;

    customConsole({
      ...apiDetails,
      params: {},
      show_toast: false,
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {},
        show_toast: false,
      })
    );

    if (resp.error) {
      setRecentNotificationDetails([]);
    } else {
      setRecentNotificationDetails(resp.data.notifications); // Access notifications array
    }
  };

  useEffect(() => {
    getRecentNotificationDetails();
  }, []);

  return (
    <Paper
      sx={{
        position: "absolute",
        top: 50,
        right: 20,
        width: 300,
        zIndex: 1300,
        padding: 1,
        boxShadow: 15,
        border: "1px solid black",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 1,
          height: 40
        }}
      >
        <Typography >Notifications</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ maxHeight: 250, overflowY: "auto" }}>
        {recentNotificationDetails.map((notification, index) => (
            <Box key={index} sx={{ padding: 0.5, textAlign: "left" }}>
              <Paper sx={{ border: "1px solid black", padding: "5px", boxShadow: 5, }}>
                <Button onClick={handleViewAll}>
                  <Typography sx={{fontSize: 12, textAlign: "left"}}>
                    <strong>{notification.header}</strong>
                  </Typography>
                </Button>
                <Typography sx={{fontSize: 10}}>{notification.message}</Typography> {/* Updated to message */}
              </Paper>
            </Box>
          ))}        
      </Box>


        <Box sx={{ paddingTop: 1, display: "flex", justifyContent: "flex-end", height: 40 }}>
          <Button
            variant="contained"
            onClick={handleViewAll}
            sx={{ ...appTheme.commonBtnStyle, fontSize: "10px" }}
          >
            View All
          </Button>
        </Box>
      
    </Paper>
  );
};

export default Notifications;
