import { Reducer } from "redux";
import actionType from "../actionTypes";
import customConsole from "../../config/customConsole";

const initialState = {
  error: false,
  msg: "",
  vehicleSystemsDetails: [],
};

const vehicleSystemsReducer = (state = initialState, action) => {
  customConsole(action);
  switch (action.type) {
    case actionType.ADD_VEHICLE_SYSTEMS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GET_VEHICLE_SYSTEMS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        vehicleSystemsDetails: action.payload,
      };
    case actionType.UPDATE_VEHICLE_SYSTEMS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
      case actionType.DELETE_VEHICLE_SYSTEMS:
        return {
          ...state,
          error: action.payload.error,
          msg: action.payload.msg,
        };
    default:
      return state;
  }
};

export default vehicleSystemsReducer;
