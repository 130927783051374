/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @date 11-06-2024
 * @description This file is to protect the website URLs -> Routes
 *              If user didn't logged in -> redirect to authentiation page
 */

import { Navigate } from "react-router-dom";
const ProtectedRoute = ({ children }) => {
  const isLoggedIn =
    localStorage.getItem("token") || sessionStorage.getItem("token")
      ? true
      : false;
  if (!isLoggedIn) {
    return <Navigate to="/authenticate" replace />;
  }
  return children;
};
export default ProtectedRoute;
