import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import CustomFiltersN from "../../../components/tables/CustomFiltersN";
import ToggleButton from "@mui/material/ToggleButton";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import CallMadeIcon from "@mui/icons-material/CallMade";
import DialogContentText from "@mui/material/DialogContentText";
import {
  CustomConfirmationDialog,
  CustomDataGridTableN,
  CustomPagination,
  CustomTextFieldN,
} from "../../../components";
import Stack from "@mui/material/Stack";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import customConsole from "../../../config/customConsole";
import { HandleApiActions } from "../../../redux/actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import editRequestAPI from "../../../redux/actions/editRequestApi";
import { convertUtcToTimeZone } from "../../../utils/convertUtcToTimeZone";
import helper from "../../../utils/helper";
import Button from '@mui/material/Button';
function EditAccessRequest({ boxes }) {
  const dispatch = useDispatch();

  const {
    project_status,
    access_module_types,
    access_request_types,
    access_request_status,
  } = useSelector((state) => state?.settingsReducer?.metaData, shallowEqual);

  // Redux store
  const profileDetails = useSelector((store) => store.profileReducer);
  const editRequestDetails = useSelector((state) => state.editRequestReducer);
  console.log("editRequestDetails", editRequestDetails);
  // Is request send
  const [isRequestSend, setIsRequestSend] = useState(true);

  const [selectedFilter, setSelectedFilter] = useState(100);

  // request access data
  const [requestAccessList, setRequestAccessList] = useState([]);

  // Table rows data
  const [tableRowsData, setTableRowsData] = useState([]);

  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [hasMore, setHasMore] = useState(false);

  // Dialogs states
  const [openApproveAccessDialog, setOpenApproveAccessDialog] = useState(false);
  const [selectedApproveAccessData, setSelectedApproveAccessData] =
    useState(null);
  const [openRejectAccessDialog, setOpenRejectAccessDialog] = useState(false);
  const [selectedRejectAccessData, setSelectedRejectAccessData] =
    useState(null);

  // Reason to reject access
  const [reasonToRejectAccess, setReasonToRejectAccess] = useState("");
  const [reasonToApproveAccess, setReasonToApproveAccess] = useState("");

  const getRequestAccessList = async ({
    projectId,
    pageNumber,
    statusNumber,
    pageSize,
  }) => {
    let apiDetails = editRequestAPI.get_edit_requests;

    customConsole({
      ...apiDetails,
      params: {
        is_sent_requests: isRequestSend,
        project_id: projectId,
        page_number: pageNumber,
        page_size: pageSize,
      },
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          ...(statusNumber ? { status: statusNumber } : null),
          is_sent_requests: isRequestSend,
          project_id: projectId,
          page_number: pageNumber,
          page_size: pageSize,
        },
      })
    );

    if (resp.error) {
      setRequestAccessList([]);
    } else {
      setRequestAccessList(resp.data);
    }
  };

  const approveRequestAccess = async ({
    id,
    reason,
    approvingAccess,
    selectedProjectId,
    page,
    rowsPerPage,
  }) => {
    let apiDetails = editRequestAPI.approve_edit_requests;

    customConsole({
      ...apiDetails,
      params: {
        er_id: Number(id),
        reason: reason,
      },
      show_toast: true,
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          er_id: Number(id),
          reason: reason,
        },
        show_toast: true,
      })
    );

    if (resp.error) {
    } else {
    }
  };

  const rejectRequestAccess = async ({
    id,
    reason,
    selectedProjectId,
    page,
    rowsPerPage,
  }) => {
    let apiDetails = editRequestAPI.reject_edit_requests;

    customConsole({
      ...apiDetails,
      params: {
        er_id: Number(id),
        reason: reason,
      },
      show_toast: true,
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          er_id: Number(id),
          reason: reason,
        },
        show_toast: true,
      })
    );

    if (resp.error) {
    } else {
    }
  };

  /* UseEffects */
  useEffect(() => {
    // Get the request access list
    if (boxes?.project_id) {
      getRequestAccessList({
        projectId: boxes?.project_id,
        pageNumber: page,
        status: selectedFilter,
        pageSize: rowsPerPage,
      });
    }
  }, [boxes, page, rowsPerPage, isRequestSend, selectedFilter]);

  //useEffect for set isRequestSend
  useEffect(() => {
    setIsRequestSend(!boxes?.is_user_project_owner);
  }, [boxes]);

  useEffect(() => {
    // Handle pagination hasMore
    if (requestAccessList?.length < rowsPerPage) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
    console.log("--->requestAccessList<---", requestAccessList);
    let filteredEditRequestAccessList = requestAccessList
      ?.filter((el) => {
        // If is request send and the status is not pending(100)
        if (!isRequestSend && el?.status !== 100) {
          return false;
        }
        return true;
      })
      ?.map((er, index) => {
        return {
          ...er,
          id: er.er_id, // Add a unique id here
          sNo: (page - 1) * rowsPerPage + index + 1,
          requested_user: er?.requested_user || {},
          ee_details: er?.ee_details || {},
          swm_details: er?.swm_details,
          updated_reason: er?.update_reason || "",
          created_at: convertUtcToTimeZone(
            er.created_at,
            profileDetails?.profile?.profile?.region
          ),
          approved_user: er?.approved_user || {},
          status:
            helper?.getValueByKey(access_request_status, er?.status) || "-",
        };
      });

    console.log("filteredEditRequestAccessList", filteredEditRequestAccessList);
    setTableRowsData(filteredEditRequestAccessList);
  }, [requestAccessList]);

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to the first page when page size changes
  };

  // Handle approve access stages
  const handleApproveAccessClick = (params) => {
    setSelectedApproveAccessData(params);

    setOpenApproveAccessDialog(true);
  };

  const handleConfirmApproveAccess = () => {
    approveRequestAccess(selectedApproveAccessData);

    setTimeout(() => {
      getRequestAccessList({
        projectId: boxes?.project_id,
        pageNumber: page,
        pageSize: rowsPerPage,
      });
    }, 500); // 500ms delay

    //setSelectedApproveAccessData(null);

    setOpenApproveAccessDialog(false);

  };
  const handleCancelApproveAccess = () => {
    //setSelectedApproveAccessData(null);

    setOpenApproveAccessDialog(false);
  };

  // Handle reject access stages
  const handleRejectAccessClick = (params) => {
    setSelectedRejectAccessData(params);

    setOpenRejectAccessDialog(true);

    setReasonToRejectAccess("");
  };
  const handleConfirmRejectAccess = () => {
    rejectRequestAccess({
      ...selectedRejectAccessData,
      reason: reasonToRejectAccess,
    });

    setTimeout(() => {
      getRequestAccessList({
        projectId: boxes?.project_id,
        pageNumber: page,
        pageSize: rowsPerPage,
      });
    }, 500); // 500ms delay

    setSelectedRejectAccessData(null);

    setOpenRejectAccessDialog(false);
  };
  const handleCancelRejectAccess = () => {
    setSelectedRejectAccessData(null);

    setOpenRejectAccessDialog(false);

    setReasonToRejectAccess("");
  };

  const handleOnClickFilter = (status) => {
    if (status) {
      getRequestAccessList({
        projectId: boxes?.project_id,
        pageNumber: page,
        pageSize: rowsPerPage,
        statusNumber: status,
      });
    }
    setSelectedFilter(status);
  };
  const COLUMNS = [
    {
      field: "sNo",
      headerName: "S.No",
      width: 90,
      filterable: false,
      sortable: false,
    },
    {
      field: "requested_user",
      headerName: "Requested By",
      width: 180,
      renderCell: (params) => (
        <Box>
          <Typography variant="subtitle2">
            <span style={styles.txtBold}>Name: </span>
            {params?.row?.requested_user?.fullname || "-"}
          </Typography>
          <Typography variant="subtitle2">
            <span style={styles.txtBold}>Email: </span>
            {params?.row?.requested_user?.email || "-"}
          </Typography>
        </Box>
      ),
    },

    {
      headerName: "Module Type",
      width: 180,
      renderCell: (params) => {
        return (
          <Box>
            <Typography variant="subtitle2">
              {params?.row?.swm_details === null ? "ECU" : "SWM"}
            </Typography>
          </Box>
        );
      },
    },

    {
      field: "ee_details",
      headerName: "EE Details",
      width: 220,
      renderCell: (params) => (
        <Box>
          <Typography variant="subtitle2">
            <span style={styles.txtBold}>Assembly Number: </span>
            {params?.row?.ee_details?.assembly_number || "-"}
          </Typography>
          <Typography variant="subtitle2">
            <span style={styles.txtBold}>Description: </span>
            {params?.row?.ee_details?.description || "-"}
          </Typography>
          <Typography variant="subtitle2">
            <span style={styles.txtBold}>Version: </span>
            {params?.row?.ee_details?.version || "-"}
          </Typography>
        </Box>
      ),
    },

    {
      field: "swm_details",
      headerName: "Software Details",
      width: 180,
      renderCell: (params) => (
        <Box>
          <Typography variant="subtitle2">
            <span style={styles.txtBold}>Name: </span>
            {params?.row?.swm_details?.name || "-"}
          </Typography>
          <Typography variant="subtitle2">
            <span style={styles.txtBold}>Description: </span>
            {params?.row?.swm_details?.description || "-"}
          </Typography>
          <Typography variant="subtitle2">
            <span style={styles.txtBold}>Version: </span>
            {params?.row?.swm_details?.version || "-"}
          </Typography>
        </Box>
      ),
    },

    {
      field: "request_reason",
      headerName: "Reason",
      width: 180,
    },
    {
      field: "updated_reason",
      headerName: "Rejected Reason",
      width: 180,
    },
    {
      field: "created_at",
      headerName: "Requested At",
      width: 180,
    },
    {
      field: "approved_user",
      headerName: "Approved By",
      width: 180,
      renderCell: (params) => (
        <Box>
          <Typography variant="subtitle2">
            <span style={styles.txtBold}>Name: </span>
            {params?.row?.approved_user?.fullname || "-"}
          </Typography>
          <Typography variant="subtitle2">
            <span style={styles.txtBold}>Email: </span>
            {params?.row?.approved_user?.email || "-"}
          </Typography>
        </Box>
      ),
    },
    ...(isRequestSend
      ? [
        {
          field: "status",
          headerName: "Status",
          width: 220,
        },
      ]
      : [
        {
          field: "status",
          headerName: "Status",
          width: 180,
        },
      ]),
    ...(!isRequestSend
      ? [
        {
          field: "action",
          headerName: "Action",
          width: 220,
          renderCell: (params) => (
            <Box
            // sx={{
            //   display: "flex",
            //   flexDirection: "column",
            //   alignItems: "flex-start",
            // }}
            >
              {/* <Box sx={{ display: "flex", alignItems: "center" }}> */}
              <IconButton
                size="small"
                color="success"
                onClick={() =>
                  handleApproveAccessClick({
                    id: params?.row?.er_id,
                    reason: reasonToApproveAccess,
                  })
                }
              >
                <CheckCircleIcon />
              </IconButton>
              <IconButton
                size="small"
                color="error"
                onClick={() =>
                  handleRejectAccessClick({
                    id: params?.row?.er_id,
                  })
                }
              >
                <CancelIcon />
              </IconButton>
            </Box>
          ),
        },
      ]
      : []),
  ];
  return (
    <div>
      <Box sx={{ mb: 12 }}>
        <Box sx={styles.filterBtnContainer}>
          <Box>
            <Button
              onClick={() => handleOnClickFilter(100)}
              size="medium"
              variant="text"
              sx={{
                border: "1px solid #cfbf4c",
                color: selectedFilter === 100 ? "#fff" : "#cfbf4c",
                backgroundColor: selectedFilter === 100 ? "#cfbf4c" : "transparent",
                "&:hover": {
                  backgroundColor: "#cfbf4c",
                  color: "#fff",
                },
              }}
            >
              Pending
            </Button>
            <Button
              onClick={() => handleOnClickFilter(200)}
              size="medium"
              variant="text"
              sx={{
                ml: "10px",
                border: "1px solid #37b518",
                color: selectedFilter === 200 ? "#fff" : "#37b518",
                backgroundColor: selectedFilter === 200 ? "#37b518" : "transparent",
                "&:hover": {
                  backgroundColor: "#37b518",
                  color: "#fff",
                },
              }}
            >
              Approved
            </Button>
            <Button
              onClick={() => handleOnClickFilter(300)}
              size="medium"
              variant="text"
              sx={{
                ml: "10px",
                border: "1px solid #d93442",
                "&:hover": {
                  backgroundColor: "#d93442",
                  color: "#fff",
                },
                color: selectedFilter === 300 ? "#fff" : "#d93442",
                backgroundColor: selectedFilter === 300 ? "#d93442" : "transparent",
              }}
            >
              Rejected
            </Button>
          </Box>
        </Box>
        <Paper elevation={4} sx={styles.prTableContainer}>
          <CustomDataGridTableN
            tableRowsData={tableRowsData}
            columns={COLUMNS}
            isMultiLine={true}
            handleTableRowClick={() => null}
            tableHeading={
              <div style={{ textAlign: "center", width: "100%" }}>
                {isRequestSend
                  ? "Sent Edit Access Request"
                  : "Received Edit Access Request"}
              </div>
            }
          />
          <Paper elevation={4} sx={styles.paginationPaper}>
            {/* Pagination with select page size */}
            <CustomPagination
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              hasMore={hasMore}
            />
          </Paper>
        </Paper>

        <CustomConfirmationDialog
          open={openApproveAccessDialog}
          onClose={handleCancelApproveAccess}
          dialogTitle={"Approve Access"}
          dialogContent={
            <Stack>
              <DialogContentText>
                Are you sure you want to approve access?
              </DialogContentText>
              <Box>
                <CustomTextFieldN
                  size={"small"}
                  label={"Reason*"}
                  //placeholder={"Reason to approve access*"}
                  type={"text"}
                  //fldType={"text"}
                  value={reasonToApproveAccess}
                  setValue={setReasonToApproveAccess}
                  //valueError={assemblyNoError}
                  setValueError={() => null}
                  variant={"standard"}
                  txtFldStyle={styles.txtStyle}
                />
              </Box>
            </Stack>
          }
          onConfirm={handleConfirmApproveAccess}
        />

        <CustomConfirmationDialog
          open={openRejectAccessDialog}
          onClose={handleCancelRejectAccess}
          dialogTitle={"Reject Access"}
          //disableConfirmBtn={reasonToRejectAccess?.length < 5}
          dialogContent={
            <Stack spacing={1.2}>
              <DialogContentText>
                Are you sure you want to reject access?
              </DialogContentText>
              <Box>
                <CustomTextFieldN
                  size={"small"}
                  //label={"Reason*"}
                  placeholder={"Reason to reject access*"}
                  type={"text"}
                  // fldType={"text"}
                  value={reasonToRejectAccess}
                  setValue={setReasonToRejectAccess}
                  //valueError={assemblyNoError}
                  setValueError={() => null}
                  variant={"standard"}
                  txtFldStyle={styles.txtStyle}
                />
              </Box>
            </Stack>
          }
          onConfirm={handleConfirmRejectAccess}
        />
      </Box>
    </div>
  );
}

export default EditAccessRequest;

// Styles
const styles = {
  txtBold: {
    fontWeight: "bold",
  },
  filterBtnContainer: {
    mt: 1.4,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 1,
  },
  prTableContainer: {
    mt: 1.4,
    height: 470,
    width: "100%",
    mb: 8,
    border: "1px solid grey",
  },
  paginationPaper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px",
    border: "1px solid grey",
  },
  txtStyle: {
    width: "100%",
  },
};
