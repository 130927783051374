/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 19-07-2024
 * @description : This page is for the vlc car card.
 */
import { Badge, Box, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import VlcCar from "../../assets/carForVLC.png";
import customConsole from "../../config/customConsole";
import InfoButton from "../../components/infoButton/InfoButton";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 19-07-2024
 * @description : Displays the car card.
 * @param : availableZones,  selectedComponent,  setSelectedComponent,
 * @return : The rendered car card.
 */
function VlcCarCard({
  availableZones,
  selectedComponent,
  setSelectedComponent,
  zoneEECountList,
}) {
  console.log("zoneEECountList", zoneEECountList);
  /* useEffects */
  useEffect(() => {
    customConsole("availableZones:" + availableZones[0]);
    if (availableZones && availableZones.length > 0) {
      setSelectedComponent({
        zone_position_name: availableZones[0].position_name,
      });
    }
  }, [availableZones]);

  // Zone Box with the badge
  const ZoneDetailsBox = (props) => {
    const handleOnClick = () => {
      console.log("-- handleOnClick --", zoneEECountList, "sed");
      customConsole(props);
      setSelectedComponent(props);
    };
    return (
      <Badge badgeContent={props?.ee_components_count || "0"} color="primary">
        <Paper
          elevation={12}
          sx={{
            ...styles.zoneContainer,
            border:
              selectedComponent &&
              selectedComponent.zone_position_name === props.zone_position_name
                ? "2px solid blue"
                : "",
          }}
          onClick={handleOnClick}
        >
          <Typography sx={styles.zoneText}>
            {props.zone_position_name}
          </Typography>
        </Paper>
      </Badge>
    );
  };
  const DummyComponent = () => {
    return <span style={styles.dummyComp}></span>;
  };
  return (
    <Paper elevation={12} sx={styles.mainContainer}>
      <Box sx={styles.frontZonesContainer}>
        {/* Front Left Zone */}
        {availableZones.find((data) => data?.zone_position === "FRONT-LEFT") ? (
          <ZoneDetailsBox
            zone_position_name={
              availableZones.find(
                (data) => data?.zone_position === "FRONT-LEFT"
              ).position_name
            }
            ee_components_count={
              zoneEECountList?.find(
                (data) => data?.zone_position === "FRONT-LEFT"
              )?.ee_components_count
            }
          />
        ) : (
          <DummyComponent />
        )}
        {/* Front Zone */}
        {availableZones.find((data) => data.zone_position === "FRONT") ? (
          <ZoneDetailsBox
            zone_position_name={
              availableZones.find((data) => data.zone_position === "FRONT")
                .position_name
            }
            ee_components_count={
              zoneEECountList?.find(
                (data) => data.zone_position_name === "FRONT"
              )?.ee_components_count
            }
          />
        ) : (
          <DummyComponent />
        )}
        {/* Front Right Zone */}
        {availableZones.find((data) => data.zone_position === "FRONT-RIGHT") ? (
          <ZoneDetailsBox
            zone_position_name={
              availableZones.find(
                (data) => data.zone_position === "FRONT-RIGHT"
              ).position_name
            }
            ee_components_count={
              zoneEECountList?.find(
                (data) => data.zone_position === "FRONT-RIGHT"
              )?.ee_components_count
            }
          />
        ) : (
          <DummyComponent />
        )}
      </Box>
      <Box sx={styles.middleZonesContainer}>
        {/* Left Zone */}
        {availableZones.find((data) => data.zone_position === "LEFT") ? (
          <ZoneDetailsBox
            zone_position_name={
              availableZones.find((data) => data.zone_position === "LEFT")
                .position_name
            }
            ee_components_count={
              zoneEECountList?.find((data) => data.zone_position === "LEFT")
                ?.ee_components_count
            }
          />
        ) : (
          <DummyComponent />
        )}
        {/* Vehicle Image -> HPC */}
        <Box
          sx={{
            minHeight: "250px",
            minWidth: "150px",
            backgroundImage: `url(${VlcCar})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Box
            sx={{
              height: "50px",
              width: "50px",
              border: "3px solid gray",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: "red",
                fontWeight: "bold",
              }}
            >
              HPC
            </Typography>
          </Box> */}
        </Box>
        {/* Right Zone */}
        {availableZones.find((data) => data.zone_position === "RIGHT") ? (
          <ZoneDetailsBox
            zone_position_name={
              availableZones.find((data) => data.zone_position === "RIGHT")
                .position_name
            }
            ee_components_count={
              zoneEECountList?.find((data) => data.zone_position === "RIGHT")
                ?.ee_components_count
            }
          />
        ) : (
          <DummyComponent />
        )}
      </Box>
      <Box sx={styles.rearZonesContainer}>
        {/* Rear Left Zone */}
        {availableZones.find((data) => data.zone_position === "REAR-LEFT") ? (
          <ZoneDetailsBox
            zone_position_name={
              availableZones.find((data) => data.zone_position === "REAR-LEFT")
                .position_name
            }
            ee_components_count={
              zoneEECountList?.find(
                (data) => data.zone_position === "REAR-LEFT"
              )?.ee_components_count
            }
          />
        ) : (
          <DummyComponent />
        )}
        {/* Rear Zone */}
        {availableZones.find((data) => data.zone_position === "REAR") ? (
          <ZoneDetailsBox
            zone_position_name={
              availableZones.find((data) => data.zone_position === "REAR")
                .position_name
            }
            ee_components_count={
              zoneEECountList?.find((data) => data.zone_position === "REAR")
                ?.ee_components_count
            }
          />
        ) : (
          <DummyComponent />
        )}
        {/* Rear Right Zone */}
        {availableZones.find((data) => data.zone_position === "REAR-RIGHT") ? (
          <ZoneDetailsBox
            zone_position_name={
              availableZones.find((data) => data.zone_position === "REAR-RIGHT")
                .position_name
            }
            ee_components_count={
              zoneEECountList?.find(
                (data) => data?.zone_position === "REAR-RIGHT"
              )?.ee_components_count
            }
          />
        ) : (
          <DummyComponent />
        )}
      </Box>
    </Paper>
  );
}

export default VlcCarCard;

// Styles
const styles = {
  mainContainer: {
    borderRadius: 1,
    width: "100%",
    height: "470px",
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",
    padding: "10px",
  },
  frontZonesContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  middleZonesContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
  },
  rearZonesContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  zoneContainer: {
    px: "2px",
    borderRadius: "20%",
    height: "80px",
    width: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  zoneText: {
    fontWeight: "bold",
    fontSize: "14px",
    marginTop: "10px",
    color: "brown",
  },
  dummyComp: {
    height: "80px",
    width: "100px",
  },
};
