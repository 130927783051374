/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 05-07-2024
 * @description
 */

import actionType from "../actionTypes";

const initialState = {
  error: false,
  msg: "",
  metaData: [],
};

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 05-07-2024
 * @description
 * @param {}
 * @return {}
 */
const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_APP_META_DATA:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        metaData: action.payload.error ? [] : action.payload.data,
      };

    default:
      return state;
  }
};

export default settingsReducer;
