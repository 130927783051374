/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 12-08-2024
 * @description : Reusable sorting component
 */

import React, { useEffect, useState } from "react";
import {
  Box,
  Menu,
  Typography,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { CustomButton, SelectTextField } from "../index";

function CustomSorting({
  anchorEl,
  open,
  handleClose,
  sortOptionsList,
  selectedSortColumn,
  selectedSortOrder,
  handleSortClick,
  onReset,
  onApplyNow,
}) {
  const [selectToSort, setSelectToSort] = useState(selectedSortColumn);
  const [checkedAscending, setCheckedAscending] = useState(
    selectedSortOrder === "ASC"
  );
  const [checkedDescending, setCheckedDescending] = useState(
    selectedSortOrder === "DESC"
  );

  // Handle functions
  const handleAscendingChange = (event) => {
    setCheckedAscending(event?.target?.checked);
    setCheckedDescending(!event?.target?.checked);
  };

  const handleDescendingChange = (event) => {
    setCheckedDescending(event?.target?.checked);
    setCheckedAscending(!event?.target?.checked);
  };

  // UseEffects
  useEffect(() => {
    setSelectToSort(selectedSortColumn);

    setCheckedAscending(selectedSortOrder === "ASC");
    setCheckedDescending(selectedSortOrder === "DESC");
  }, [selectedSortColumn, selectedSortOrder]);

  return (
    <Box sx={styles.mainContainer}>
      <CustomButton
        size="small"
        variant="contained"
        btnName="Sort By"
        handleOnClick={handleSortClick}
        btnStyle={styles.customButton}
        startIcon={<SwapVertIcon />}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 12,
          sx: {
            mt: 0.4,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography sx={{ ...styles.heading, ml: "10px" }}>Sort By</Typography>
        <Divider />
        {/* Sort fields */}
        <Box sx={styles.container}>
          <Box>
            <SelectTextField
              size="small"
              label="Select to sort"
              optionsList={sortOptionsList}
              selectedValue={selectToSort}
              setSelectedValue={setSelectToSort}
              variant="standard"
              sltFldStyle={styles.selectTextField}
            />
            <Box sx={{ mt: "10px" }}>
              <Typography sx={styles.heading}>Order</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedAscending}
                    onChange={handleAscendingChange}
                    name="Ascending"
                    color="primary"
                  />
                }
                label="Ascending"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedDescending}
                    onChange={handleDescendingChange}
                    name="Descending"
                    color="primary"
                  />
                }
                label="Descending"
              />
            </Box>
          </Box>

          {/* reset & apply now buttons */}
          <Box sx={styles.btBtnsContainer}>
            <CustomButton
              size="small"
              variant="contained"
              btnName="Reset All"
              handleOnClick={onReset}
            />
            <CustomButton
              size="small"
              variant="contained"
              btnName="Apply Now"
              handleOnClick={() =>
                onApplyNow(selectToSort, checkedAscending ? "ASC" : "DESC")
              }
            />
          </Box>
        </Box>
      </Menu>
    </Box>
  );
}

export default CustomSorting;

// Styling
const styles = {
  mainContainer: {
    // mr: "10px ",
  },
  container: {
    px: 1.4,
    py: 0.4,
  },
  customButton: {
    ml: 2,
  },
  btBtnsContainer: {
    display: "flex",
    justifyContent: "space-between",
    mt: 1,
  },
  selectTextField: {
    width: "250px",
    mt: 1,
  },
  heading: {
    fontWeight: "bold",
  },
};
