/**
 * @author Abdul Rahim M-abdul@au79consulting.com
 * @date 21-10-2024
 * @description  Edit Requests API documentation Apis
 */
import actionType from "../actionTypes";

const editRequestAPI = {
  //Edit Request Action

  get_edit_requests: {
    api_name: "/PCM/project/edit/requests",
    https_method: "GET",
    action_type: actionType.GET_EDIT_REQUESTS,
    description: "Get the Edit Requests",
  },

  create_edit_requests: {
    api_name: "/PCM/project/edit/request",
    https_method: "POST",
    action_type: actionType.CREATE_EDIT_REQUESTS,
    description: "Create the Edit Requests",
  },

  approve_edit_requests: {
    api_name: "/PCM/project/edit/request/approve",
    https_method: "PUT",
    action_type: actionType.APPROVE_EDIT_REQUESTS,
    description: "Approve the Edit Requests",
  },

  reject_edit_requests: {
    api_name: "/PCM/project/edit/request/reject",
    https_method: "PUT",
    action_type: actionType.REJECT_EDIT_REQUESTS,
    description: "Reject the Edit Requests",
  },
};

export default editRequestAPI;
