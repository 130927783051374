import customConsole from "../../config/customConsole";
import actionType from "../actionTypes";
/**
 * @author : Abdul Rahim M - abdul@au79consulting.com
 * @Date : 04-07-2024
 * @description Reducer function for handling profile related actions and state changes.
 */
const initialState = {
  error: false,
  msg: "",
  profile: {},
  profile_pic_name: "",
  profile_pic_path: "",
};

/**
 * @author : Abdul Rahim M - abdul@au79consulting.com
 * @Date : 04-07-2024
 * @description
 * @param {}
 * @return New state after processing the action
 */
const profileReducer = (state = initialState, action) => {
  customConsole(action);
  switch (action.type) {
    case actionType.GET_PROFILE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        profile: action.payload.data,
      };
    case actionType.GET_PROFILE_PIC:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        profile_pic_path: action.payload.file_path,
      };
    case actionType.UPLOAD_PROFILE_PIC:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        profile_pic_name: action.payload.file_name
          ? action.payload.file_name
          : "",
      };
    case actionType.UPDATE_PROFILE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        profile: action.payload,
      };
    case actionType.RESET_PROFILE:
      return initialState;
    default:
    return state;
  }
};

export default profileReducer;
