/**
 * @author : Narender - narender@au79consulting.com, Abdul - abdul@au79consulting.com
 * @Date : 18-10-2024
 * @description : Show the linked software modules list
 */
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import customConsole from "../../../config/customConsole";

function LinkedSWMTable({ selectedSWMLinkedList, selectedProjectIsZonal }) {
  const [filteredSWModules, setFilteredSWModules] = useState([]);

  useEffect(() => {
    customConsole({
      selectedSWMLinkedListNNNNNNNNNNNN: selectedSWMLinkedList,
    });
  }, [selectedSWMLinkedList]);

  // useEffect(() => {
  //   const filteredList = selectedSWMLinkedList?.find(
  //     (data) => data?.swm_id === selectedMainSWModuleDetails?.swm_id
  //   );
  //   console.log("filteredList", filteredList);
  //   setFilteredSWModules(filteredList);
  // }, [selectedMainSWModuleDetails]);

  return (
    <div>
      <Paper
        elevation={24}
        sx={{
          mt: "40px",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px",
          border: "1px solid black", // Add border here
        }}
      >
        {selectedSWMLinkedList && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              boxShadow: 2,
              mt: "20px",
              padding: "10px",
              // Add border here
            }}
          >
            <Paper
              sx={{
                display: "flex",
                padding: "10px",
                border: "1px solid black",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                Selected Software Module
              </Typography>
              <strong style={{ marginRight: "4px" }}>:</strong>
              <Typography>{selectedSWMLinkedList[0]?.name}</Typography>
            </Paper>
            <Paper
              sx={{
                display: "flex",
                padding: "10px",
                border: "1px solid black",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Version</Typography>
              <strong>:</strong>
              <Typography> {selectedSWMLinkedList[0]?.version}</Typography>
            </Paper>
          </Box>
        )}

        <TableContainer
          component={Paper}
          sx={{
            mt: "20px",
            maxHeight: "300px",
            overflowY: "auto",
            border: "1px solid black",
          }} // Add border here
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid black",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>S.No</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid black",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Software Modules
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid black",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    EE Component
                  </Typography>
                </TableCell>
                {selectedProjectIsZonal ? (
                  <TableCell
                    sx={{
                      border: "1px solid black",
                      position: "sticky",
                      top: 0,
                      backgroundColor: "white",
                      zIndex: 1,
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>Zone</Typography>
                  </TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedSWMLinkedList[0]?.linked_swm_details?.length > 0 ? (
                selectedSWMLinkedList[0]?.linked_swm_details?.map(
                  (linkedModule, linkedIdx) => (
                    <TableRow key={linkedModule?.swm_id}>
                      <TableCell sx={{ border: "1px solid black" }}>
                        <Typography>{linkedIdx + 1}</Typography>
                      </TableCell>

                      <TableCell sx={{ border: "1px solid black" }}>
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Name
                          </Typography>
                          <strong>:</strong>
                          <Typography>{linkedModule?.name}</Typography>
                        </Box>

                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Version
                          </Typography>
                          <strong>:</strong>
                          <Typography>{linkedModule?.version}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ border: "1px solid black" }}>
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Description
                          </Typography>
                          <strong>:</strong>
                          <Typography>
                            {linkedModule?.ee_details?.description}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Version
                          </Typography>
                          <strong>:</strong>
                          <Typography>
                            {linkedModule?.ee_details?.version}
                          </Typography>
                        </Box>
                      </TableCell>
                      {selectedProjectIsZonal ? (
                        <TableCell sx={{ border: "1px solid black" }}>
                          <Box sx={{ display: "flex" }}>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Name
                            </Typography>
                            <strong>:</strong>
                            <Typography>
                              {linkedModule.zone_details?.zone_name || "N/A"}
                            </Typography>
                          </Box>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  )
                )
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography textAlign="center" sx={{ color: "red" }}>
                    Data Not Found
                  </Typography>
                </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default LinkedSWMTable;
