import actionType from "../actionTypes";
const softwareIntersectionAPI = {
  get_software_intersections: {
    api_name: "/PCM/swm/intersections",
    https_method: "GET",
    action_type: actionType.GET_SOFTWARE_INTERSECTIONS,
    description: "Get the Software Intersections",
  },
  get_software_legal_requirements: {
    api_name: "/PCM/swm/legal-requirements",
    https_method: "GET",
    action_type: actionType.GET_SOFTWARE_LR,
    description: "Get the Software LR",
  },
  get_ee_legal_requirements: {
    api_name: "/PCM/ee_components/legal-requirements",
    https_method: "GET",
    action_type: "",
    description: "",
  },
};
export default softwareIntersectionAPI;
