/**
 * @author Narender - narender@au79consulting.com
 * @date 13-06-2024
 * @description Reducer function for handling authentication-related actions and state changes.
 */

import actionType from "../actionTypes";

const initialState = {
  isAuthenticated:
    localStorage.getItem("token") || sessionStorage.getItem("token")
      ? true
      : false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.LOGIN:
      return {
        ...state,
        isAuthenticated: true,
      };
    case actionType.FORGOT_PASSWORD:
      return {
        ...state,
      };
    case actionType.RESET_PASSWORD:
      return {
        ...state,
      };
    case actionType.SIGN_OUT:
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default authReducer;
