/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 07-07-2024
 * @description : This page is to update the user details.
 */
import React, { useState, useEffect } from "react";
import UpdateIcon from "@mui/icons-material/Update";
import {
  Box,
  Button,
  Typography,
  Grid,
  DialogContentText,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DefaultProfilePic from "../../assets/logoIcons/defaultProfilePic.png";
import { toast } from "react-toastify";
import {
  GetUserPicAction,
  HandleApiActions,
  profileActions,
  UploadUserPicAction,
  userManagementApis,
} from "../../redux/actions";
import CloseIcon from "@mui/icons-material/Close";
import {
  CustomButton,
  CustomConfirmationDialog,
  CustomTextFieldN,
  PhoneNumberTextField,
  SelectTextField,
  SelectTextFieldN,
} from "../../components";
import appTheme from "../../assets/AppTheme/appTheme";
import customConsole from "../../config/customConsole";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 07-07-2024
 * @description : Component to update the user details.
 * @param : userData, toggleDrawer
 * @return : The rendered update user component.
 */
const UpdateUser = ({ userData, toggleDrawer, handleGetUsersList }) => {
  customConsole("userData: " + userData);
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState(userData?.fullname || "");
  const [fullNameError, setFullNameError] = useState("");
  const [loggedInUserDetails, setLoggedInUserDetails] = useState("");
  const [email, setEmail] = useState(userData?.email || "");
  const [emailError, setEmailError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(userData?.phone || "");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const [phoneCodeOptions, setPhoneCodeOptions] = useState([]);

  const [selectedRoleDepartmentId, setSelectedRoleDepartmentId] = useState(
    userData?.role_id || ""
  );
  const [roleDepartmentOptions, setRoleDepartmentOptions] = useState([]);

  const [selectedPhoneCode, setSelectedPhoneCode] = useState(
    userData?.phone_code || ""
  );
  const [regionOptions, setRegionOptions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(userData?.region || "");
  const [isUserActive, setIsUserActive] = useState(
    userData?.is_active || false
  );
  const [profilePicName, setProfilePicName] = useState(
    userData?.profile?.profile_pic || ""
  );
  const [profilePicFilePath, setProfilePicFilePath] = useState("");
  const { countryCodes } = useSelector(
    (state) => state.userManagementReducer,
    shallowEqual
  );
  const [openUserDeleteDialog, setOpenUserDeleteDialog] = useState(false);
  const { rolesList } = useSelector(
    (state) => state.userManagementReducer,
    shallowEqual
  );
  const [rolesOptions, setRolesOptions] = useState([]);
  const profileDetails = useSelector((store) => store.profileReducer);
  customConsole("profileDetails: " + profileDetails);

  const [openUserStatusDialog, setOpenUserStatusDialog] = useState(false);
  const [pendingUserStatus, setPendingUserStatus] = useState(isUserActive);
  const [selectedPendingRoleDepartmentId, setSelectedPendingRoleDepartmentId] =
    useState("");
  const [
    openUserUpdateRoleDepartmentDialog,
    setOpenUserUpdateRoleDepartmentDialog,
  ] = useState(false);

  const handleUserStatusChange = (newStatus) => {
    setPendingUserStatus(newStatus);
    setOpenUserStatusDialog(true);
  };

  const handleUserDialogClose = (confirmed) => {
    let currentTime = new Date().toISOString();

    if (confirmed) {
      setIsUserActive(pendingUserStatus);

      handleUserStatusClick({
        current_time: currentTime,
        status: pendingUserStatus,
        user_id: userData?.user_id,
      });
    }
    setOpenUserStatusDialog(false);
  };

  useEffect(() => {
    let filteredRoleDepartment = rolesList
      ?.filter((el) => el.is_active || el.role_id === selectedRoleDepartmentId)
      ?.map((el) => {
        return {
          value: el.role_id,
          label: `${el.role_name} - ${el.department}`,
        };
      })
      ?.sort((a, b) => {
        return a.value.localeCompare(b.value);
      });

    setRoleDepartmentOptions(filteredRoleDepartment);
  }, [rolesList, selectedRoleDepartmentId]);

  useEffect(() => {
    if (profileDetails?.profile) {
      setLoggedInUserDetails(profileDetails?.profile);
    }
  }, [profileDetails]);

  useEffect(() => {
    let filteredRolesOptions = rolesList
      ?.map((el) => {
        return { value: el.role_id, label: el.role_name };
      })
      .sort((a, b) => {
        return a.value.localeCompare(b.value);
      });

    setRolesOptions(filteredRolesOptions);
  }, [rolesList]);

  useEffect(() => {
    let filteredRegions = countryCodes
      ?.map((el) => {
        return { value: el.time_zone, label: el.time_zone };
      })
      .sort((a, b) => {
        return a.value.localeCompare(b.value);
      });

    let filteredPhoneCodes = countryCodes
      ?.map((el) => {
        return {
          value: el.phone_code,
          label: `${el.country}: ${el.phone_code}`,
          phone_code: el.phone_code,
        };
      })
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });

    setRegionOptions(filteredRegions);
    setPhoneCodeOptions(filteredPhoneCodes);
  }, [countryCodes]);

  const handleUploadProfile = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const allowedExtensions = ["jpg", "jpeg", "png"];
      if (allowedExtensions.includes(fileExtension)) {
        let newFile = new FormData();
        newFile.append("name", "filename");
        newFile.append("filename", file);

        dispatch(UploadUserPicAction({ file: newFile })).then((res) => {
          if (!res?.error) {
            if (res?.user_profile_pic_name) {
              setProfilePicName(res?.user_profile_pic_name);
            }
          } else {
          }
        });
      } else {
        toast.dark("Please select a valid image file", {
          type: "error",
          position: "bottom-right",
          autoClose: 3000,
        });
      }
    }
  };

  useEffect(() => {
    if (profilePicName == "") {
      setProfilePicFilePath("");
    } else {
      // user_profile_pic_path

      dispatch(GetUserPicAction({ filename: profilePicName })).then((res) => {
        if (!res?.error) {
          setProfilePicFilePath(res?.user_profile_pic_path);
        } else {
        }
      });
    }
  }, [profilePicName]);

  const handleDeleteUserClick = () => {
    setOpenUserDeleteDialog(true);
  };

  const handleConfirmUserDelete = () => {
    let currentTime = new Date().toISOString();

    dispatch(
      HandleApiActions({
        ...userManagementApis.delete_user,
        params: { current_time: currentTime, user_id: userData?.user_id },
        show_toast: true,
      })
    ).then(() => {
      handleGetUsersList();
    });

    setOpenUserDeleteDialog(false);
    toggleDrawer(false)();
  };

  const handleCancelUserDelete = () => {
    setOpenUserDeleteDialog(false);
  };

  const handleUpdateUserClick = () => {
    let currentTime = new Date().toISOString();

    if (
      fullName !== userData?.fullname ||
      profilePicName !== userData?.profile?.profile_pic ||
      phoneNumber !== userData?.phone ||
      selectedPhoneCode !== userData?.phone_code ||
      selectedRegion !== userData?.region
    ) {
      dispatch(
        HandleApiActions({
          ...userManagementApis.update_user,
          params: {
            user_id: userData?.user_id,
            ...(userData?.fullname !== fullName && { fullname: fullName }),
            ...(userData?.profile?.profile_pic !== profilePicName && {
              profile_pic: profilePicName,
            }),
            ...(userData?.phone !== phoneNumber &&
              phoneNumber && {
                phone: { code: selectedPhoneCode, number: phoneNumber },
              }),
            ...(userData?.region !== selectedRegion && {
              region: selectedRegion,
            }),
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        handleGetUsersList();
      });
    }

    if (profileDetails?.profile?.user_id === userData?.user_id) {
      dispatch(
        HandleApiActions({
          ...profileActions.get_profile_details,
          show_toast: false,
          params: {},
        })
      );
    }

    toggleDrawer(false)();
  };

  const handleUserStatusClick = ({ current_time, status, user_id }) => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.change_user_status,
        params: { current_time, status, user_id },
        show_toast: true,
      })
    ).then(() => {
      handleGetUsersList();
    });
  };

  const handleUserRoleChangeClick = ({ user_id, role_id, current_time }) => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.change_user_role,
        params: { user_id, role_id, current_time },
        show_toast: true,
      })
    ).then(() => {
      handleGetUsersList();
    });
  };

  const isFormValid = () => {
    return (
      !fullNameError &&
      (phoneNumber.length === 0 || phoneNumber.length === 10) &&
      (fullName?.trim() !== userData?.fullname?.trim() ||
        profilePicName !== userData?.profile?.profile_pic ||
        phoneNumber !== userData?.phone ||
        selectedRegion !== userData?.region)
    );
  };

  const handleOnChangeRoleDepartment = (e) => {
    setSelectedPendingRoleDepartmentId(e.target.value);

    setOpenUserUpdateRoleDepartmentDialog(true);
  };

  const handleConfirmUserUpdateRoleDepartment = () => {
    let currentTime = new Date().toISOString();

    setSelectedRoleDepartmentId(selectedPendingRoleDepartmentId);

    if (selectedPendingRoleDepartmentId != userData?.role_id) {
      handleUserRoleChangeClick({
        user_id: userData?.user_id,
        role_id: selectedPendingRoleDepartmentId,
        current_time: currentTime,
      });
    }

    setOpenUserUpdateRoleDepartmentDialog(false);
  };

  useEffect(() => {
    customConsole("profilePicName: " + profilePicName);
    customConsole("profilePicFilePath: " + profilePicFilePath);
  }, [profilePicName, profilePicFilePath]);

  return (
    <Box>
      {/* close icon */}
      <Box sx={styles.closeIconContainer}>
        <IconButton sx={styles.closeIcon} onClick={toggleDrawer(false)}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Display/Upload image */}
      <Box sx={styles.imageContainer}>
        <Box>
          <img
            src={profilePicFilePath || DefaultProfilePic}
            alt="Profile picture."
            style={styles.displayImageBox}
          />
        </Box>
        <Button variant="standard" component="label" sx={styles.uploadImageBox}>
          <UploadFileIcon color="blue" size={18} />
          <input
            accept="image/*"
            hidden
            type="file"
            onChange={handleUploadProfile}
          />
        </Button>
      </Box>

      {/* Update used details */}
      <Box sx={styles.mainContainer}>
        <Box sx={styles.container}>
          <Grid container columnSpacing={2} rowSpacing={1} px={3} pt={2} pb={4}>
            <Grid item xs={12}>
              <CustomTextFieldN
                size="small"
                label={"Name"}
                placeholder={"Name"}
                type={"text"}
                fldType={"text"}
                value={fullName}
                setValue={setFullName}
                valueError={fullNameError}
                setValueError={setFullNameError}
                variant={"standard"}
                inputProps={
                  {
                    // disabled: true,
                  }
                }
                txtFldStyle={styles.customTextFieldN}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextFieldN
                size="small"
                label={"Email"}
                type={"email"}
                fldType={"email"}
                placeholder={"Email"}
                value={email}
                variant={"standard"}
                setValue={setEmail}
                valueError={emailError}
                setValueError={setEmailError}
                inputProps={{
                  disabled: true,
                }}
                txtFldStyle={styles.customTextFieldN}
              />
            </Grid>

            <Grid item xs={12}>
              <SelectTextFieldN
                size={"small"}
                label={"Role-Department"}
                variant={"standard"}
                disabled={
                  loggedInUserDetails?.role_details?.role_name !== "SUPER-ADMIN"
                }
                optionsList={roleDepartmentOptions}
                selectedValue={selectedRoleDepartmentId}
                handleOnChange={handleOnChangeRoleDepartment}
                sltFldStyle={styles.selectTextFieldN}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectTextField
                size={"small"}
                label={"Region"}
                optionsList={regionOptions}
                selectedValue={selectedRegion}
                setSelectedValue={setSelectedRegion}
                variant={"standard"}
                sltFldStyle={styles.selectTextFieldN}
              />
            </Grid>
            <Grid item xs={12}>
              <PhoneNumberTextField
                size="small"
                phoneCodeLabel="Country"
                phoneNumberLabel="Phone Number"
                variant={"standard"}
                phoneCodeOptions={phoneCodeOptions}
                selectedPhoneCode={selectedPhoneCode}
                setSelectedPhoneCode={setSelectedPhoneCode}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                phoneNumberError={phoneNumberError}
                setPhoneNumberError={setPhoneNumberError}
                phoneCodeStyle={{
                  width: "75px",
                  marginRight: "5px",
                  mt: 2,
                }}
                phoneNumberStyle={{
                  width: "260px",
                  mt: 2,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-around"
                sx={styles.userStatusContainer}
              >
                <Typography variant="body1" component="span">
                  User Status:
                </Typography>

                <CustomButton
                  size="small"
                  variant="contained"
                  btnName="Enabled"
                  handleOnClick={() => handleUserStatusChange(true)}
                  btnStyle={styles.customButton}
                  endIcon={isUserActive ? <CheckBoxIcon /> : null}
                />
                <CustomButton
                  size="small"
                  variant="contained"
                  btnName="Disabled"
                  handleOnClick={() => handleUserStatusChange(false)}
                  btnStyle={styles.customButton}
                  endIcon={isUserActive ? null : <CheckBoxIcon />}
                />
              </Box>
            </Grid>
            <Grid item xs={6} textAlign={"center"}>
              <Button
                startIcon={<DeleteIcon />}
                size="medium"
                color="error"
                variant="contained"
                onClick={handleDeleteUserClick}
                sx={styles.deleteBtn}
              >
                Delete
              </Button>
            </Grid>
            <Grid item xs={6} textAlign={"center"}>
              <Button
                startIcon={<UpdateIcon />}
                size="medium"
                color="primary"
                variant="contained"
                disabled={!isFormValid()}
                onClick={handleUpdateUserClick}
                sx={styles.updateBtn}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <CustomConfirmationDialog
        open={openUserDeleteDialog}
        onClose={handleCancelUserDelete}
        dialogTitle={"Confirm Delete"}
        dialogContent={
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        }
        onConfirm={handleConfirmUserDelete}
      />
      <CustomConfirmationDialog
        open={openUserUpdateRoleDepartmentDialog}
        onClose={() => setOpenUserUpdateRoleDepartmentDialog(false)}
        dialogTitle={"Update User Role-Department"}
        dialogContent={
          <DialogContentText>
            Are you sure you want to update role-department?
          </DialogContentText>
        }
        onConfirm={handleConfirmUserUpdateRoleDepartment}
      />
      <CustomConfirmationDialog
        open={openUserStatusDialog}
        onClose={() => handleUserDialogClose(false)}
        dialogTitle={"Confirm Status Change"}
        dialogContent={
          <DialogContentText>
            Are you sure you want to change the user status to{" "}
            {pendingUserStatus ? "Enable" : "Disable"}?
          </DialogContentText>
        }
        onConfirm={() => handleUserDialogClose(true)}
      />
    </Box>
  );
};

export default UpdateUser;

const styles = {
  closeIconContainer: {
    display: "flex",
    justifyContent: "flex-end",
    px: 1,
    pt: 1,
  },
  closeIcon: {
    color: "red",
  },
  imageContainer: {
    textAlign: "center",
    mt: 1,
  },
  mainContainer: {
    display: "flex",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    marginTop: { xs: 0, sm: "30px" },
  },
  customTextFieldN: {
    backgroundColor: "white",
    mt: 2,
  },
  selectTextFieldN: {
    width: "100%",
    mt: 2,
  },
  displayImageBox: {
    width: 150,
    height: 150,
    borderRadius: "50%",
    objectFit: "cover",
  },
  uploadImageBox: {
    backgroundColor: "none",
    height: "50px",
    width: "40px",
    borderRadius: "50%",
    marginTop: "-55px",
    marginRight: "-100px",
  },
  profileDataBox: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    alignItems: "center",
  },
  profileHeader: {
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "left",
    minWidth: "100px",
  },
  profileContent: {
    fontSize: "16px",
    textAlign: "right",
    marginLeft: "15px",
  },
  userStatusContainer: {
    width: "100%",
    mt: 2,
  },
  toggleBtnGroup: {
    borderRadius: "20px",
    backgroundColor: "lightgrey",
  },
  deleteBtn: {
    // width: "100%",
    width: "140px",
    mt: 2,
    ...appTheme.commonBtnStyle,
    // borderRadius: "0px",
    //bgcolor: "#f44336",
    // "&:hover": {
    //   bgcolor: "#d32f2f",
    // },
  },
  updateBtn: {
    width: "140px",
    ...appTheme.commonBtnStyle,
    mt: 2,
  },
};
