import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";

import Collapse from "@mui/material/Collapse";

import Divider from "@mui/material/Divider";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import DeleteIcon from "@mui/icons-material/Delete";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import React, { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import CustomButton from "../../components/buttons/CustomButton";
import CustomTextField1 from "../../components/text-fields/CustomTextField1";
import customConsole from "../../config/customConsole";

import CustomTextFieldSP from "../../components/text-fields/CustomTextFieldSP";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import profileActions from "../../redux/actions/profile";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import adminSettingsAPI from "../../redux/actions/adminSetttingAPI";

import AddIcon from "@mui/icons-material/Add";
import { convertUtcToTimeZone } from "../../utils/convertUtcToTimeZone";
import CustomAddDialog from "../../components/dialog-boxes/CustomAddDialog";
import { DialogContentText, IconButton, TextField } from "@mui/material";
import CustomUpdateDialog from "../../components/dialog-boxes/CustomUpdateDialog";
import CustomConfirmationDialog from "../../components/dialog-boxes/CustomConfirmationDialog";

function VehicleFunction({ open, handleClick }) {
  const dispatch = useDispatch();
  const vehicleFunctionDetails = useSelector(
    (state) => state.vehicleFunctionReducer
  );
  const timeZoneDetails = useSelector((state) => state.profileReducer);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [getVehicleFunction, setGetVehicleFunction] = React.useState([]);
  const [openAddVehicleFunctionDialog, setOpenAddVehicleFunctionDialog] =
    React.useState(false);
  const [openEditVehicleFunctionDialog, setOpenEditVehicleFunctionDialog] =
    React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [openDeleteVehicleFunctionDialog, setOpenDeleteVehicleFunctionDialog] =
    React.useState(false);
  const [timeZone, setTimeZone] = React.useState([]);
  const [tableRowsData, setTableRowsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const COLUMNS = [
    { field: "sno", headerName: "S.No", width: 100 },
    {
      field: "abbreviation",
      headerName: "Abbreviation",
      width: 150,
    },
    { field: "name", headerName: "Name", width: 300 },

    {
      field: "created_at",
      headerName: "Created",
      width: 200,
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <IconButton
          size="small"
          color="error"
          onClick={(e) => {
            e.stopPropagation();
            handleTableRowClick(params, true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {
    customConsole(getVehicleFunction);
    let filteredUsersList = getVehicleFunction?.map((data, index) => {
      return {
        ...data,
        sno: index + 1,
        id: data?.id,
        abbreviation: data?.abbreviation,
        name: data?.name,

        created_at: convertUtcToTimeZone(
          data?.created_at,
          timeZoneDetails?.profile?.profile?.region
        ),
        updated_at: convertUtcToTimeZone(
          data?.updated_at,
          timeZoneDetails?.profile?.profile?.region
        ),
      };
    });

    setTableRowsData(filteredUsersList);
  }, [getVehicleFunction]);

  React.useEffect(() => {
    dispatch(HandleApiActions({ ...profileActions.get_coutry_regions }));
  }, []);
  React.useEffect(() => {
    customConsole("timeZoneDetails?.data?.profile?.region");
    customConsole(timeZoneDetails?.profile?.profile?.region);
    setTimeZone(timeZoneDetails?.profile?.profile?.region);
  }, [timeZoneDetails]);

  const filteredRows = useMemo(() => {
    if (!searchQuery) return tableRowsData;
    return tableRowsData.filter((row) =>
      COLUMNS.some((column) =>
        row[column.field]
          ?.toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, tableRowsData, COLUMNS]);
  const toggleDialog = (newOpen) => () => {
    setOpenAddVehicleFunctionDialog(newOpen);
  };

  const handleAddVehicleFunctionClick = () => {
    toggleDialog(true)();
  };

  const handleTableRowClick = (params, isButtonClicked) => {
    setSelectedRow(params);
    customConsole(params);
    if (isButtonClicked) {
      setOpenDeleteVehicleFunctionDialog(true);
    } else {
      setOpenEditVehicleFunctionDialog(true);
    }
  };

  React.useEffect(() => {
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.get_vehicle_function,
      })
    );
  }, []);

  React.useEffect(() => {
    customConsole("VehicleFunction Train Details...................");
    customConsole(vehicleFunctionDetails);

    setGetVehicleFunction(
      vehicleFunctionDetails?.vehicleFunctionDetails?.data?.length
        ? vehicleFunctionDetails?.vehicleFunctionDetails?.data
        : []
    );
  }, [vehicleFunctionDetails]);

  customConsole("GetVehicleFunction...............,", getVehicleFunction);

  return (
    <div>
      <List
        sx={{
          width: "100%",
          maxWidth: "100%",
          marginTop: "15px",
          backgroundColor: "whitesmoke",
          boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.2)",
          borderRadius: "0px",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={() => handleClick("4")}>
          <ListItemText
            sx={{
              textAlign: "start",
              color: "black",
            }}
            primary="Vehicle Function"
            primaryTypographyProps={{
              fontWeight: 600,
              fontSize: "18px",
            }}
          />
          {open === "4" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open === "4"} timeout="auto" unmountOnExit>
          <Divider sx={{ margin: "10px" }} />
          <List
            component="div"
            disablePadding
            sx={{
              background: "whitesmoke",
              margin: "10px",
              padding: "10px",
              borderRadius: "10px",

              maxHeight: "500px",
            }}
          >
            <Box>
              <Paper sx={styles.container}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "60px",
                    mr: "10px",
                    ml: "10px",
                  }}
                >
                  <Box>
                  <TextField
                    variant="standard"
                    label="Search"
                    size="small"
                    margin="normal"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{ margin: 0 }}
                  />
                  </Box>


<Box sx={styles.tblFun}>
              <CustomButton
                size={"medium"}
                variant={"contained"}
                btnName={"Add"}
                startIcon={<AddIcon />}
                handleOnClick={handleAddVehicleFunctionClick}
                btnStyle={{
                  ml: 2,
                  // borderRadius: "0px",
                }}
              />
            </Box>
                </Box>
                <div style={{ height: 400, width: "100%" }}>
                  <DataGrid
                    initialState={{
                      density: "comfortable",
                    }}
                    sx={{
                      "& .MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal.css-1rtad1":
                        {
                          display: "block",
                        },
                      "& .MuiDataGrid-columnHeaders": {
                        position: "sticky",
                        top: 0, 
                        zIndex: 1,
                        backgroundColor: "white",
                        "& .MuiDataGrid-columnHeaderTitle": {
                          fontWeight: "bold",
                        },
                      },
                    }}
                    localeText={{ toolbarDensity: "View" }}
                    // autoHeight={true}
                    rows={filteredRows || []}
                    columns={COLUMNS}
                    disableColumnMenu
                    disableColumnSelector
                    disableColumnFilter
                    disableRowSelectionOnClick
                    slots={{ toolbar: GridToolbar }}
                    pageSize={rowsPerPage}
                    slotProps={{
                      toolbar: {
                        printOptions: { disableToolbarButton: true },
                      },
                    }}
                    rowCount={
                      tableRowsData.length >= rowsPerPage
                        ? -1
                        : page * rowsPerPage + tableRowsData.length
                    }
                    onPaginationModelChange={(model) => {
                      setPage(model.page);
                      setRowsPerPage(model.pageSize);
                    }}
                    paginationModel={{ page: page, pageSize: rowsPerPage }}
                    pageSizeOptions={[25, 50, 100]}
                    onRowClick={(params) =>
                      handleTableRowClick(params.row, false)
                    }
                  />
                </div>
                <AddVehicleFunction
                  open={openAddVehicleFunctionDialog}
                  toggleDialog={toggleDialog}
                />
                <EditVehicleFunction
                  open={openEditVehicleFunctionDialog}
                  setOpenEditVehicleFunctionDialog={
                    setOpenEditVehicleFunctionDialog
                  }
                  selectedRow={selectedRow}
                />

                <DeleteVehicleFunction
                  openDeleteVehicleFunctionDialog={
                    openDeleteVehicleFunctionDialog
                  }
                  setOpenDeleteVehicleFunctionDialog={
                    setOpenDeleteVehicleFunctionDialog
                  }
                  selectedRow={selectedRow}
                />
              </Paper>
            </Box>
          </List>
        </Collapse>
      </List>
    </div>
  );
}

export default VehicleFunction;

// Styling for the Users component
const styles = {
  container: {
    width: "100%",
    overflow: "auto",
  },
  tblFun: {
    mt: 2,
    mb: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};

const AddVehicleFunction = ({ open, toggleDialog }) => {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [abbreviation, setAbbreviation] = useState("");
  const [description, setDescription] = useState("");
  const [nameError, setNameError] = useState(false);
  const [abbreviationError, setAbbreviationError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  const handleClose = () => {
    toggleDialog(false)();
  };

  const handleClickAdd = () => {
    const reqObj = {
      current_time: new Date(),
      abbreviation: abbreviation,
      name: name,
    };

    if (description?.trim()) {
      reqObj.description = description;
    }
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.add_vehicle_function,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...adminSettingsAPI.get_vehicle_function,
        })
      );
    });
    setName("");
    setDescription("");
    setAbbreviation("");
    toggleDialog(false)();
  };

  return (
    <>
      <CustomAddDialog
        open={open}
        onClose={handleClose}
        dialogTitle={"Add Vehicle Function"}
        dialogContent={
          <Stack spacing={2}>
            <CustomTextFieldSP
              size={"small"}
              label={"Name"}
              placeholder={"Name"}
              value={name}
              validationRules={{ minLength: 3 }}
              setValue={setName}
              txtFldStyle={{
                width: "270px",
              }}
              setError={setNameError}
              error={nameError !== ""}
            />
            <CustomTextFieldSP
              size={"small"}
              label={"Abbreviation"}
              placeholder={"Abbreviation"}
              value={abbreviation}
              setValue={setAbbreviation}
              validationRules={{ minLength: 2 }}
              txtFldStyle={{
                width: "270px",
              }}
              setError={setAbbreviationError}
              error={abbreviationError !== ""}
            />
            <CustomTextFieldSP
              size={"small"}
              label={"Description"}
              placeholder={"Description"}
              value={description}
              validationRules={{ minLength: 5 }}
              setValue={setDescription}
              txtFldStyle={{
                width: "270px",
              }}
              setError={setDescriptionError}
              error={descriptionError !== ""}
            />
          </Stack>
        }
        onConfirm={handleClickAdd}
        onDisabled={
          name?.length > 2 &&
          name?.trim() !== "" &&
          abbreviation?.length > 1 &&
          abbreviation?.trim() !== ""
            ? false
            : true
        }
      />
    </>
  );
};

const EditVehicleFunction = ({
  open,
  setOpenEditVehicleFunctionDialog,
  selectedRow,
}) => {
  const dispatch = useDispatch();
  customConsole("selectedRow");

  customConsole(selectedRow);

  const [editName, setEditName] = useState("");
  const [editAbbreviation, setEditAbbreviation] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);

  React.useEffect(() => {
    if (selectedRow) {
      setEditName(selectedRow?.name || "");
      setEditAbbreviation(selectedRow?.abbreviation || "");
      setEditDescription(selectedRow?.other_fields?.description || "");
    }
  }, [selectedRow]);
  React.useEffect(() => {
    customConsole("selectedRow");
    customConsole(selectedRow);
    customConsole("name");
    customConsole(selectedRow?.name);
  }, [selectedRow]);

  const handleClose = () => {
    setOpenEditVehicleFunctionDialog(false);
  };

  const handleClickEdit = () => {
    const reqObj = {
      current_time: new Date(),
      id: selectedRow?.id,
      description: editDescription,
    };
    customConsole("reqObj");
    customConsole(reqObj);
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.update_vehicle_function,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_function }));
    });
    setOpenEditVehicleFunctionDialog(false);
  };

  return (
    <>
      <CustomUpdateDialog
        open={open}
        onClose={handleClose}
        dialogTitle={"Edit Vehicle Function"}
        dialogContent={
          <Stack spacing={2}>
            <CustomTextField1
              size={"small"}
              label={"Name"}
              placeholder={"Name"}
              value={editName}
              setValue={setEditName}
              txtFldStyle={{
                width: "270px",
              }}
              disabled={true}
            />
            <CustomTextField1
              size={"small"}
              label={"Abbreviation"}
              placeholder={"Abbreviation"}
              value={editAbbreviation}
              setValue={setEditAbbreviation}
              txtFldStyle={{
                width: "270px",
              }}
              disabled={true}
            />
            <CustomTextFieldSP
              size={"small"}
              label={"Description"}
              placeholder={"Description"}
              value={editDescription}
              setValue={setEditDescription}
              txtFldStyle={{
                width: "270px",
              }}
              validationRules={{ minLength: 5 }}
              setError={setDescriptionError}
              error={descriptionError !== ""}
            />
          </Stack>
        }
        onConfirm={handleClickEdit}
        onDisabled={
          editDescription?.length > 4 && editDescription.trim() !== ""
            ? false
            : true
        }
      />
    </>
  );
};

const DeleteVehicleFunction = ({
  openDeleteVehicleFunctionDialog,
  selectedRow,
  setOpenDeleteVehicleFunctionDialog,
}) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    const reqObj = {
      current_time: new Date(),
      id: selectedRow?.id,
    };
    customConsole("reqObj");
    customConsole(reqObj);
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.delete_vehicle_function,
        params: reqObj,
        show_toast: true,
      })
    );

    setTimeout(() => {
      dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_function }));
    }, 500);
    setOpenDeleteVehicleFunctionDialog(false);
  };
  return (
    <CustomConfirmationDialog
      open={openDeleteVehicleFunctionDialog}
      onClose={() => setOpenDeleteVehicleFunctionDialog(false)}
      dialogTitle={"Confirm Delete"}
      dialogContent={
        <DialogContentText>Are you sure want to delete?</DialogContentText>
      }
      onConfirm={handleDelete}
    />
  );
};
