/**
 * @author Narender - narender@au79consulting.com
 * @date 06-07-2024
 * @description  Settings API details to perform a particular action
 */
import actionType from "../actionTypes";
const settingsApis = {
  get_app_meta_data: {
    api_name: "/UCM/settings/app_meta",
    https_method: "GET",
    action_type: actionType.GET_APP_META_DATA,
    description: "",
  },
};

export default settingsApis;
