import customConsole from "../../config/customConsole";
import actionType from "../actionTypes";

const initialState = {
  error: false,
  msg: "",
  typeApproval: [],
  cTypeApproval: [],
};

const typeApprovalReducer = (state = initialState, action) => {
  customConsole(action);
  switch (action.type) {
    case actionType.GET_CREATE_INTERSECTIONS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        cTypeApproval: action.payload.error
          ? []
          : action.payload.data.intersections,
      };
    case actionType.CREATE_INTERSECTIONS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GET_TICKETS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        typeApproval: action.payload.error ? [] : action.payload.data,
      };

    case actionType.UPDATE_PROJECT_STATUS_TICKETS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.RE_CREATE_TICKETS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.DELETE_DRAFT_TICKETS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.RESET_TYPE_APPROVAL:
      return initialState;
    default:
      return state;
  }
};

export default typeApprovalReducer;
