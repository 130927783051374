/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 27-09-2024
 * @description : Shows project details
 */
import React from "react";
import { Divider, Grid, Paper, Typography } from "@mui/material";
import helper from "../../utils/helper";

function ProjectDetails({ selectedProjectDetails, projectStatus }) {
  return (
    <Paper elevation={2} sx={styles.detailsContainer}>
      <Typography sx={styles.heading}>Project Details</Typography>
      <Divider sx={styles.divider} />
      <Grid container>
        <Grid item xs={12} md={6} lg={4} mt={2}>
          <Typography variant="subtitle2" sx={styles.nrDetailsTxt}>
            <span style={styles.txtBold}>Name: </span>
            {selectedProjectDetails?.name || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4} mt={2}>
          <Typography variant="subtitle2" sx={styles.nrDetailsTxt}>
            <span style={styles.txtBold}>Architecture: </span>
            {selectedProjectDetails?.architecture_name || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4} mt={2}>
          <Typography variant="subtitle2" sx={styles.nrDetailsTxt}>
            <span style={styles.txtBold}>Status: </span>
            {helper?.getObjectKeyByValue(
              projectStatus,
              selectedProjectDetails?.status
            ) || "-"}
          </Typography>
        </Grid>
        {/* <Grid item xs={12} md={6} lg={4} mt={2}>
          <Typography variant="subtitle2" sx={styles.nrDetailsTxt}>
            <span style={styles.txtBold}>Delivery Market: </span>
            {selectedProjectDetails?.delivery_market || "-"}
          </Typography>
        </Grid> */}
        {/* <Grid item xs={12} md={6} lg={4} mt={2}>
          <Typography variant="subtitle2" sx={styles.nrDetailsTxt}>
            <span style={styles.txtBold}>Owner: </span>
            {selectedProjectDetails?.owner_details?.length &&
            selectedProjectDetails?.owner_details[0]?.name &&
            selectedProjectDetails?.owner_details[0]?.email
              ? `${selectedProjectDetails?.owner_details[0]?.name} - ${selectedProjectDetails?.owner_details[0]?.email}`
              : "-"}
          </Typography>
        </Grid> */}
        {/* <Grid item xs={12} md={6} lg={4} mt={2}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="subtitle2"
                  sx={{ ...styles.nrDetailsTxt, marginRight: 1 }}
                >
                  <span style={styles.txtBold}>Co-Owners: </span>
                </Typography>
                <SelectTextFieldN
                  size="small"
                  label="Co-Owners"
                  variant="standard"
                  optionsList={coOwnersOptionsList}
                  selectedValue={selectedCoOwner}
                  handleOnChange={(e) => setSelectedCoOwner(e.target.value)}
                  sltFldStyle={styles.nrSelectTextFieldN}
                />
              </Box>
            </Grid> */}
      </Grid>
    </Paper>
  );
}

export default ProjectDetails;

// Styles
const styles = {
  detailsContainer: {
    minWidth: "100%",
    border: "1px solid grey",
    px: 2,
    pt: 0.4,
    pb: 2,
    my: 2,
  },
  nrDetailsTxt: {
    textAlign: "start",
  },
  txtBold: {
    fontWeight: "bold",
  },
  heading: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  divider: {
    mt: 0.4,
    mb: 1,
  },
};
