import {
  Box,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  IconButton,
  TextField,
  Stack,
  Select,
  DialogContentText,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  CustomAddDialog,
  CustomButton,
  CustomConfirmationDialog,
  CustomTextField1,
  CustomTextFieldSP,
  CustomUpdateDialog,
} from "../../components";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import customConsole from "../../config/customConsole";
import { useDispatch, useSelector } from "react-redux";
import { current } from "@reduxjs/toolkit";
import moment from "moment";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import adminSettingsAPI from "../../redux/actions/adminSetttingAPI";
 
function ManufacturerDetails({ open, handleClick }) {
  const mftDetails = useSelector((state) => state.adminSettingsReducer);
  console.log("mftDetails", mftDetails);
 
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [tableRowsData, setTableRowsData] = useState([]);
  const [openAddMftDetails, setOpenAddMftDetails] = React.useState(false);
  const [openEditMftDetails, setOpenEditMftDetails] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [openDeleteMftDetails, setOpenDeleteMftDetails] = React.useState(false);
 
  const COLUMNS = [
    {
      field: "s_no",
      headerName: "S.No",
      width: 100,
    },
    { field: "mft_name", headerName: "Manufacturer Name", width: 400 },
    { field: "mft_address", headerName: "Manufacturer Address", width: 500 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <IconButton
          size="small"
          color="error"
          onClick={(e) => {
            e.stopPropagation();
            handleTableRowClick(params);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];
 
  useEffect(() => {
    const mappedData =
      mftDetails?.adminSettings?.data?.vehicle_manufacturers?.map(
        (item, index) => {
          return {
            ...item,
            id: index + 1,
            s_no: index + 1,
            mft_name: item?.name,
            mft_address: item?.address,
          };
        }
      );
    setTableRowsData(mappedData);
  }, [mftDetails]);
 
  const filteredRows = useMemo(() => {
    if (!searchQuery) return tableRowsData;
    return tableRowsData.filter((row) =>
      COLUMNS.some((column) =>
        row[column.field]
          ?.toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, tableRowsData, COLUMNS]);
 
  //Function to Click Row
  const handleTableRowClick = (params) => {
    const reqObj = {
      current_time: moment().format(),
      name: params?.row?.name,
      address: params?.row?.address,
    };
    setSelectedRow(reqObj);
    setOpenDeleteMftDetails(true);
  };
 
  const toggleDialog = (newOpen) => {
    setOpenAddMftDetails(newOpen);
  };
 
  const handleAddMftDetails = () => {
    toggleDialog(true);
  };
 
  return (
    <div>
      <List
        sx={{
          width: "100%",
          maxWidth: "100%",
          marginTop: "15px",
          backgroundColor: "whitesmoke",
          boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.2)",
          borderRadius: "0px",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={() => handleClick("6")}>
          <ListItemText
            sx={{
              textAlign: "start",
              color: "black",
            }}
            primary="Manufacturer Details"
            primaryTypographyProps={{
              fontWeight: 600,
              fontSize: "18px",
            }}
          />
          {open === "6" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
 
        <Collapse in={open === "6"} timeout="auto" unmountOnExit>
          <Divider sx={{ margin: "10px" }} />
          <List
            component="div"
            disablePadding
            sx={{
              background: "whitesmoke",
              margin: "10px",
              padding: "10px",
              borderRadius: "10px",
 
              maxHeight: "500px",
            }}
          >

            <Box>
              <Paper sx={styles.container}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "60px",
                    mr: "10px",
                    ml: "10px",
                  }}
                >
                  <Box>
                  <TextField
                    variant="standard"
                    label="Search"
                    size="small"
                    margin="normal"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{ margin: 0 }}
                  />
                  </Box>


<Box sx={styles.tblFun}>
              <CustomButton
                size={"medium"}
                variant={"contained"}
                btnName={"Add"}
                startIcon={<AddIcon />}
                handleOnClick={handleAddMftDetails}
                btnStyle={{
                  ml: 2,
                  // borderRadius: "0px",
                }}
              />
            </Box>
                </Box>
 
                <div style={{ height: 400, width: "100%" }}>
                  <DataGrid
                    sx={{
                      "& .MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal.css-1rtad1":
                        {
                          display: "block",
                        },
                      "& .MuiDataGrid-columnHeaders": {
                        position: "sticky",
                        top: 0, 
                        zIndex: 1,
                        backgroundColor: "white",
                        "& .MuiDataGrid-columnHeaderTitle": {
                          fontWeight: "bold",
                        },
                      },
                    }}
                    localeText={{ toolbarDensity: "View" }}
                    // autoHeight={true}
                    rows={filteredRows}
                    disableColumnMenu
                    disableColumnSelector
                    disableColumnFilter
                    disableRowSelectionOnClick
                    slots={{ toolbar: GridToolbar }}
                    columns={COLUMNS}
                    pageSize={rowsPerPage}
                    rowCount={
                      tableRowsData?.length >= rowsPerPage
                        ? -1
                        : page * rowsPerPage + tableRowsData?.length
                    }
                    pageSizeOptions={[25, 50, 100]}
                    paginationModel={{ page: page, pageSize: rowsPerPage }}
                    onPaginationModelChange={(model) => {
                      setPage(model.page);
                      setRowsPerPage(model.pageSize);
                    }}
                  />
                </div>
 
                <AddManufacturerDetails
                  open={openAddMftDetails}
                  toggleDialog={toggleDialog}
                />
 
                <DeleteManufacturerDetails
                  openDeleteMftDetails={openDeleteMftDetails}
                  setOpenDeleteMftDetails={setOpenDeleteMftDetails}
                  selectedRow={selectedRow}
                />
              </Paper>
            </Box>
          </List>
        </Collapse>
      </List>
    </div>
  );
}
 
export default ManufacturerDetails;
 
const styles = {
  container: {
    width: "100%",
    overflow: "auto",
  },
  tblFun: {
    mt: 2,
    mb: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  dataGridStyle: { height: 300, width: "100%" },
  dataGrid: {
    "& .MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal.css-1rtad1": {
      display: "block",
    },
    "& .MuiDataGrid-columnHeaders": {
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: "bold",
      },
    },
    position: "sticky",
  },
};
 
const AddManufacturerDetails = ({ open, toggleDialog }) => {
  const dispatch = useDispatch();
 
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [nameError, setNameError] = useState(false);
  const [addressError, setAddressError] = useState(false);
 
  const handleClose = () => {
    toggleDialog(false);
 
    setName("");
    setAddress("");
  };
 
  const handleClickAdd = () => {
    const reqObj = {
      current_time: moment().format(),
      name: name,
      address: address,
    };
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.add_mft_details,
        show_toast: true,
        params: reqObj,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...adminSettingsAPI.get_admin_settings,
          show_toast: false,
        })
      );
    });
    toggleDialog(false);
    setName("");
    setAddress("");
  };
 
  return (
    <div>
      <CustomAddDialog
        open={open}
        onClose={handleClose}
        dialogTitle={"Add Manufacturer Details"}
        dialogContent={
          <Stack spacing={2}>
            <CustomTextFieldSP
              size="small"
              label="Name"
              placeholder="Name"
              value={name}
              setValue={setName}
              txtFldStyle={{ width: "270px" }}
              validationRules={{ minLength: 3 }}
              setError={setNameError}
              error={nameError !== ""}
            />
            <CustomTextFieldSP
              size="small"
              label="Address"
              placeholder="Address"
              value={address}
              setValue={setAddress}
              txtFldStyle={{ width: "270px" }}
              validationRules={{ minLength: 2 }}
              setError={setAddressError}
              error={addressError !== ""}
            />
          </Stack>
        }
        onConfirm={handleClickAdd}
        onDisabled={
          name?.length > 2 &&
            name?.trim() !== "" &&
            address?.length > 1 &&
            address?.trim() !== ""
            ? false
            : true
        }
      />
    </div>
  );
};
 
const DeleteManufacturerDetails = ({
  openDeleteMftDetails,
  setOpenDeleteMftDetails,
  selectedRow,
}) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.delete_mft_details,
        show_toast: true,
        params: selectedRow,
      })
    ).then(() => {
      setTimeout(() => {
        dispatch(
          HandleApiActions({
            ...adminSettingsAPI.get_admin_settings,
            show_toast: false,
          })
        );
      }, 500);
    });
    setOpenDeleteMftDetails(false);
  };
  return (
    <CustomConfirmationDialog
      open={openDeleteMftDetails}
      onClose={() => setOpenDeleteMftDetails(false)}
      dialogTitle={"Confirm Delete"}
      dialogContent={
        <DialogContentText>Are you sure want to delete?</DialogContentText>
      }
      onConfirm={handleDelete}
    />
  );
};
 
 