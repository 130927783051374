import React, { useState, useEffect } from "react";
import { Badge, IconButton } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Notifications from "./Notifications";
import { useDispatch, useSelector } from "react-redux";
import notificationsAction from "../../redux/actions/notification";
import { HandleApiActions } from "../../redux/actions";
import customConsole from "../../config/customConsole";

const NotificationIcon = () => {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const dispatch = useDispatch();

  // Access notifications from Redux state with safety checks
  const [recentNotificationDetails, setRecentNotificationDetails] = useState(
    []
  );
  const [loadingNotifications, setLoadingNotifications] = useState(false); // New loading state

  const notificationCount = recentNotificationDetails;

  console.log("recentNotificationDetails", recentNotificationDetails);
  const handleBellClick = () => {
    setNotificationOpen((prev) => !prev);
  };

  const handleClose = () => {
    setNotificationOpen(false);
  };

  useEffect(() => {
    // Fetch notifications initially
    getRecentNotificationDetails();

    // Set up polling for new notifications
    const interval = setInterval(() => {
      getRecentNotificationDetails();
    }, 5000); // Check every 5 seconds

    // Clean up the interval on unmount
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getRecentNotificationDetails = async () => {
    // setLoadingNotifications(true); // Set loading state before the call

    let apiDetails = notificationsAction.get_recent_notifications;
    // delete apiDetails.action_type;
    customConsole({
      ...apiDetails,
      params: {},
      show_toast: false,
    });
    // const resp ={error:true,msg:""}

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );

    if (resp.error) {
      setRecentNotificationDetails([]);
    } else {
      setRecentNotificationDetails(resp.data.unread_notifications);
    }
    // setLoadingNotifications(false); // Reset loading state after the call
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleBellClick}>
        <Badge
          badgeContent={notificationCount}
          color="error"
          invisible={notificationCount === 0}
        >
          <NotificationsIcon sx={{ color: "white" }} />
        </Badge>
      </IconButton>

      {notificationOpen && <Notifications onClose={handleClose} />}
    </>
  );
};

export default NotificationIcon;
