/**
 * @author : Bharathan - bharathab@au79consulting.com
 * @Date : 28-09-2024
 * @description Notificaation API details
*/

import actionType from "../actionTypes";

const notificationsAction = {
    get_recent_notifications: {
        api_name: "/UCM/notifications/recent",
        https_method: "GET",
        action_type: actionType.GET_RECENT_NOTIFICATIONS,
        description: "",
    },
    get_all_notifications: {
        api_name: "/UCM/notifications/all",
        https_method: "GET",
        action_type: actionType.GET_ALL_NOTIFICATIFICATIONS,
        description: "",
    },
    mark_read_notifications: {
        api_name: "/UCM/notifications/mark-read",
        https_method: "PUT",
        action_type: actionType.NOTIFICATIONS_MARK_READ,
        description: "",
    },
}

export default notificationsAction;