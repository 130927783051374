/**
 * @author Abdul Rahim M - abdul@au79consulting.com
 * @date 1-07-2024
 * @description Component for handling the Vehicle Class.
 */
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DefaultProfilePic from "./../../assets/logoIcons/defaultProfilePic.png";

import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import React, { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import CustomButton from "../../components/buttons/CustomButton";
import CustomTextField1 from "../../components/text-fields/CustomTextField1";
import customConsole from "../../config/customConsole";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";

import { HandleApiActions } from "../../redux/actions/actionHandler";

import { toast } from "react-toastify";
import profileActions from "../../redux/actions/profile";

import AddIcon from "@mui/icons-material/Add";
import CustomTextFieldSP from "../../components/text-fields/CustomTextFieldSP";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import adminSettingsAPI from "../../redux/actions/adminSetttingAPI";
import { convertUtcToTimeZone } from "../../utils/convertUtcToTimeZone";
import CustomAddDialog from "../../components/dialog-boxes/CustomAddDialog";
import CustomConfirmationDialog from "../../components/dialog-boxes/CustomConfirmationDialog";
import CustomUpdateDialog from "../../components/dialog-boxes/CustomUpdateDialog";

function VehicleClass({ open, handleClick }) {
  const COLUMNS = [
    { field: "sno", headerName: "S.No", width: 100 },

    { field: "name", headerName: "Name", width: 400 },
    {
      field: "class",
      headerName: "Class",
      width: 150,
    },
    {
      field: "sub_class",
      headerName: "Sub Class",
      width: 150,
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
    },
    {
      field: "created_at",
      headerName: "Created",
      width: 150,
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 150,
    },
    {
      field: "image",
      headerName: "Image",
      width: 150,
      renderCell: (params) => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleTableRowClick(params, "3");
          }}
        >
          <VisibilityIcon />
        </IconButton>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <IconButton
          size="small"
          color="error"
          onClick={(e) => {
            e.stopPropagation();
            handleTableRowClick(params, "1");
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];
  //1.Redux
  const dispatch = useDispatch();
  const vehicleClassDetails = useSelector((state) => state.vehicleClassReducer);
  const profilePathDetails = useSelector((state) => state?.profileReducer);
  const timeZoneDetails = useSelector((state) => state.profileReducer);

  //2.React useState
  const [page, setPage] = React.useState(0);
  const [tableRowsData, setTableRowsData] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [getVehicleClass, setGetVehicleClass] = React.useState([]);
  const [profilepicpath, setProfilePicPath] = React.useState("");
  const [profilePng, setProfilePng] = React.useState("");

  const [openAddVehicleClassDialog, setOpenAddVehicleClassDialog] =
    React.useState(false);
  const [openEditVehicleClassDialog, setOpenEditVehicleClassDialog] =
    React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [deleteVehicleClassDialog, setDeleteVehicleClassDialog] =
    React.useState(false);
  const [timeZone, setTimeZone] = React.useState([]);
  const [imageFile, setImageFile] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  //3.React useEffect
  useEffect(() => {
    let filteredUsersList = getVehicleClass?.map((data, index) => {
      return {
        ...data,
        sno: index + 1,
        id: data?.id,

        name: data?.name,
        class: data?.class,
        subclass: data?.subclass,
        description: data?.other_fields?.description,
        created_at: convertUtcToTimeZone(
          data?.created_at,
          timeZoneDetails?.profile?.profile?.region
        ),
        updated_at: convertUtcToTimeZone(
          data?.updated_at,
          timeZoneDetails?.profile?.profile?.region
        ),
      };
    });

    setTableRowsData(filteredUsersList);
  }, [getVehicleClass]);

  const filteredRows = useMemo(() => {
    if (!searchQuery) return tableRowsData;
    return tableRowsData.filter((row) =>
      COLUMNS.some((column) =>
        row[column.field]
          ?.toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, tableRowsData, COLUMNS]);

  useEffect(() => {
    dispatch(HandleApiActions({ ...profileActions.get_coutry_regions }));
  }, []);
  useEffect(() => {
    if (
      timeZoneDetails &&
      timeZoneDetails?.profile &&
      timeZoneDetails?.profile?.profile &&
      timeZoneDetails?.profile?.profile?.region &&
      timeZoneDetails?.profile?.profile?.region.length > 0
    ) {
      customConsole("timeZoneDetails?.data?.profile?.region");
      customConsole(timeZoneDetails?.profile?.profile?.region);
      setTimeZone(timeZoneDetails?.profile?.profile?.region);
    }
  }, [timeZoneDetails]);

  useEffect(() => {
    if (
      profilePathDetails?.profile_pic_name &&
      profilePathDetails?.profile_pic_name.length > 0
    ) {
      customConsole("profilePathDetails?.profile_pic_name");
      customConsole(profilePathDetails?.profile_pic_name);
      setProfilePng(profilePathDetails?.profile_pic_name);
    }
  }, [profilePathDetails]);

  useEffect(() => {
    if (profilePng && profilePng.length) {
      dispatch(
        HandleApiActions({
          ...profileActions.get_profile_pic,
          show_toast: true,
          file_name: profilePng,
          is_file: true,
        })
      );
    }
  }, []);

  useEffect(() => {
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_class }));
  }, []);

  useEffect(() => {
    customConsole("profilePathDetails?.profile_pic_path");
    customConsole(profilePathDetails?.profile_pic_path);
    setProfilePicPath(profilePathDetails?.profile_pic_path);
  }, [profilePathDetails]);

  useEffect(() => {
    customConsole("VehicleClass Train Details");
    customConsole(vehicleClassDetails);

    setGetVehicleClass(
      vehicleClassDetails?.vehicleClassDetails?.data?.length
        ? vehicleClassDetails?.vehicleClassDetails?.data
        : []
    );
  }, [vehicleClassDetails]);

  //4.Functions
  const toggleDialog = (newOpen) => () => {
    setOpenAddVehicleClassDialog(newOpen);
  };

  const handleAddVehicleClassClick = () => {
    toggleDialog(true)();
  };

  const handleTableRowClick = (row, isButtonClicked) => {
    setSelectedRow(row);

    customConsole("Button Clicked: " + isButtonClicked);

    switch (isButtonClicked) {
      case "1":
        setDeleteVehicleClassDialog(true);
        setOpenEditVehicleClassDialog(false);
        setOpenModal(false);
        break;
      case "2":
        setOpenEditVehicleClassDialog(true);
        setDeleteVehicleClassDialog(false);
        setOpenModal(false);
        break;
      case "3":
        setOpenModal(true);
        setOpenEditVehicleClassDialog(false);
        setDeleteVehicleClassDialog(false);
        customConsole("Row.image");
        customConsole(row?.image);
        dispatch(
          HandleApiActions({
            ...profileActions.get_profile_pic,
            show_toast: true,
            file_name: row?.row?.image,
            is_file: true,
          })
        );
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <List
        sx={{
          width: "100%",
          maxWidth: "100%",
          marginTop: "15px",
          backgroundColor: "whitesmoke",
          boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.2)",
          borderRadius: "0px",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={() => handleClick("5")}>
          <ListItemText
            sx={{
              textAlign: "start",
              color: "black",
            }}
            primary="Vehicle Class"
            primaryTypographyProps={{
              fontWeight: 600,
              fontSize: "18px",
            }}
          />
          {open === "5" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open === "5"} timeout="auto" unmountOnExit>
          <Divider sx={{ margin: "10px" }} />
          <List
            component="div"
            disablePadding
            sx={{
              background: "whitesmoke",
              margin: "10px",
              padding: "10px",
              borderRadius: "10px",

              maxHeight: "500px",
            }}
          >
            <Box>
              <Paper sx={styles.container}>
                  <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "60px",
                    mr: "10px",
                    ml: "10px",
                  }}
                  >
                    <Box>
                    <TextField
                      variant="standard"
                      label="Search"
                      size="small"
                      margin="normal"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      sx={{ margin: 0 }}
                    />
                    </Box>

                                <Box sx={styles.tblFun}>
              <CustomButton
                size={"medium"}
                variant={"contained"}
                btnName={"Add"}
                startIcon={<AddIcon />}
                handleOnClick={handleAddVehicleClassClick}
                btnStyle={{
                  ml: 2,
                  // borderRadius: "0px",
                }}
              />
            </Box>
                  </Box>
                  <div style={{ height: 400, width: "100%" }}>
                  <DataGrid
                    initialState={{
                      density: "comfortable",
                    }}
                    sx={{
                      "& .MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal.css-1rtad1":
                        {
                          display: "block",
                        },
                      "& .MuiDataGrid-columnHeaders": {
                        "& .MuiDataGrid-columnHeaderTitle": {
                          fontWeight: "bold",
                        },
                      },
                    }}
                    localeText={{ toolbarDensity: "View" }}
                    rowHeight={60}
                    rows={filteredRows}
                    columns={COLUMNS}
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    disableRowSelectionOnClick
                    rowCount={
                      tableRowsData.length >= rowsPerPage
                        ? -1
                        : page * rowsPerPage + tableRowsData.length
                    }
                    slots={{ toolbar: GridToolbar }}
                    pageSize={rowsPerPage}
                    onPaginationModelChange={(model) => {
                      setPage(model.page);
                      setRowsPerPage(model.pageSize);
                    }}
                    paginationModel={{ page: page, pageSize: rowsPerPage }}
                    pageSizeOptions={[25, 50, 100]}
                    slotProps={{
                      toolbar: {
                        printOptions: { disableToolbarButton: true },
                      },
                    }}
                    onRowClick={(params) => handleTableRowClick(params, "2")}
                  />
                </div>

                <AddVehicleClass
                  setImageFile={setImageFile}
                  open={openAddVehicleClassDialog}
                  toggleDialog={toggleDialog}
                />
                <EditVehicleClass
                  open={openEditVehicleClassDialog}
                  imageFile={imageFile}
                  setOpenEditVehicleClassDialog={setOpenEditVehicleClassDialog}
                  selectedRow={selectedRow}
                />

                <DeleteVehicleClass
                  deleteVehicleClassDialog={deleteVehicleClassDialog}
                  selectedRow={selectedRow}
                  setDeleteVehicleClassDialog={setDeleteVehicleClassDialog}
                />
              </Paper>
              {openModal && (
                <Dialog open={openModal} onClose={() => setOpenModal(false)}>
                  <DialogContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <Typography variant="h6">View Image</Typography>
                      <IconButton
                        onClick={() => setOpenModal(false)}
                        color="inherit"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    <img
                      alt=""
                      src={profilepicpath ? profilepicpath : DefaultProfilePic}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain", // Adjust object-fit as needed
                      }}
                    />
                  </DialogContent>
                </Dialog>
              )}
            </Box>
          </List>
        </Collapse>
      </List>
    </div>
  );
}

export default VehicleClass;

// Styling for the Users component
const styles = {
  container: {
    width: "100%",
    overflow: "auto",
  },
  tblFun: {
    mt: 2,
    mb: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};

const AddVehicleClass = ({ open, toggleDialog, setImageFile }) => {
  const dispatch = useDispatch();
  const profilePathDetails = useSelector((state) => state?.profileReducer);

  const [name, setName] = useState("");
  const [className, setClassName] = useState("");
  const [subClass, setSubClass] = useState("");
  const [description, setDescription] = useState("");
  const [nameError, setNameError] = useState(false);
  const [classNameError, setClassNameError] = useState(false);
  const [subClassError, setSubClassError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [profilePic, setProfilePic] = useState(null);

  const [profilePng, setProfilePng] = React.useState("");

  React.useEffect(() => {
    customConsole("fileNameDetailssssssssss");
    customConsole(profilePathDetails?.profile_pic_path);

    setProfilePng(profilePathDetails?.profile_pic_name);
  }, [profilePathDetails]);

  const handleClose = () => {
    toggleDialog(false)();
  };

  const handleClickAdd = () => {
    const reqObj = {
      current_time: new Date(),
      sub_class: subClass,
      class: className,
      name: name,

      image: profilePng,
    };

    if (profilePng === "") {
      toast.warn("Upload Pic");
    }
    if (description?.trim()) {
      reqObj.description = description;
    }
    setImageFile(profilePng);
    customConsole("profilePng");

    customConsole("reqObj");
    customConsole(reqObj);

    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.add_vehicle_class,
        params: reqObj,
      })
    ).then(() => {
      dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_class }));
    });
    toggleDialog(false)();
    setName("");
    setClassName("");
    setSubClass("");
    setDescription("");
    setProfilePic(null);
  };

  const handelUploadProfile = (event) => {
    const file = event.target.files[0];
    setProfilePic(file.name);

    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const allowedExtensions = ["jpg", "jpeg", "png"];
      setImageFile();

      if (allowedExtensions.includes(fileExtension)) {
        // Dispatch the action to upload the file
        dispatch(
          HandleApiActions({
            ...profileActions.upload_profile_pic,
            show_toast: false,
            file: file,
            is_file: true,
          })
        ).then((response) => {
          toast.success("Image uploaded successfully", {
            position: "bottom-right",
            autoClose: 3000,
          });
        });
      }
    }
  };

  return (
    <>
      <CustomAddDialog
        open={open}
        onClose={handleClose}
        dialogTitle={"Add Vehicle Class"}
        dialogContent={
          <Stack spacing={2}>
            <CustomTextFieldSP
              size={"small"}
              label={"Name"}
              placeholder={"Name"}
              value={name}
              validationRules={{ minLength: 2 }}
              setValue={setName}
              txtFldStyle={{
                width: "270px",
              }}
              setError={setNameError}
              error={nameError !== ""}
            />
            <CustomTextFieldSP
              size={"small"}
              label={"Class"}
              placeholder={"Class"}
              validationRules={{ minLength: 2 }}
              value={className}
              setValue={setClassName}
              txtFldStyle={{
                width: "270px",
              }}
              setError={setClassNameError}
              error={classNameError !== ""}
            />
            <CustomTextFieldSP
              size={"small"}
              label={"Subclass"}
              placeholder={"Subclass"}
              value={subClass}
              validationRules={{ minLength: 2 }}
              setValue={setSubClass}
              txtFldStyle={{
                width: "270px",
              }}
              setError={setSubClassError}
              error={subClassError !== ""}
            />
            <CustomTextFieldSP
              size={"small"}
              label={"Description"}
              placeholder={"Description"}
              value={description}
              validationRules={{ minLength: 5 }}
              setValue={setDescription}
              txtFldStyle={{
                width: "270px",
              }}
              setError={setDescriptionError}
              error={descriptionError !== ""}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Typography sx={{ marginBottom: "5px", fontWeight: "bold" }}>
                Upload Image
              </Typography>
              <Button
                size="small"
                sx={{
                  display: "flex",

                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  width: "70%",
                }}
                component="label"
                endIcon={<UploadFileIcon color="blue" size={18} />}
              >
                <input
                  accept="image/*"
                  type="file"
                  onChange={handelUploadProfile}
                />
              </Button>
            </Box>
          </Stack>
        }
        onConfirm={handleClickAdd}
        onDisabled={
          name?.length > 1 &&
          name?.trim() !== "" &&
          className?.length > 1 &&
          className?.trim() !== "" &&
          subClass?.length > 1 &&
          subClass?.trim() !== ""
            ? false
            : true
        }
      />
    </>
  );
};

const EditVehicleClass = ({
  open,
  setOpenEditVehicleClassDialog,
  selectedRow,
  imageFile,
}) => {
  const dispatch = useDispatch();
  const profilePathDetails = useSelector((state) => state?.profileReducer);

  customConsole("selectedRow");

  customConsole(selectedRow);

  const [name, setName] = useState("");
  const [className, setClassName] = useState("");
  const [subClass, setSubClass] = useState("");
  const [description, setDescription] = useState("");

  const [descriptionError, setDescriptionError] = useState(false);
  const [profilePng, setProfilePng] = React.useState("");

  const [isDescriptionChanged, setIsDescriptionChanged] = useState(false);

  React.useEffect(() => {
    customConsole("fileNameDetailssssssssss");
    customConsole(profilePathDetails?.profile_pic_path);

    setProfilePng(profilePathDetails?.profile_pic_name);
  }, [profilePathDetails]);

  useEffect(() => {
    if (description !== setDescription) {
      setIsDescriptionChanged(true);
    } else {
      setIsDescriptionChanged(false);
    }
  }, [description, setDescription]);

  React.useEffect(() => {
    if (selectedRow) {
      setName(selectedRow?.row?.name || "");
      setClassName(selectedRow?.row?.class || "");
      setSubClass(selectedRow?.row?.sub_class || "");
      setDescription(selectedRow?.row?.other_fields?.description);
    }
  }, [selectedRow]);
  React.useEffect(() => {
    customConsole("selectedRow");
    customConsole(selectedRow);
    customConsole("name");
    customConsole(selectedRow?.name);
  }, [selectedRow]);

  const handleClose = () => {
    setOpenEditVehicleClassDialog(false);
  };
  const handleClickEdit = () => {
    const selectedRowId = selectedRow?.row?.id;

    const reqObj = {
      current_time: new Date(),
      id: selectedRowId ? parseInt(selectedRowId) : null,
      ...(profilePng?.length > 0 ? { image: profilePng } : {}),
      ...(!profilePng?.length > 0 && description?.length > 0
        ? { description: description }
        : {}),
    };

    customConsole("reqUpdateObj", reqObj);

    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.update_vehicle_class,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_class }));
    });

    setOpenEditVehicleClassDialog(false);
    setProfilePng("");
    setDescription("");
  };

  const handelUploadProfile = (event) => {
    const file = event.target.files[0];
    customConsole("file......", file);

    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const allowedExtensions = ["jpg", "jpeg", "png"];

      if (allowedExtensions.includes(fileExtension)) {
        // Dispatch the action to upload the file
        dispatch(
          HandleApiActions({
            ...profileActions.upload_profile_pic,
            show_toast: true,
            file: file,
            is_file: true,
          })
        )
          .then((response) => {
            toast.success("Image uploaded successfully", {
              position: "bottom-right",
              autoClose: 3000,
            });
          })
          .catch((error) => {
            toast.error("Error uploading image", {
              position: "bottom-right",
              autoClose: 3000,
            });
          });
      } else {
        toast.error("Please select a valid image file", {
          position: "bottom-right",
          autoClose: 3000,
        });
      }
    }
  };

  return (
    <>
      <CustomUpdateDialog
        open={open}
        onClose={handleClose}
        dialogTitle={"Edit Vehicle Class"}
        dialogContent={
          <Stack spacing={2}>
            <CustomTextField1
              size={"small"}
              label={"Name"}
              placeholder={"Name"}
              value={name}
              setValue={setName}
              txtFldStyle={{
                width: "270px",
              }}
              disabled={true}
            />
            <CustomTextField1
              size={"small"}
              label={"Class"}
              placeholder={"Class"}
              value={className}
              setValue={setClassName}
              txtFldStyle={{
                width: "270px",
              }}
              disabled={true}
            />
            <CustomTextField1
              size={"small"}
              label={"Subclass"}
              placeholder={"Subclass"}
              value={subClass}
              setValue={setSubClass}
              txtFldStyle={{
                width: "270px",
              }}
              disabled={true}
            />
            <CustomTextFieldSP
              size={"small"}
              label={"Description"}
              placeholder={"Description"}
              value={description}
              setValue={setDescription}
              txtFldStyle={{
                width: "270px",
              }}
              validationRules={{ minLength: 5 }}
              setError={description.length === 0 ? null : setDescriptionError}
              error={description.length === 0 ? null : descriptionError}
            />

            <Typography sx={{ marginBottom: "5px", fontWeight: "bold" }}>
              Upload Image
            </Typography>
            <Button
              size="small"
              sx={{
                display: "flex",

                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
              component="label"
              endIcon={<UploadFileIcon color="blue" size={18} />}
            >
              <input
                accept="image/*"
                type="file"
                style={{ display: "none" }}
                onChange={handelUploadProfile}
              />
            </Button>
          </Stack>
        }
        onConfirm={handleClickEdit}
      />
    </>
  );
};

const DeleteVehicleClass = ({
  deleteVehicleClassDialog,
  selectedRow,
  setDeleteVehicleClassDialog,
}) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    const reqObj = {
      current_time: new Date(),
      id: selectedRow?.id,
    };
    customConsole("reqObj");
    customConsole(reqObj);
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.delete_vehicle_class,
        params: reqObj,
      })
    ).then(() => {
      dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_class }));
    });
    setDeleteVehicleClassDialog(false);
  };
  return (
    <CustomConfirmationDialog
      open={deleteVehicleClassDialog}
      onClose={() => setDeleteVehicleClassDialog(false)}
      dialogTitle={"Confirm Delete"}
      dialogContent={
        <DialogContentText>Are you sure want to delete?</DialogContentText>
      }
      onConfirm={handleDelete}
    />
  );
};
