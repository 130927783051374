import { Reducer } from "redux";
import actionType from "../actionTypes";
import customConsole from "../../config/customConsole";

const initialState = {
  error: false,
  msg: "",
  deliveryMarketDetails: [],
};

const deliveryMarketReducers = (state = initialState, action) => {
  customConsole(action);
  switch (action.type) {
    case actionType.ADD_DELIVERY_MARKET_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };

    case actionType.DELETE_DELIVERY_MARKET_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };

    default:
      return state;
  }
};

export default deliveryMarketReducers;
