/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 27-08-2024
 * @description : Custom dialog for logs.
 */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import CustomPagination from "../tables/CustomPagination";
import CustomPaginationSmall from "../tables/CustomPaginationSmall";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { convertUtcToTimeZone } from "../../utils/convertUtcToTimeZone";
import customConsole from "../../config/customConsole";
import { shallowEqual, useSelector } from "react-redux";

/* Dialog Customization */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

/* Accordion Customization */
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles("dark", {
    backgroundColor: "rgba(255, 255, 255, .05)",
  }),
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function CustomLogsDialog({
  open,
  handleClose,
  dialogTitle,
  dialogContent,
  data,
  getLogsData,
}) {
  /* Get roles list for the store */
  const { rolesList } = useSelector(
    (state) => state.userManagementReducer,
    shallowEqual
  );
  const profileDetails = useSelector((store) => store.profileReducer);

  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [hasMore, setHasMore] = useState(false);
  const [details, setDetails] = useState("");
  const [logsList, setLogsList] = useState([]);
  const [userRolesList, setUserRolesList] = useState(null);

  // Accordion
  const [expanded, setExpanded] = React.useState("");

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to the first page when page size changes
  };

  // Handle accordion change
  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    const userRolesObject = rolesList.reduce((acc, el) => {
      const roleId = parseInt(el?.role_id, 10);
      if (!isNaN(roleId)) {
        acc[roleId] = {
          role_name: el?.role_name,
          role_department: el?.department,
        };
      }
      return acc;
    }, {});

    setUserRolesList(userRolesObject);
  }, [rolesList]);

  useEffect(() => {
    customConsole("*------- Get Roles List -------*");
    customConsole({ userRolesList });
  }, [userRolesList]);

  /* useEffects */
  useEffect(() => {
    // Handle pagination hasMore
    if (dialogContent?.length < rowsPerPage) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }

    setLogsList(dialogContent || []);
  }, [dialogContent]);

  useEffect(() => {
    setDetails(data || {});
  }, [data]);

  useEffect(() => {
    if (open) {
      customConsole("*------- Get Change Logs -------*");
      customConsole({
        ee_id: Number(details?.ee_id),
        swm_id: Number(details?.swm_id),
        pageNo: page,
        pageSize: rowsPerPage,
      });

      getLogsData({
        ee_id: Number(details?.ee_id),
        swm_id: Number(details?.swm_id),
        pageNo: page,
        pageSize: rowsPerPage,
      });
    }
  }, [details, page, rowsPerPage]);

  return (
    <BootstrapDialog
      // onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      TransitionComponent={Transition}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle sx={styles.dialogTitle} id="customized-dialog-title">
        <Box>
          <Box
            sx={{
              pt: 0.4,
              pl: 2,
              pr: 0.4,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ flex: 1 }}>{dialogTitle}</Box>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={(theme) => ({
                ...styles.closeIconButton,
                color: theme.palette.grey[500],
              })}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider sx={{ color: "red" }} />
        <Paper elevation={0} sx={styles.headingsContainer}>
          <Typography sx={styles.headingMain}>
            <span style={styles.textBold}>Name: </span>
            {details?.name || details?.description}
          </Typography>
          {details?.version ? (
            <Typography sx={styles.headingMain}>
              <span style={styles.textBold}>Version: </span>
              {details?.version}
            </Typography>
          ) : null}
          <Typography sx={styles.headingMain}>
            <span style={styles.textBold}>Owner: </span>
            {details?.owner_details?.length
              ? details?.owner_details[0]?.name
              : ""}
          </Typography>
        </Paper>
      </DialogTitle>

      <DialogContent dividers sx={styles.dialogContent}>
        {logsList?.length ? (
          <Paper elevation={4}>
            {logsList?.map((el, index) => {
              return (
                <Accordion
                  expanded={expanded === `panel${index}`}
                  onChange={handleAccordionChange(`panel${index}`)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0.2,
                      }}
                    >
                      <Typography sx={styles.heading}>
                        <span style={styles.textBold}>Timestamp: </span>
                        {convertUtcToTimeZone(
                          el?.created_at,
                          profileDetails?.profile?.profile?.region
                        )}
                      </Typography>
                      <Typography sx={styles.heading}>
                        <span style={styles.textBold}>Changed by: </span>
                        {el?.changed_by?.name} - {el?.changed_by?.email}
                        {/* Show the roles and the department by the role id */}
                        {/* {userRolesList && el?.changed_by?.role_id ? (
                          <>
                            {" "}
                            -{" "}
                            {userRolesList[parseInt(el.changed_by.role_id, 10)]
                              ?.role_name || ""}{" "}
                            -{" "}
                            {userRolesList[parseInt(el.changed_by.role_id, 10)]
                              ?.role_department || ""}
                          </>
                        ) : null} */}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{
                        maxWidth: "100%",
                        overflow: "auto",
                        wordWrap: "break-word",
                      }}
                    >
                      <Typography>
                        <Typography sx={styles.heading}>
                          {/* <span style={styles.textBold}>Before: </span> */}
                          {/* {JSON.stringify(el?.before_changes)} */}

                          {/* Show the keys as bold and horizontally with the brackets */}
                          {/* {el?.before_changes ? (
                            <div>
                              {"{ "}
                              {Object.entries(el.before_changes)?.map(
                                ([key, value], index, array) => (
                                  <span key={index}>
                                    <strong>{key}</strong>:{" "}
                                    {JSON.stringify(value)}
                                    {index < array.length - 1 ? ", " : ""}
                                  </span>
                                )
                              )}
                              {" }"}
                            </div>
                          ) : null} */}

                          {/* Show the keys as bold and vertically without the brackets */}
                          {/* {el?.before_changes ? (
                            <Box sx={{ mx: 1 }}>
                              {Object.entries(el.before_changes)?.map(
                                ([key, value], index) => (
                                  <div key={index}>
                                    <strong>{key}</strong>:{" "}
                                    {JSON.stringify(value)}
                                  </div>
                                )
                              )}
                            </Box>
                          ) : null} */}
                        </Typography>
                      </Typography>
                      <Typography
                        sx={{
                          ...styles.heading,
                          mt: 1,
                        }}
                      >
                        <span style={styles.textBold}>Changes: </span>
                        {JSON.stringify(el?.current_changes)}
                      </Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Paper>
        ) : (
          <Box sx={styles.noLogsFoundContainer}>
            <Typography>No logs found</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        {/* <CustomPagination */}
        <CustomPaginationSmall
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          hasMore={hasMore}
        />
      </DialogActions>
    </BootstrapDialog>
  );
}

export default CustomLogsDialog;

// Styles
const styles = {
  dialogTitle: {
    m: 0,
    px: 0,
    pt: 0.4,
    textAlign: "center",
    boxShadow: 1,
  },
  closeIconButton: {
    // position: "absolute",
    // right: 8,
    // top: 7,
  },
  dialogContent: {
    height: "270px",
  },
  noLogsFoundContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dialogActions: {
    boxShadow: 4,
  },
  textBold: {
    fontWeight: "bold",
  },
  headingsContainer: {
    // mb: 2,
    // pb: 0.6,
    pt: 1,
    display: "flex",
    justifyContent: "space-around",
  },
  headingMain: {
    mb: -1,
  },
  heading: {
    fontSize: "14px",
  },
};
