import React, { useEffect, useState } from "react";
import CustomUpdateDialog from "../../components/dialog-boxes/CustomUpdateDialog";
import { Box, Stack, Typography } from "@mui/material";
import MutliSelectTextFieldVF from "../../components/text-fields/MultiSelectTextFieldVF";
import { useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import adminSettingsAPI from "../../redux/actions/adminSetttingAPI";
import customConsole from "../../config/customConsole";

function EditVehicleFunction({
  openVfDialog,
  setOpenVfDialog,
  editVF,
  setEditVF,
  handleClickEdit,
}) {
  //1.Redux
  const dispatch = useDispatch();
  const vehicleFunctionDetails = useSelector(
    (state) => state.vehicleFunctionReducer
  );

  //2.React useState
  const [vfData, setVfData] = useState([]);

  //3.React useEffect
  useEffect(() => {
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_function }));
  }, []);

  useEffect(() => {
    customConsole("vehicleFunctionDetails......");

    customConsole(vehicleFunctionDetails?.vehicleFunctionDetails?.data);

    if (
      vehicleFunctionDetails &&
      vehicleFunctionDetails?.vehicleFunctionDetails &&
      vehicleFunctionDetails?.vehicleFunctionDetails?.data
    ) {
      setVfData(vehicleFunctionDetails.vehicleFunctionDetails.data);
    }
  }, [vehicleFunctionDetails]);

  return (
    <div>
      <CustomUpdateDialog
        open={openVfDialog}
        onClose={() => {
          setOpenVfDialog(false);
        }}
        dialogTitle={"Add Vehicle Function"}
        dialogContent={
          <Stack spacing={2}>
            <Box sx={styled.box}>
              <Box sx={styled.secondaryBox}>
                <Typography sx={styled.topography}>
                  Select the Vehicle Function
                </Typography>
                <MutliSelectTextFieldVF
                  mutiple
                  size={"small"}
                  label={"Select Vehicle Function"}
                  optionsList={vfData}
                  selectedValues={editVF}
                  setSelectedValues={setEditVF}
                  sltFldStyle={styled.multiSelect}
                />
              </Box>
            </Box>
          </Stack>
        }
        onConfirm={handleClickEdit}
      />
    </div>
  );
}

export default EditVehicleFunction;

const styled = {
  box: {
    mt: "10px",
  },
  secondaryBox: {
    mt: "25px",
  },
  typography: {
    fontWeight: "bold",
    ml: "2px",
  },
  multiSelect: {
    width: "400px",
    textAlign: "start",
    mt: "10px",
    backgroundColor: "white",
  },
};
