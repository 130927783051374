/**
 * @author Narender - narender@au79consulting.com
 * @date 12-06-2024
 * @description Reusable button component for authentication related actions.
 */

import { Button } from "@mui/material";
import React from "react";
import appTheme from "../../assets/AppTheme/appTheme";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 12-06-2024
 * @description Reusable button component for authentication related actions.
 * @param { type, btnName, handleOnClick }
 * @return The rendered button component.
 */
function AuthButton({ type, btnName, handleOnClick }) {
  return (
    <Button
      fullWidth
      type={type}
      variant="contained"
      sx={styles.btnStyle}
      onClick={handleOnClick}
    >
      {btnName}
    </Button>
  );
}

export default AuthButton;

// Styling for the AuthButton component
const styles = {
  btnStyle: {
    marginTop: 3,
    backgroundColor: "#2196F3",
    color: "#ffffff",
    ...appTheme.commonBtnStyle,
  },
};
