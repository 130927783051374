import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import ReFirstScreen from "./ReFirstScreen";
import { useSelector } from "react-redux";

function ReCreateProject({
  open,
  close,
  page,
  rowsPerPage,
  recreateProject,
  selectedSortColumn,
  selectedSortOrder,
}) {
  const profileDetails = useSelector((state) => state.profileReducer);
  return (
    <div>
      <Dialog fullWidth maxWidth="lg" open={open}>
        <DialogTitle sx={styled.dialogTitle} id="customized-dialog-title">
          <Typography fontWeight="bold" variant="h5">
            Re-Create Project
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => close(false)}
          sx={styled.iconButton}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box>
            <ReFirstScreen
              page={page}
              rowsPerPage={rowsPerPage}
              selectedSortColumn={selectedSortColumn}
              selectedSortOrder={selectedSortOrder}
              recreateProject={recreateProject}
              close={close}
              profileDetails={profileDetails}
              key="first-screen"
            />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ReCreateProject;

const styled = {
  dialogTitle: { m: 0, p: 2, boxShadow: "3" },
  iconButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: "red",
  },
};
