/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @date 11-06-2024
 * @description Root reducer-> Combines all the individual reducer together for better readability and understanding.
 */
import { combineReducers } from "redux";
import spinnerReducer from "./spinner";
import authReducer from "./authReducer";
import profileReducer from "./profileReducer";
import countryAndPhoneCodeReducer from "./countryAndPhoneCodeReducer";
import userManagementReducer from "./userManagementReducer";
import fileHandlingReducers from "./fileHandlingReducers";
import settingsReducer from "./settingsReducer";
import adminSettingsReducer from "./adminSettingsReducer";
import vehicleFunctionReducer from "./vehicleFunctionReducer";
import powerTrainClassReducer from "./powerTrainClassReducer";
import vehicleClassReducer from "./vehicleClassReducer";
import architectureTypesReducer from "./architectureTypesReducer";
import swaggerReducer from "./swaggerDocs";
import projectConfigReducer from "./projectConfigReducer";
import labCarReducer from "./labCarReducer";
import typeApprovalReducer from "./typeApprovalReducer";
import legalRequirementsReducer from "./legalRequirementsReducer";
import softwareToSoftwareIntersectionReducer from "./softwareToSoftwareIntersectionsReducer";
import mftDetailsReducer from "./mftReducers";
import deliveryMarketReducers from "./deliveryMarketReducers";
import userDetailsReducer from "./userDetailsReducer";
import vehicleStatusReducer from "./vehicleStatusReducer";
import vehicleSystemsReducer from "./vehicleSystemsReducer";
import editRequestReducer from "./editRequestReducer";
import variantReducer from "./variantReducer";
const RootReducer = combineReducers({
  spinnerReducer: spinnerReducer,
  authReducer: authReducer,
  userManagementReducer: userManagementReducer,
  settingsReducer: settingsReducer,
  profileReducer: profileReducer,
  fileHandlingReducers: fileHandlingReducers,
  countryAndPhoneCodeReducer: countryAndPhoneCodeReducer,
  adminSettingsReducer: adminSettingsReducer,
  powerTrainClassReducer: powerTrainClassReducer,
  vehicleFunctionReducer: vehicleFunctionReducer,
  vehicleClassReducer: vehicleClassReducer,
  architectureTypesReducer: architectureTypesReducer,
  swagger: swaggerReducer,
  projectConfigReducer: projectConfigReducer,
  labCarReducer: labCarReducer,
  typeApprovalReducer: typeApprovalReducer,
  legalRequirementsReducer: legalRequirementsReducer,
  softwareToSoftwareIntersectionReducer: softwareToSoftwareIntersectionReducer,
  mftDetailsReducer: mftDetailsReducer,
  deliveryMarketReducers: deliveryMarketReducers,
  userDetailsReducer: userDetailsReducer,
  vehicleStatusReducer: vehicleStatusReducer,
  vehicleSystemsReducer: vehicleSystemsReducer,
  editRequestReducer: editRequestReducer,
  variantReducer: variantReducer,
});
export default RootReducer;
