/**
 * @author Narender - narender@au79consulting.com
 * @date 13-07-2024
 * @description : Defines action creators for update-user related actions.
 */
import customConsole from '../../config/customConsole';
import useAxios from '../../hooks/useAxios';

const baseURL = process.env.REACT_APP_BASE_URL;

export const UploadUserPicAction = ({ file }) => {
    let api = useAxios();

    return async (dispatch) => {
        return await api
            .post(`${baseURL}/UCM/files/upload`, file)
            .then(res => {
                if (!res?.data?.error) {
                    return { error: false, msg: res?.data?.message || res?.data?.msg, user_profile_pic_name: res?.data?.file_name };
                }
                else {
                    return { error: true, msg: res?.data?.message || res?.data?.msg };
                }
            })
            .catch(err => {
                customConsole("-- Upload User Profile API --")
                customConsole(err);

                return { error: true, msg: err?.response?.data?.message || err?.response?.data?.msg };
            })
    }
}


export const GetUserPicAction = ({ filename }) => {
    let api = useAxios();

    return async (dispatch) => {
        return await api
            .get(`${baseURL}/UCM/files/details`, {
                params: { filename },
                responseType: "blob",
            })
            .then(res => {
                if (res.status === 200) {
                    const blob = res?.data;

                    const picPath = URL.createObjectURL(blob);

                    return { error: false, user_profile_pic_path: picPath };
                }
                else {
                    customConsole(res);

                    return { error: true, msg: "Failed to get the image" };
                }
            })
            .catch(err => {
                customConsole("-- Get Profile Pic API --")
                customConsole(err);

                return { error: true, msg: err?.response?.data?.message || err?.response?.data?.msg };
            })
    }
}