/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @date 11-06-2024
 * @description This is the Virtual Labcar Page for the Application
 */
//Importing the required components/functions from the libraries

import actionType from "../actionTypes";

/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @date 11-06-2024
 * @description Action to load/start the spinner
 * @param {number} count
 * @returns dispatch an action to the reducer
 */
export const startLoading = (count) => {
  return {
    type: actionType.SPINNER_LOADING,
    payload: { totalLoadingCounts: count ? count : 1 },
  };
};
/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @date 11-06-2024
 * @description Action to stop the spinner
 * @param {} count
 * @returns dispatch an action to the reducer
 */
export const stopLoading = () => {
  return {
    type: actionType.SPINNER_LOADED,
  };
};
