/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 27-06-2024
 * @description Styling for the UserManagement component
 */

const styles = {
  container: {
    width: "100%",
    position: "relative",
  },
  tabsContainer: {
    position: "sticky",
    top: 0,
    borderBottom: 1,
    borderColor: "divider",
    backgroundColor: "white",
    zIndex: 1000,
  },
  tabs: {
    "&.Mui-selected": {
      fontWeight: 600,
      color: "black",
    },
  },
};

export default styles;
