import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react'
import customConsole from '../../config/customConsole';
import { GetSingleImages } from '../../redux/actions/actionHandler';

function ImageBox({ boxes }) {
    const [image, setImage] = useState("");
    useEffect(() => {
        customConsole("boxes.vehicle_class_details", boxes.vehicle_class_details);

        if (boxes?.vehicle_class_details && boxes.vehicle_class_details.length) {
            const fetchImage = async () => {
                try {
                    const imageDetails = { image: boxes.vehicle_class_details[0].image };
                    const resp = await GetSingleImages(imageDetails);

                    customConsole("imagesURL RESP: ");
                    customConsole(resp);

                    setImage(resp); // Update state with the fetched image
                } catch (error) {
                    customConsole("Error fetching image:", error);
                }
            };

            fetchImage(); // Call the async function
        }
    }, [boxes.vehicle_class_details[0].image]);
    return (
        <Box sx={styled.imgBox}>
            <img alt="" src={image} style={{ width: 46, height: 46 }} />
        </Box>
    )
}

export default ImageBox

const styled = {
    imgBox: { width: "56px", height: "56px" }
}