/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 12-08-2024
 * @description : Reusable pagination component
 */
import {
  Box,
  FormControl,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
} from "@mui/material";
import React from "react";

function CustomPaginationSmall({
  page,
  rowsPerPage,
  onPageChange,
  onPageSizeChange,
  hasMore,
  pageSizeOptions = [25, 50, 75, 100],
}) {
  return (
    <Box sx={styles.container}>
      {/* Page Size label */}
      {/* <Typography variant="caption">Page Size:</Typography> */}

      {/* Select page size */}
      <FormControl variant="outlined" size="small" style={{ minWidth: 50 }}>
        <Select
          size="small"
          variant="standard"
          value={rowsPerPage}
          onChange={onPageSizeChange}
          sx={{ fontSize: "0.85rem" }}
        >
          {pageSizeOptions.map((size) => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Pagination */}
      <Pagination
        count={hasMore ? page + 1 : page}
        page={page}
        onChange={onPageChange}
        size="small"
        renderItem={(item) => (
          <PaginationItem
            {...item}
            style={{
              display:
                item.page === page || item.type !== "page"
                  ? "inline-flex"
                  : "none",
            }}
          />
        )}
        color="primary"
      />
    </Box>
  );
}

export default CustomPaginationSmall;

// Styles
const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    gap: 0.5,
  },
};
