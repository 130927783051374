import React, { useState } from "react";
import { CustomUpdateDialog } from "../../../components";
import { Box, Stack, Typography } from "@mui/material";
import MutliSelectTextFieldVF from "../../../components/text-fields/MultiSelectTextFieldVF";

function EditVariantVehicleFunction({
  openVfDialog,
  handleClickEdit,
  setOpenVfDialog,
  editVF,
  setEditVF,
  selectedProjectData,
  selectedVariantData,
}) {
  console.log("selectedProjectData", selectedProjectData);

  //2.React useState
  const [vfData, setVfData] = useState([]);

  //3. React useEffect
  React.useEffect(() => {
    console.log(
      "selectedProjectData?.vehicle_function_details",
      selectedProjectData?.vehicle_function_details
    );
    if (selectedProjectData?.vehicle_function_details && selectedProjectData) {
      setVfData(selectedProjectData?.vehicle_function_details);
    }
  }, [selectedProjectData]);
  return (
    <div>
      <CustomUpdateDialog
        open={openVfDialog}
        onClose={() => {
          setOpenVfDialog(false);
        }}
        dialogTitle={"Add Vehicle Function"}
        dialogContent={
          <Stack spacing={2}>
            <Box sx={styled.box}>
              <Box sx={styled.secondaryBox}>
                <Typography sx={styled.topography}>
                  Select the Vehicle Function
                </Typography>
                <MutliSelectTextFieldVF
                  mutiple
                  size={"small"}
                  label={"Select Vehicle Function"}
                  optionsList={vfData}
                  selectedValues={editVF}
                  setSelectedValues={setEditVF}
                  sltFldStyle={styled.multiSelect}
                />
              </Box>
            </Box>
          </Stack>
        }
        onConfirm={handleClickEdit}
      />
    </div>
  );
}
const styled = {
  box: {
    mt: "10px",
  },
  secondaryBox: {
    mt: "25px",
  },
  typography: {
    fontWeight: "bold",
    ml: "2px",
  },
  multiSelect: {
    width: "400px",
    textAlign: "start",
    mt: "10px",
    backgroundColor: "white",
  },
};

export default EditVariantVehicleFunction;
