/**
 * @author Abdul Rahim M -  abdul@au79consulting.com
 * @date 11-06-2024
 * @description This is the Main Dashboard Layout common for Sidebar
 */

import {
  Box,
  CssBaseline,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { buttons } from "../../assets/app-theme/appColors";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import MuiDrawer from "@mui/material/Drawer";
import { sideBarData } from "./sideBarData";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useDispatch, useSelector } from "react-redux";
import profileReducer from "../../redux/reducers/profileReducer";
import settingsApis from "../../redux/actions/settings";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import profileActions from "../../redux/actions/profile";

function SideBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  //const profileDetails = useSelector((state) => state.settingsReducer);
  const profileDetails = useSelector((state) => state.profileReducer);
  const [menuOptions, setMenuOptions] = React.useState([]);

  React.useEffect(() => {
    dispatch(HandleApiActions({ ...profileActions.get_profile_details }));
  }, []);

  React.useEffect(() => {
    setMenuOptions(() =>
      profileDetails?.profile?.role_details?.access_to_pages
        ? profileDetails.profile.role_details.access_to_pages
        : []
    );
  }, [profileDetails]);

  // React.useEffect(() => {
  //   customConsole("profileDetailssssss", profileDetails);
  //   setMenuOptions(() =>
  //     profileDetails?.metaData?.menu_options
  //       ? profileDetails.metaData?.menu_options
  //       : []
  //   );
  // }, [profileDetails]);

  //1.useState
  const [open, setOpen] = React.useState(true);

  //3.Funtions
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleClickToPage = (to) => {
    navigate(to);
    // setOpen(!open);
  };

  // sideBarData.map((data, idx) => {
  // customConsole(`Index ${idx}`);
  //   customConsole(data.title);
  //   customConsole(menuOptions[idx]);
  //   customConsole(menuOptions.includes(data.title));
  // });

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          width: "100%",
          boxShadow: "3",
        }}
      >
        <CssBaseline />
        {!isMobile && (
          <Drawer variant="permanent" open={open} sx={{ position: "relative" }}>
            <Box
              sx={{
                display: "flex",
                height: "7%",
                //alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
              }}
            >
              <Box>
                <Box
                  sx={{
                    // position: "absolute",
                    // top: 2,
                    // right: open ? "25px" : 0,
                    // zIndex: 2,
                    backgroundColor: open ? "" : "white",
                  }}
                >
                  <IconButton
                    size="large"
                    sx={{
                      // background: open ? "" : "white",
                      // color: open ? "white" : "black",
                      color: "black",
                    }}
                    onClick={toggleDrawer}
                  >
                    {open ? (
                      // <img
                      //   alt=""
                      //   src={HideIcon}
                      //   style={{ width: "30px", height: "30px" }}
                      // />
                      <ArrowBackIosNewOutlinedIcon />
                    ) : (
                      <MenuIcon />
                    )}
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Divider
              sx={{
                height: 4,
                backgroundColor: "rgba(0, 0, 0, 0.12)",
              }}
            />
            <List component="nav" sx={{ marginLeft: open ? "0px" : "20px" }}>
              {sideBarData?.map((data, index) => {
                const titleSide = data.title.toUpperCase();
                if (menuOptions.includes(titleSide)) {
                  return (
                    <ListItemButton
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "start",

                        justifyContent: "start",
                        backgroundColor:
                          window.location.pathname === data.path
                            ? buttons.greenDefault
                            : "",
                        "&:hover": {
                          backgroundColor: buttons.greenHover,
                          color: "white",
                        },
                      }}
                      onClick={() => handleClickToPage(data.path)}
                    >
                      <ListItemIcon
                        sx={{
                          height: "2rem",
                          width: "2rem",
                          color:
                            window.location.pathname === data.path
                              ? "white"
                              : "black",
                        }}
                      >
                        {data.icon}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color:
                            window.location.pathname === data.path
                              ? "white"
                              : "inherit",
                        }}
                        primary={data.title}
                      />
                    </ListItemButton>
                  );
                } else {
                  return null;
                }
              })}
            </List>
            {open && (
              <Typography
                variant="body1"
                sx={{
                  textAlign: "left",
                  marginLeft: "20px",
                  marginTop: "50px",
                }}
              >
                Version: {process.env.REACT_APP_VERSION}
              </Typography>
            )}
          </Drawer>
        )}
      </Box>
    </div>
  );
}

export default SideBar;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: 260,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(0),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
        marginLeft: "-20px",
      },
    }),
  },
}));
