/**
 * @author Abdul Rahim M - abdul@au79consulting.com
 * @date 04-07-2024
 * @description Defines action creators for profile.
 */

import { toast } from "react-toastify";
import useAxios from "../../hooks/useAxios";
import actionType from "../actionTypes";

/**
 * @author Abdul Rahim M - abdul@au79consulting.com
 * @date 04-07-2024
 * @description Action creator for profile.
 * @param
 * @returns
 */

const profileActions = {
  upload_profile_pic: {
    api_name: "/UCM/files/upload",
    https_method: "POST",
    description: "Upload profile picture",
    action_type: actionType.UPLOAD_PROFILE_PIC,
  },
  get_profile_pic: {
    api_name: "/UCM/files/details",
    https_method: "GET",
    description: "Download profile picture",
    action_type: actionType.GET_PROFILE_PIC,
  },
  get_profile_details: {
    api_name: "/UCM/user/profile",
    https_method: "GET",
    description: "Get profile details",
    action_type: actionType.GET_PROFILE,
  },
  update_profile_details: {
    api_name: "/UCM/user/profile",
    https_method: "PUT",
    description: "Update profile details",
    action_type: actionType.GET_PROFILE,
  },
  get_coutry_regions: {
    api_name: "/UCM/user/countries",
    https_method: "GET",
    description: "Get countries and details",
    action_type: actionType.GET_COUNTRIES_PHONECODE,
  },
};
export default profileActions;
