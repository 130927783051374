/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 23-09-2024
 * @description : To give co-owner/editor/viewer access to the project/zones/ee component/software module
 */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import customConsole from "../../../config/customConsole";
import { HandleApiActions, labCar } from "../../../redux/actions";
import {
  Button,
  ButtonGroup,
  Paper,
  Typography,
  Divider,
  Grid,
  Box,
} from "@mui/material";
import {
  CustomButton,
  EEDetails,
  ModuleAccessTypes,
  ProjectDetails,
  SearchSelectTextField,
  SelectModules,
  SWMDetails,
} from "../../../components";
import ProjectAccessDrawer from "../ProjectAccessDrawer";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

function AccessControlTab({
  selectedProjectDetails,
  projectStatus,
  moduleTypes,
  accessRequestStatus,
}) {
  const dispatch = useDispatch();

  // New Requests Buttons
  const [selectedPaBtn, setSelectedPaBtn] = useState(moduleTypes?.PROJECT);

  // Co-owners state
  // const [coOwnersOptionsList, setCoOwnersOptionsList] = useState([]);
  // const [selectedCoOwner, setSelectedCoOwner] = useState(null);

  // Access type
  // const [selectedAtBtn, setSelectedAtBtn] = useState(accessTypes?.COOWNER);

  // Zones States
  const [zoneOptionsList, setZoneOptionsList] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedZoneDetails, setSelectedZoneDetails] = useState(null);

  // EE Components States
  const [zoneEEDetails, setZoneEEDetails] = useState([]);
  const [eeOptionsList, setEEOptionsList] = useState([]);
  const [selectedEE, setSelectedEE] = useState(null);
  const [selectedEEDetails, setSelectedEEDetails] = useState(null);

  // Software Modules States
  const [eeSwmDetails, setEESwmDetails] = useState([]);
  const [swmOptionsList, setSWMOptionsList] = useState([]);
  const [selectedSWM, setSelectedSWM] = useState(null);
  const [selectedSWMDetails, setSelectedSWMDetails] = useState(null);

  //
  const [reasonForAccess, setReasonForAccess] = useState("");

  // Access Drawer
  const [isAccessDrawerOpened, setIsAccessDrawerOpened] = useState(false);
  const [accessControlData, setAccessControlData] = useState({});

  const handleCloseDrawer = () => {
    setIsAccessDrawerOpened(false);
    setAccessControlData({});
  };

  const handleAccessBtnClick = (params) => {
    customConsole("handleAccessBtnClick: ");
    customConsole(params);

    setAccessControlData(params);
    setIsAccessDrawerOpened(true);
  };

  // Functions to handle data //
  // Get EE Components Details
  const getZoneEEDetails = async ({
    projectId,
    // zoneSequence,
    zonePosition,
  }) => {
    let apiDetails = labCar.get_ee_components_pa;

    customConsole({
      ...apiDetails,
      params: {
        project_id: projectId,
        // ...(zoneSequence && { zone_sequence: zoneSequence }),
        ...(zonePosition && { zone_position: zonePosition }),
        is_for_req_access: true,
      },
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          project_id: projectId,
          // ...(zoneSequence && { zone_sequence: zoneSequence }),
          ...(zonePosition && { zone_position: zonePosition }),
          is_for_req_access: true,
        },
      })
    );

    if (resp.error) {
      setZoneEEDetails([]);
    } else {
      setZoneEEDetails(resp.data);
    }
  };

  // Get Software Module Details
  const getEESwmDetails = async ({ projectId, eeId }) => {
    let apiDetails = labCar.get_sw_modules_pa;

    customConsole({
      ...apiDetails,
      params: {
        project_id: projectId,
        ee_id: eeId,
        is_for_req_access: true,
      },
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          project_id: projectId,
          ee_id: eeId,
          is_for_req_access: true,
        },
      })
    );

    if (resp.error) {
      setEESwmDetails([]);
    } else {
      setEESwmDetails(resp.data);
    }
  };

  /* UseEffects */
  useEffect(() => {
    // Make the states to default when the project is changed
    // access type buttons
    setSelectedPaBtn(moduleTypes?.PROJECT);
    // Zones
    setZoneOptionsList([]);
    setSelectedZone(null);
    setSelectedZoneDetails(null);
    // EE Components
    setEEOptionsList([]);
    setSelectedEE(null);
    setSelectedEEDetails(null);
    // Software Modules
    setSWMOptionsList([]);
    setSelectedSWM(null);
    setSelectedSWMDetails(null);
  }, [selectedProjectDetails]);

  useEffect(() => {
    // When the selected Zone is changed, make the EE Components state to default
    setEEOptionsList([]);
    setSelectedEE(null);
    setSelectedEEDetails(null);
  }, [selectedZone]);

  useEffect(() => {
    // When the selected Zone/EE Component is changed, make the Software Modules state to default
    setSWMOptionsList([]);
    setSelectedSWM(null);
    setSelectedSWMDetails(null);
  }, [selectedZone, selectedEE]);

  useEffect(() => {
    // let filteredCoOwnersOptionsList = selectedProjectDetails?.co_owner_details
    //   ?.map((el) => {
    //     if (el?.user_id) {
    //       return {
    //         value: el.user_id,
    //         label: `${el.name} - ${el.email}`,
    //       };
    //     }
    //     return null;
    //   })
    //   ?.filter(Boolean);
    // setCoOwnersOptionsList(filteredCoOwnersOptionsList);
    // // Select first co-owner by default
    // setSelectedCoOwner(selectedProjectDetails?.co_owner_details[0]?.user_id);

    // If Centralized project, Get the EE Components Details, When the project is selected
    if (
      selectedProjectDetails?.project_id &&
      !selectedProjectDetails?.is_zonal
    ) {
      getZoneEEDetails({
        projectId: selectedProjectDetails?.project_id,
      });
    }

    // Zones options list
    let filteredZoneOptionsList = selectedProjectDetails?.project_zones
      ?.map((el) => {
        return {
          // value: el?.sequence,
          value: el?.position,
          label: el?.zone_name,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));

    setZoneOptionsList(filteredZoneOptionsList);

    // Select the first zone, when the selected project is changed
    if (filteredZoneOptionsList?.length) {
      setSelectedZone(filteredZoneOptionsList[0]);
    }
  }, [selectedProjectDetails]);

  useEffect(() => {
    /* Update selected zone details based on the selected zone */
    let filteredSelectedZoneDetails =
      selectedProjectDetails?.project_zones?.find(
        // (el) => el?.sequence == selectedZone?.value
        (el) => el?.position == selectedZone?.value
      );
    setSelectedZoneDetails(filteredSelectedZoneDetails);

    // If Zonal project, Get the EE Components Details, When the zone is selected
    if (selectedProjectDetails?.project_id && selectedZone?.value) {
      getZoneEEDetails({
        projectId: selectedProjectDetails?.project_id,
        // zoneSequence: selectedZone?.value,
        zonePosition: selectedZone?.value,
      });
    }
  }, [selectedZone]);

  useEffect(() => {
    // EE Components Options List
    let filteredEEOptionsList = zoneEEDetails
      ?.map((el) => {
        return {
          value: el?.ee_id,
          label: el?.description,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));

    setEEOptionsList(filteredEEOptionsList);

    // Select the first ee component, when the selected zone is changed
    if (filteredEEOptionsList?.length) {
      setSelectedEE(filteredEEOptionsList[0]);
    }
  }, [zoneEEDetails]);

  useEffect(() => {
    // When EE Component is selected, Get the Software Module Details
    if (selectedProjectDetails?.project_id && selectedEE?.value) {
      getEESwmDetails({
        projectId: selectedProjectDetails?.project_id,
        eeId: selectedEE?.value,
      });
    }

    // Get Selected EE Component details
    let filteredSelectedEEDetails = zoneEEDetails?.find(
      (el) => el?.ee_id == selectedEE?.value
    );
    setSelectedEEDetails(filteredSelectedEEDetails);
  }, [selectedEE]);

  useEffect(() => {
    // Software Module Options List
    let filteredSWMOptionsList = eeSwmDetails
      ?.map((el) => {
        return {
          value: el?.swm_id,
          label: el?.name,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));

    setSWMOptionsList(filteredSWMOptionsList);

    // Select the first software module, when the selected ee component is changed
    if (filteredSWMOptionsList?.length) {
      setSelectedSWM(filteredSWMOptionsList[0]);
    }
  }, [eeSwmDetails]);

  useEffect(() => {
    // Get Selected Software Module Details
    let filteredEESwmDetails = eeSwmDetails?.find(
      (el) => el?.swm_id == selectedSWM?.value
    );
    setSelectedSWMDetails(filteredEESwmDetails);
  }, [selectedSWM]);

  return (
    // New Request
    <Paper elevation={4} sx={styles.nrContainer}>
      {/* Module Access Types */}
      <ModuleAccessTypes
        selectedProjectDetails={selectedProjectDetails}
        selectedPaBtn={selectedPaBtn}
        setSelectedPaBtn={setSelectedPaBtn}
        moduleTypes={moduleTypes}
      />

      {/* Request access */}
      <Paper elevation={4} sx={styles.nrMainContainer}>
        {/* Project Details */}
        <ProjectDetails
          selectedProjectDetails={selectedProjectDetails}
          projectStatus={projectStatus}
        />

        {/* Select Zone/EE Component/Software Module */}
        <SelectModules
          selectedProjectDetails={selectedProjectDetails}
          selectedPaBtn={selectedPaBtn}
          moduleTypes={moduleTypes}
          zoneOptionsList={zoneOptionsList}
          selectedZone={selectedZone}
          setSelectedZone={setSelectedZone}
          eeOptionsList={eeOptionsList}
          selectedEE={selectedEE}
          setSelectedEE={setSelectedEE}
          swmOptionsList={swmOptionsList}
          selectedSWM={selectedSWM}
          setSelectedSWM={setSelectedSWM}
        />

        {/* EE Component Details */}
        {selectedPaBtn === moduleTypes?.EE && selectedEE && (
          <EEDetails selectedEEDetails={selectedEEDetails} />
        )}

        {/* Software Module Details */}
        {selectedPaBtn === moduleTypes?.SWM && selectedSWM && (
          <SWMDetails selectedSWMDetails={selectedSWMDetails} />
        )}

        {/* Access type */}
        <Box sx={styles.acATContainer}>
          <Box sx={styles.acATbContainer}>
            <CustomButton
              endIcon={<KeyboardDoubleArrowRightIcon />}
              size={"small"}
              disabled={
                // Project Access Validation
                (selectedPaBtn === moduleTypes?.PROJECT &&
                  !selectedProjectDetails?.project_id) ||
                // Zone Access Validation
                (selectedPaBtn === moduleTypes?.ZONE && !selectedZone?.value) ||
                // EE Component Access Validation
                (selectedPaBtn === moduleTypes?.EE &&
                  (!selectedProjectDetails?.project_id ||
                    !selectedEEDetails?.ee_id)) ||
                // Software Module Access Validation
                (selectedPaBtn === moduleTypes?.SWM &&
                  (!selectedProjectDetails?.project_id ||
                    !selectedEEDetails?.ee_id ||
                    !selectedSWMDetails?.swm_id))
              }
              btnName={"Access Details"}
              handleOnClick={() =>
                handleAccessBtnClick({
                  access_type:
                    selectedPaBtn === moduleTypes?.PROJECT
                      ? moduleTypes?.PROJECT
                      : selectedPaBtn === moduleTypes?.ZONE
                      ? moduleTypes?.ZONE
                      : selectedPaBtn === moduleTypes?.EE
                      ? moduleTypes?.EE
                      : moduleTypes?.SWM,
                  project_id: selectedProjectDetails?.project_id,
                  zone_position: selectedZoneDetails?.position,
                  ee_id: selectedEEDetails?.ee_id,
                  swm_id: selectedSWMDetails?.swm_id,
                })
              }
              // btnStyle={styles.customButton}
            />
          </Box>
        </Box>
      </Paper>

      {isAccessDrawerOpened && (
        <ProjectAccessDrawer
          open={isAccessDrawerOpened}
          handleClose={handleCloseDrawer}
          data={accessControlData}
        />
      )}
    </Paper>
  );
}

export default AccessControlTab;

// Styles
const styles = {
  nrContainer: {
    width: "100%",
    display: "flex",
    p: 2,
    mt: 3,
    border: "1px solid grey",
  },
  nrMainContainer: {
    width: "100%",
    border: "1px solid grey",
    ml: 3,
    px: 2,
    pt: 0.6,
    pb: 2,
  },
  acATContainer: {
    mt: 4,
    mb: 0.4,
  },
  acATbContainer: {},
};
