/**
 * @author Narender - narender@au79consulting.com
 * @date 12-06-2024
 * @description Reusable text field component for authentication-related inputs.
 */

import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import appMeta from "../../assets/app-meta/appMeta";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 12-06-2024
 * @description Reusable text field component for authentication-related inputs.
 * @param label, placeHolder, type, value, setValue, valueError, setValueError, isAutoFocused, isRequired
 * @return The rendered text field component.
 */
function AuthTextField({
  label,
  placeHolder,
  type,
  fldType,
  variant,
  inputProps,
  passwordValue = "",
  value,
  setValue,
  valueError,
  setValueError,
  isAutoFocused,
  isRequired,
  txtFldStyle,
  onKeyDown,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  // Toggles the visibility of the password input.
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Handles changes in the text field's value.
  const handleChange = (e) => {
    const newValue = e.target.value;

    // Regular expression for a valid email address & otp
    const otpRegex = /^\d{0,6}$/;

    switch (fldType) {
      case appMeta.textFieldTypes.EMAIL:
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(newValue)) {
          setValueError("Invalid email address");
        } else {
          setValueError("");
        }
        setValue(newValue);
        break;
      case appMeta.textFieldTypes.PASSWORD:
        const passwdMinLength = 8;
        const lowerCaseRegex = /[a-z]/;
        const upperCaseRegex = /[A-Z]/;
        const numberRegex = /[0-9]/;
        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

        // Validate password in real-time
        if (
          newValue.length < passwdMinLength ||
          !lowerCaseRegex.test(newValue) ||
          !upperCaseRegex.test(newValue) ||
          !numberRegex.test(newValue) ||
          !specialCharRegex.test(newValue)
        ) {
          setValueError("Password is weak");
        } else {
          setValueError("");
        }
        setValue(newValue);
        break;
      case appMeta.textFieldTypes.AUTHPASSWORD:
        const passwdMinLength1 = 8;

        // Validate password in real-time
        if (newValue.length < passwdMinLength1) {
          // setValueError("Invalid password");
        } else {
          setValueError("");
        }
        setValue(newValue);
        break;
      case appMeta.textFieldTypes.CONFIRM_PASSWORD:
        if (newValue !== passwordValue) {
          setValueError("Passwords do not match.");
        } else {
          setValueError("");
        }
        setValue(newValue);
        break;
      case appMeta.textFieldTypes.OTP:
        // Validate otp in real-time
        if (otpRegex.test(newValue)) {
          setValue(newValue);
          if (newValue.length !== 6) {
            setValueError("Code must be a 6-digit number");
          } else {
            setValueError("");
          }
        } else if (newValue.length <= 6) {
          setValueError("Code must be a numeric value");
        }
        break;
      case appMeta.textFieldTypes.FULL_NAME:
        // Validate full name in real-time
        if (newValue?.trim() === "") {
          setValueError("Full Name is required");
          return;
        } else {
          setValueError("");
        }
        setValue(newValue);
        break;
      default:
        break;
    }
  };

  return (
    <Box>
      {/* <Typography variant="body1" sx={styles.txtLbs}>
        {label}
      </Typography> */}
      <TextField
        sx={txtFldStyle}
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        // autoComplete={}
        size="small"
        onChange={handleChange}
        label={label}
        variant={variant}
        value={value}
        placeholder={placeHolder}
        required={isRequired}
        fullWidth
        error={Boolean(valueError)}
        helperText={valueError}
        autoFocus={isAutoFocused}
        onKeyDown={onKeyDown}
        inputProps={inputProps}
        InputProps={
          fldType === appMeta.textFieldTypes.PASSWORD ||
          fldType === appMeta.textFieldTypes.AUTHPASSWORD ||
          fldType === appMeta.textFieldTypes.CONFIRM_PASSWORD
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : undefined
        }
      />
    </Box>
  );
}

export default AuthTextField;
