import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, Divider, IconButton, Paper, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import appTheme from "../../../assets/AppTheme/appTheme";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EEDependenciesDialog({
  description,
  open,
  handleClose,
  vehicleSystems,
  eeComponents,
}) {
  React.useEffect(() => {
    // console.log("-- DependenciesDialog --");
    // console.log({
    //   description,
    //   open,
    //   handleClose,
    //   vehicleSystems,
    //   eeComponents,
    // });
  }, []);
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          maxWidth: "350px",
          boxSizing: "border-box",
          padding: 0,
        },
      }}
    >
      <Box sx={styles.drawerContainer}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          EE Component
        </Typography>
        <Divider sx={styles.divider} />

        {/* Close Button */}
        <IconButton onClick={handleClose} sx={styles.closeButton}>
          <CloseIcon />
        </IconButton>
        {/* Vehicle Functions */}
        <Box sx={styles.drawerWrap}>
          <Paper sx={styles.paperDrawer}>
            <Typography
              variant="h6"
              sx={{
                marginBottom: 1,
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Vehicle Systems
            </Typography>
            <Divider sx={styles.divider} />

            {vehicleSystems?.map((data, idx) => {
              return (
                <Paper sx={styles.cardDrawer}>
                  <Typography
                    key={idx}
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      display: "flex",
                    }}
                  >
                    <Typography sx={{ marginLeft: 1, fontSize: "15px" }}>
                      <strong>{data?.abbreviation}</strong> : {data?.name}
                    </Typography>
                  </Typography>
                </Paper>
              );
            })}
          </Paper>
        </Box>
        {/* EE Components */}
        <Box sx={styles.drawerWrap}>
          {eeComponents.length ? (
            <Paper sx={styles.paperDrawer}>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 1,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Dependent EE Components
              </Typography>
              <Divider sx={styles.divider} />
              <Box
              // sx={{
              //   maxHeight: "80vH",
              //   padding: "10px",
              //   overflow: "auto",
              // }}
              >
                {eeComponents?.map((data, idx) => {
                  return (
                    <Paper sx={styles.cardDrawer}>
                      <Box
                        sx={{
                          border: "1px solid black",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        {/* EE Components */}
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              color: "black",
                              fontSize: "15px",
                              textDecoration: "underline",
                            }}
                          >
                            EE Component
                          </Typography>
                          <Box sx={{ gap: "10px" }}>
                            {[
                              {
                                header: "Name",
                                value: data?.description,
                              },
                              {
                                header: "Version",
                                value: data?.version,
                              },
                              {
                                header: "Assembly number",
                                value: data?.assembly_number,
                              },
                              {
                                header: "Supplier name",
                                value: data?.supplier_name,
                              },
                            ]?.map((det, key) => {
                              return (
                                <Typography
                                  key={key}
                                  sx={{
                                    fontSize: "13px",
                                  }}
                                >
                                  <strong>{det.header}:</strong> {det.value}
                                </Typography>
                              );
                            })}
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  );
                })}
              </Box>
            </Paper>
          ) : undefined}
        </Box>
      </Box>

      {/* <DialogActions>
        <Button onClick={handleClose} sx={{ ...appTheme.commonBtnStyle }}>Close</Button>
      </DialogActions> */}
    </Dialog>
  );
}

// Styles for the page
const styles = {
  filter: {
    position: "relative",
    top: "4px",
    right: "8px",
  },
  mainBox: {
    pt: 0,
    boxShadow: 3,
    border: "1px solid #e0e0e0",
    borderRadius: "10px",
    height: "100vh",
    pr: 2,
    pl: 2,
  },
  box: {
    height: "100vh",
    width: "100%",
  },
  paper: {
    boxShadow: 3,
    height: "62%",
    width: "auto",
  },
  drawerContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "400px",
    padding: 2,
    position: "relative",
  },
  closeButton: {
    color: "red",
    position: "absolute",
    top: "4px",
    right: "8px",
  },
  divider: {
    border: "1px solid lightgrey",
    margin: "8px 0",
    marginY: 1,
  },
  paperDrawer: {
    marginBottom: 2,
    padding: 2,
    boxShadow: 10,
    border: "1px solid #e0e0e0",
    borderRadius: "10px",
  },
  drawerWrap: {
    marginTop: 2,
  },

  dataTypographyKey: {
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "left",
    minWidth: "110px",
  },
  dataTypographyValue: {
    marginLeft: 1,
    fontSize: "14px",
    textAlign: "left",
  },
  dataBox: {
    display: "flex",
    flexDirection: "row",

    alignItems: "center",
    flexWrap: "wrap",
    gap: 1,
  },
  cardContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "100%",
  },
  card: {
    boxShadow: 3,
    width: "100%",
    height: "auto",
    marginBottom: 1,
    padding: 2,
  },
  cardContent: {
    display: "flex",
    height: "40px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dataTypography: {
    fontWeight: "bold",
    fontSize: "13px",
    textAlign: "left",
    minWidth: "120px",
  },
  dataTypographyCardKey: {
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "left",
    minWidth: "50px",
  },
  dataTypographyCardValue: {
    fontSize: "15px",
    textAlign: "left",
    minWidth: "50px",
  },
  btnsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    mb: 1,
    gap: 1,
  },
  cardDrawer: { margin: 1, padding: 2, boxShadow: 3 },
  paginationPaper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "5px",
  },
};
