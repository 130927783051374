/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 27-09-2024
 * @description : Shows different module to provide access
 */
import { Button, ButtonGroup, Paper } from "@mui/material";
import React from "react";
import { customAppColors } from "../../assets/app-theme/appColors";

function ModuleAccessTypes({
  selectedProjectDetails,
  selectedPaBtn,
  setSelectedPaBtn,
  moduleTypes,
  hideProject = false,
}) {
  return (
    <Paper elevation={0} sx={styles.container}>
      <ButtonGroup orientation="vertical">
        {[
          ...(!hideProject
            ? [{ value: moduleTypes?.PROJECT, label: "Project" }]
            : []),
          ...(selectedProjectDetails?.is_zonal
            ? [{ value: moduleTypes?.ZONE, label: "Zone" }]
            : []),
          { value: moduleTypes?.EE, label: "EE Component" },
          { value: moduleTypes?.SWM, label: "Software Module" },
        ].map((el) => (
          <Button
            sx={{
              color: "black",
              borderRadius: 0,
              borderColor: "#333",
              backgroundColor:
                // selectedPaBtn === el?.value ? "#26890D" : "transparent",
                selectedPaBtn === el?.value ? customAppColors?.green : "transparent",
              "&:hover": {
                borderColor: "#333",
                backgroundColor:
                  // selectedPaBtn == el?.value ? "#37b518" : "#f0f0f0",
                  customAppColors?.lightGreen,
              },
            }}
            onClick={() => setSelectedPaBtn(el?.value)}
          >
            {el?.label}
          </Button>
        ))}
      </ButtonGroup>
    </Paper>
  );
}

export default ModuleAccessTypes;

// Styles
const styles = {
  container: {
    display: "flex",
    width: "290px",
  },
};
