// import React from "react";
// import {
//   Checkbox,
//   FormControl,
//   InputLabel,
//   ListItemText,
//   MenuItem,
//   OutlinedInput,
//   Select,
//   TextField,
// } from "@mui/material";
// import customConsole from "../../config/customConsole";

// function MultiSelectTextField2({
//   size,
//   label,
//   optionsList,
//   selectedValues,
//   setSelectedValues,
//   sltFldStyle,
// }) {
//   const menuProps = {
//     PaperProps: {
//       style: {
//         maxHeight: 48 * 4.5 + 8,
//         width: 220,
//       },
//     },
//   };

//   // Handles change events for the multi-select dropdown.
//   const handleChange = (event) => {
//     customConsole(event.target.value);
//     const {
//       target: { value },
//     } = event;
//     setSelectedValues(
//       // If the value is a string, split it into an array; otherwise, use the value directly
//       typeof value === "string" ? value.join(",") : value
//     );
//   };

//   return (
//     <FormControl size={size} sx={sltFldStyle}>
//       <InputLabel>{label}</InputLabel>
//       <Select
//         variant="standard"
//         multiple
//         value={selectedValues}
//         onChange={handleChange}
//         //input={<OutlinedInput label={label} />}
//         renderValue={(selected) =>
//           selected
//             .map((id) => {
//               const option = optionsList.find((opt) => opt.id === id);
//               return option ? option.abbreviation : "";
//             })
//             .join(", ")
//         }
//         MenuProps={menuProps}
//       >
//         {optionsList?.map((data) => (
//           <MenuItem key={data?.id} value={data?.id}>
//             <Checkbox checked={selectedValues.indexOf(data?.id) > -1} />
//             <ListItemText primary={`${data?.name}-${data?.abbreviation}`} />
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   );
// }

// export default MultiSelectTextField2;

import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect } from "react";

function MultiSelectTextField2({
  size,
  label,
  optionsList,
  selectedValues,
  setSelectedValues,
  sltFldStyle,
}) {
  const handleChange = (event, newValue) => {
    const newSelectedIds = newValue?.map((option) => option?.id);
    setSelectedValues(newSelectedIds);
  };

  useEffect(() => {
    const returnValue = optionsList?.filter((option) =>
      selectedValues?.includes(option?.id)
    );
    console.log("---retun val--");
    console.log(returnValue);
    console.log("---1optionsList1---");
    console.log(optionsList);
    console.log("---1selectedValues1---");
    console.log(selectedValues);
  }, [optionsList, selectedValues]);

  return (
    <div>
      <Autocomplete
        disableClearable
        multiple
        id="tags-standard"
        options={optionsList || []}
        getOptionLabel={(option) => `${option?.name} - ${option?.abbreviation}`}
        value={
          optionsList?.filter((option) =>
            selectedValues?.includes(option?.id)
          ) || []
        }
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={label}
            sx={sltFldStyle}
            placeholder="Search"
          />
        )}
      />
    </div>
  );
}

export default MultiSelectTextField2;
