import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import {project_config, vlc_ta_lr} from "../../assets/tool-tip/data";

const combinedData = {...project_config, ...vlc_ta_lr};
function InfoButton({ infoKey, color}) {
    const title = combinedData[infoKey] || "No data";

  return (
    <div>
      <Box>
        <Tooltip title={title}>
          <IconButton>
            <InfoIcon style={{ color }} sx={{fontSize: "15px", margin: 0}}/>
          </IconButton>
        </Tooltip>
      </Box>
    </div>
  );
}

export default InfoButton;


