/**
 * @author Tahir Shaik
 * @description App Theme  Should Be declared Here and will use in entire app
 * @date 11-06-2024
 * @version 0.0.1 - Initial Release
 */

import { customAppColors } from "../app-theme/appColors";

const appTheme = {
  primaryColor: "primary",
  secondaryColor: "secondary",
  buttonTextSize: ".8rem",
  textSize: "18px",
  textHeadingSize: "20px",
  fontSizeSmall: ".8rem",
  fontSizeMedium: ".9rem",
  fontSizeLarge: "1rem",
  fontSizexl: "1.2rem",
  fontSizexs: ".7rem",
  commonBtnStyle: {
    color: "white",
    // color: "black",
    // backgroundImage: "linear-gradient( to bottom, rgb(7, 5, 69), rgb(103, 86, 133))",
    backgroundColor: customAppColors?.green,
    maxHeight: "30px",
    width: "fit-content",
    borderRadius: "20px",
    "&:hover": {
      // backgroundImage: "linear-gradient(#6b8df2,#021636)",
      backgroundColor: customAppColors?.lightGreen,
    },
    "&.Mui-disabled": {
      color: "rgba(255, 255, 255, 0.7)",
      // color: "#666666",
      backgroundImage: "linear-gradient(to bottom, rgb(169, 169, 169), rgb(211, 211, 211))", // Light grey gradient
      // backgroundColor: "#0F3705",
      cursor: "not-allowed",
    },
  },
};

export default appTheme;
