import React, { useEffect, useState } from "react";
import { CustomUpdateDialog } from "../../components";
import MutliSelectTextFieldVF from "../../components/text-fields/MultiSelectTextFieldVF";
import { Box, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { adminSettingsAPI, HandleApiActions } from "../../redux/actions";

function EditPowertrainClass({
  openPcDialog,
  setOpenPcDialog,
  editPC,
  setEditPC,
  handleClickEdit,
}) {
  const dispatch = useDispatch();
  const powerTrainClassDetails = useSelector(
    (state) => state.powerTrainClassReducer
  );

  const [pCData, setPCData] = useState([]);

  useEffect(() => {
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_powertrain_class }));
  }, []);

  useEffect(() => {
    console.log("powerTrainClassDetails", powerTrainClassDetails);
    if (
      powerTrainClassDetails &&
      powerTrainClassDetails?.powerTrainClassDetails &&
      powerTrainClassDetails?.powerTrainClassDetails?.data
    ) {
      setPCData(powerTrainClassDetails.powerTrainClassDetails.data);
    }
  }, [powerTrainClassDetails]);
  return (
    <div>
      <CustomUpdateDialog
        open={openPcDialog}
        onClose={() => {
          setOpenPcDialog(false);
        }}
        dialogTitle={"Add Powertrain Class"}
        dialogContent={
          <Stack spacing={2}>
            <Box sx={styled.box}>
              <Box sx={styled.secondaryBox}>
                <Typography sx={styled.topography}>
                  Select the Powertrain Class
                </Typography>
                <MutliSelectTextFieldVF
                  mutiple
                  size={"small"}
                  label={"Select Powertrain Class "}
                  optionsList={pCData}
                  selectedValues={editPC}
                  setSelectedValues={setEditPC}
                  sltFldStyle={styled.multiSelect}
                />
              </Box>
            </Box>
          </Stack>
        }
        onConfirm={handleClickEdit}
      />
    </div>
  );
}

export default EditPowertrainClass;

const styled = {
  box: {
    mt: "10px",
  },
  secondaryBox: {
    mt: "25px",
  },
  typography: {
    fontWeight: "bold",
    ml: "2px",
  },
  multiSelect: {
    width: "400px",
    textAlign: "start",
    mt: "10px",
    backgroundColor: "white",
  },
};
