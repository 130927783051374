/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 27-09-2024
 * @description : Shows software module details
 */
import React from "react";
import { Divider, Grid, Paper, Typography } from "@mui/material";

function SWMDetails({ selectedSWMDetails }) {
  return (
    <Paper elevation={2} sx={styles.detailsContainer}>
      <Typography sx={styles.heading}>Software Module Details</Typography>
      <Divider sx={styles.divider} />
      <Grid container>
        <Grid item xs={12} md={6} lg={4} mt={1.4}>
          <Typography variant="subtitle2" sx={styles.nrDetailsTxt}>
            <span style={styles.txtBold}>Name: </span>
            {selectedSWMDetails?.name || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4} mt={1.4}>
          <Typography variant="subtitle2" sx={styles.nrDetailsTxt}>
            <span style={styles.txtBold}>Version: </span>
            {selectedSWMDetails?.version || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4} mt={1.4}>
          <Typography variant="subtitle2" sx={styles.nrDetailsTxt}>
            <span style={styles.txtBold}>Summary: </span>
            {selectedSWMDetails?.summary || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4} mt={1.4}>
          <Typography variant="subtitle2" sx={styles.nrDetailsTxt}>
            <span style={styles.txtBold}>Description: </span>
            {selectedSWMDetails?.description || "-"}
          </Typography>
        </Grid>
        {/* <Grid item xs={12} md={6} lg={4} mt={1.4}>
          <Typography variant="subtitle2" sx={styles.nrDetailsTxt}>
            <span style={styles.txtBold}>Owner: </span>
            {selectedSWMDetails?.owner_details?.length &&
            selectedSWMDetails?.owner_details[0]?.name &&
            selectedSWMDetails?.owner_details[0]?.email
              ? `${selectedSWMDetails?.owner_details[0]?.name} - ${selectedSWMDetails?.owner_details[0]?.email}`
              : "-"}
          </Typography>
        </Grid> */}
      </Grid>
    </Paper>
  );
}

export default SWMDetails;

// Styles
const styles = {
  detailsContainer: {
    border: "1px solid grey",
    px: 2,
    pt: 0.4,
    pb: 2,
    my: 2,
  },
  nrDetailsTxt: {
    textAlign: "start",
  },
  txtBold: {
    fontWeight: "bold",
  },
  heading: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  divider: {
    mt: 0.4,
    mb: 1,
  },
};
