import React, { useEffect, useState } from "react";
import { CustomUpdateDialog, SelectTextField } from "../../components";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { adminSettingsAPI, HandleApiActions } from "../../redux/actions";

function AddMftDetails({
  selectMft,
  setSelectMft,
  openMftDialog,
  selectedProjectData,
  setOpenMftDialog,
  handleClickEdit,
}) {
  const dispatch = useDispatch();
  const mftDetails = useSelector((state) => state.adminSettingsReducer);

  console.log("mftDetails", mftDetails);
  //1.React useState
  const [mftData, setMftData] = useState([]);

  //2.useEffect
  useEffect(() => {
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_admin_settings }));
  }, []);
  useEffect(() => {
    console.log(
      "mftDetails",
      mftDetails?.adminSettings?.data?.vehicle_manufacturers
    );
    if (mftDetails?.adminSettings?.data?.vehicle_manufacturers?.length > 0) {
      const mftDataDetails =
        mftDetails?.adminSettings?.data?.vehicle_manufacturers?.map(
          (data, idx) => {
            return {
              name: data?.name,
              address: data?.address,
            };
          }
        );
      console.log("mftDataDetails", mftDataDetails);
      setMftData(mftDataDetails);
    }
  }, [mftDetails]);

  const handleChange = (e) => {
    setSelectMft(e.target.value);
  };
  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 220,
        overflowX: "auto", // Allow horizontal scrolling
        // whiteSpace: "nowrap", // Prevent text from wrapping to the next line
        overflowX: "hidden", // Hide horizontal overflow
        scrollbarWidth: "thin", // Firefox
        msOverflowStyle: "none", // IE and Edge
        "&::-webkit-scrollbar": {
          height: "8px", // Adjust scrollbar height
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#888", // Scrollbar thumb color
          borderRadius: "4px", // Scrollbar thumb rounded corners
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#f1f1f1", // Scrollbar track color
        },
      },
    },
  };

  console.log("selectMft", selectMft);
  return (
    <div>
      <CustomUpdateDialog
        onDisabled={selectMft ? false : true}
        open={openMftDialog}
        onClose={() => setOpenMftDialog(false)}
        dialogTitle={"Add Manufacturer Details"}
        dialogContent={
          <Stack spacing={2}>
            <Box sx={{ marginTop: "10px" }}>
              <FormControl
                variant={"standard"}
                fullWidth
                size={"small"}
                required
                sx={{ width: "270px" }}
              >
                <InputLabel>Add Manufacturer Details</InputLabel>
                <Select
                  value={selectMft}
                  onChange={handleChange}
                  MenuProps={menuProps}
                >
                  {mftData?.map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      sx={{
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                        lineHeight: 1.5,
                      }}
                    >
                      {option?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Stack>
        }
        onConfirm={handleClickEdit}
      />
    </div>
  );
}

export default AddMftDetails;
