import React, { useEffect, useState } from "react";
import { CustomUpdateDialog, SelectTextField } from "../../components";
import { Box, Stack } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  adminSettingsAPI,
  HandleApiActions,
  projectConfigAPI,
} from "../../redux/actions";

function UpdateDeliveryMarket({
  openDeliveryMarket,
  setOpenDeliveryMarket,
  boxes,
}) {
  const dispatch = useDispatch();

  const DMDetails = useSelector((state) => state.adminSettingsReducer);
  const [DMState, setDMState] = useState("");
  const [DMDetailsState, setDMDetailsState] = useState([]);

  useEffect(() => {
    dispatch(
      HandleApiActions({ ...adminSettingsAPI.get_admin_settings, shallowEqual })
    );
  }, []);
  useEffect(() => {
    console.log(
      "DMDetails--",

      DMDetails?.adminSettings?.data?.product_delivery_markets
    );
    if (DMDetails?.adminSettings?.data?.product_delivery_markets?.length > 0) {
      const filteredDetails =
        DMDetails?.adminSettings?.data?.product_delivery_markets?.map(
          (data) => {
            return {
              value: data,
              label: data,
            };
          }
        );
      console.log("filteredDetails", filteredDetails);
      setDMDetailsState(filteredDetails);
    }
  }, [DMDetails]);

  const handleClickEditStatus = (index) => {
    const reqObj = {
      project_id: parseInt(index?.project_id),
      delivery_market: DMState,
      current_time: moment().format(),
    };

    console.log("reqObj", reqObj);
    dispatch(
      HandleApiActions({
        ...projectConfigAPI.update_project,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...projectConfigAPI.get_project,
        })
      );
    });
    setOpenDeliveryMarket(false);
  };

  return (
    <div>
      <CustomUpdateDialog
        onDisabled={DMState ? false : true}
        open={openDeliveryMarket}
        onClose={() => setOpenDeliveryMarket(false)}
        dialogTitle={"Add Delivery Market"}
        dialogContent={
          <Stack spacing={2}>
            <Box sx={{ marginTop: "10px" }}>
              <SelectTextField
                required
                size={"small"}
                label={"Add Delivery Market"}
                optionsList={DMDetailsState}
                selectedValue={DMState}
                setSelectedValue={setDMState}
                variant={"standard"}
                sltFldStyle={{
                  width: "200px",
                }}
              />
            </Box>
          </Stack>
        }
        onConfirm={() => handleClickEditStatus(boxes)}
      />
    </div>
  );
}

export default UpdateDeliveryMarket;
