/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 15-06-2024
 * @description Styling for the Authentication component.
 */
const styles = {
    container: {
        backgroundColor: "black",
        position: "relative",
        height: "100vh",
        width: "100vW",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
    },
    bgImgContainer: {
        display: "flex",
        alignItems: { xs: "center", sm: "none" },
        justifyContent: { xs: "center", sm: "none" },
        position: "fixed",
        right: { xs: "0%", sm: "10%", lg: "10%" },
        maxWidth: "100vW",
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: { xm: "none", sm: "360px" },
        left: { md: "20%" },
        zIndex: 100,
        marginLeft: { xs: 0, md: "-50%" },
    },
};

export default styles;