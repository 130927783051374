import React from "react";
import { useLocation } from "react-router-dom";
import ViewDialogCopy from "./project_view/ViewDialogCopy";
import ProjectConfig from "./ProjectConfig";
import ViewDialogCopy1 from "./project_view/ViewDialogCopy1";

function RouteProject() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get("project_id");
  return (
    <div>
      {projectId ? (
        //<ViewDialogCopy projectId={projectId} /> // Pass the projectId as a prop if needed
        <ViewDialogCopy1 projectId={projectId} />
      ) : (
        <ProjectConfig />
      )}
    </div>
  );
}

export default RouteProject;
