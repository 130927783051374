/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 13-08-2024
 * @description : Reusable component to select or enter data.
 */
import React from "react";
import { TextField, Autocomplete } from "@mui/material";

function EnterSelectTextField({
  size,
  label,
  placeholder,
  optionsList,
  value,
  setValue,
  txtFldStyle,
}) {
  // Handle change in input value
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Autocomplete
      freeSolo
      disableClearable
      options={optionsList}
      value={value}
      onInputChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          size={size}
          label={label}
          placeholder={placeholder}
          variant="standard"
          sx={txtFldStyle}
        />
      )}
      onChange={(event, newValue) => setValue(newValue)}
    />
  );
}

export default EnterSelectTextField;
