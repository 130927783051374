/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 13-07-2024
 * @description : Custom Confirmation Dialog Component.
 */

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import appTheme from "../../assets/AppTheme/appTheme";

const CustomConfirmationDialog = ({
  open,
  onClose,
  dialogTitle,
  dialogContent,
  onConfirm,
  disableConfirmBtn = false,
}) => {
  return (
    <Dialog
      open={open}
      // onClose={onClose}
      fullWidth
      maxWidth="xs"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>
        <Button
          sx={{ ...appTheme.commonBtnStyle }}
          color="error"
          variant="contained"
          onClick={onClose}
          startIcon={<CloseIcon />}
        >
          Cancel
        </Button>
        <Button
          disabled={disableConfirmBtn}
          sx={{ ...appTheme.commonBtnStyle }}
          color="primary"
          variant="contained"
          onClick={onConfirm}
          startIcon={<CheckIcon />}
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomConfirmationDialog;
