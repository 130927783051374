// /**
//  * @author : Narender - narender@au79consulting.com
//  * @Date : 29-06-2024
//  * @description Reusable Multi-select dropdown component with checkboxes.
//  */
// import {
//   Checkbox,
//   FormControl,
//   InputLabel,
//   ListItemText,
//   MenuItem,
//   OutlinedInput,
//   Select,
// } from "@mui/material";
// import React from "react";

// function MultiSelectTextField3({
//   disabled,
//   size,
//   label,
//   optionsList,
//   selectedValues,
//   setSelectedValues,
//   sltFldStyle,
// }) {
//   const menuProps = {
//     PaperProps: {
//       style: {
//         maxHeight: 48 * 4.5 + 8,
//         width: 220,
//       },
//     },
//   };
//   const mapToZones = (details) => {
//     return details.map((item, index) => ({
//       position: item.zone_position,
//       zone_name: item.position_name,
//       sequence: index + 1,
//     }));
//   };

//   const zones = mapToZones(optionsList);

//   const handleChange = (event) => {
//     const selectedZoneNames = event.target.value;

//     const updatedSelections = zones.filter((zone) =>
//       selectedZoneNames.includes(zone.position)
//     );
//     setSelectedValues(updatedSelections);
//   };

//   return (
//     <FormControl fullWidth>
//       <InputLabel>Select Zones</InputLabel>
//       <Select
//         labelId="zone-selector-label"
//         variant="standard"
//         multiple
//         value={selectedValues.map((zone) => zone.position)}
//         onChange={handleChange}
//         renderValue={(selected) => (
//           <div>
//             {selected.map((zoneName) => (
//               <span key={zoneName}>{zoneName},</span>
//             ))}
//           </div>
//         )}
//       >
//         {zones.map((zone) => (
//           <MenuItem key={zone.position} value={zone.position}>
//             <Checkbox
//               checked={selectedValues.some(
//                 (selectedZone) => selectedZone.position === zone.position
//               )}
//             />
//             <ListItemText primary={`${zone.position} - ${zone.zone_name}`} />
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   );
// }

// export default MultiSelectTextField3;
import React from "react";
import { Autocomplete, Checkbox, TextField, ListItemText } from "@mui/material";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 29-06-2024
 * @description Reusable Multi-select dropdown component with checkboxes using Autocomplete.
 */
function MultiSelectTextField3({
  disabled,
  size,
  label,
  optionsList,
  selectedValues,
  setSelectedValues,
  sltFldStyle,
}) {
  // Map the options list to a format suitable for the Autocomplete component
  const mapToZones = (details) => {
    return details?.map((item, index) => ({
      position: item?.zone_position,
      zone_name: item?.position_name,
      sequence: index + 1,
    }));
  };

  const zones = mapToZones(optionsList);

  const handleChange = (event, newValue) => {
    const newSelectedIds = (newValue || [])?.map((option) => ({
      zone_name: option?.zone_name,
      position: option?.position,
      sequence: option?.sequence,
    }));
    setSelectedValues(newSelectedIds);
  };

  // Determine the currently selected values based on selectedValues
  const currentSelection = zones?.filter((zone) =>
    selectedValues?.some((selected) => selected?.zone_name === zone?.zone_name)
  );

  return (
    <Autocomplete
      disableClearable
      multiple
      disableCloseOnSelect
      options={zones || []}
      getOptionLabel={(option) => `${option.position} - ${option.zone_name}`}
      value={currentSelection}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) =>
        option?.zone_name === value?.zone_name
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="standard"
          size={size}
          disabled={disabled}
          style={sltFldStyle}
        />
      )}
    />
  );
}

export default MultiSelectTextField3;
