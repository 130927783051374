/**
 * @author Abdul Rahim M - abdul@au79consulting.com
 * @date 1-07-2024
 * @description Component for handling the Architecture Types.
 */

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

import React, { useState } from "react";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

import moment from "moment";
import adminSettingsAPI from "../../redux/actions/adminSetttingAPI";
import appTheme from "../../assets/AppTheme/appTheme";
import CustomTextFieldSP from "../../components/text-fields/CustomTextFieldSP";
import CustomAddDialog from "../../components/dialog-boxes/CustomAddDialog";
import customConsole from "../../config/customConsole";
import CustomButton from "../../components/buttons/CustomButton";
import { CustomTextFieldN, SelectTextField } from "../../components";

function ArchitectureTypes({ open, handleClick, architectureDetails }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [architectureName, setArchitectureName] = useState("");
  const [selectedValue, setSelectedValue] = useState(false);

  const dispatch = useDispatch();

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  }
  const handleDialogClose = () => {
    setIsDialogOpen(false);

    setArchitectureName("");
    setSelectedValue(false);
  }

  const isFormValid = () => {
    return (architectureName?.length > 0)
  }

  const handleAddATClick = () => {
    let currentTime = new Date().toISOString();

    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.add_architecture_types,
        params: {
          name: architectureName,
          is_zones_available: selectedValue,
          current_time: currentTime,
        },
        show_toast: true,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...adminSettingsAPI.get_admin_settings,
          show_toast: false,
        })
      );
    })

    handleDialogClose();
  }

  return (
    <div>
      <List sx={styled.list} component="nav">
        <ListItemButton onClick={() => handleClick("1")}>
          <ListItemText
            sx={styled.listItemText}
            primary="Architecture Types"
            primaryTypographyProps={styled.typographyProps}
          />
          {open === "1" ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>

        <Collapse in={open === "1"} timeout="auto" unmountOnExit appear>
          <Divider sx={styled.divider} />
          <List component="div" disablePadding sx={styled.listMain}>
            <Box sx={styled.tblFun}>
              <CustomButton
                size={"medium "}
                variant={"contained"}
                btnName={"Add "}
                startIcon={<AddIcon />}
                handleOnClick={handleDialogOpen}
                btnStyle={{
                  ml: 2,
                }}
              />
            </Box>
            <Box sx={styled.architectureStyle}>
              {architectureDetails && architectureDetails?.length ? (
                architectureDetails.map((data, index) => {
                  return (
                    <>
                      <Box
                        key={index}
                        sx={styled.architectureBox}
                        backgroundColor="green"
                      >
                        <Typography padding="8px">{data?.name}</Typography>

                        <Divider orientation="vertical" />
                      </Box>
                    </>
                  );
                })
              ) : (
                <Typography sx={styled.notFound}>
                  Architecture Types not found
                </Typography>
              )}
            </Box>
          </List>
        </Collapse>
      </List>

      <CustomAddDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        dialogTitle={"Add Architecture Type"}
        dialogContent={
          <Stack spacing={4} mb={2} width="220px">
            <CustomTextFieldN
              size={"small"}
              label={"Architecture Name*"}
              placeholder={"Architecture Name"}
              type={"text"}
              value={architectureName}
              setValue={setArchitectureName}
              variant={"standard"}
            />
            <SelectTextField
              size={"small"}
              label={"Is Zones Available*"}
              optionsList={[{
                value: true,
                label: "True",
              },
              {
                value: false,
                label: "False",
              }]}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              variant={"standard"}
            />
          </Stack>
        }
        onConfirm={handleAddATClick}
        onDisabled={!isFormValid()}
      />
    </div>
  );
}

export default ArchitectureTypes;

const styled = {
  list: {
    width: "100%",
    maxWidth: "100%",
    marginTop: "15px",
    backgroundColor: "whitesmoke",
    boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.2)",
    borderRadius: "0px",
  },
  listItemText: {
    textAlign: "start",
    color: "black",
  },
  typographyProps: {
    fontWeight: 600,
    fontSize: "18px",
  },
  divider: {
    margin: "10px",
    height: "2px",
  },
  listMain: {
    backgroundColor: "whitesmoke",
    margin: "10px",
    padding: "10px",
    borderRadius: "10px",
    overflowY: "auto",
    maxHeight: "500px",
  },
  boxMain: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "5px",
  },
  architectureStyle: {
    display: "flex",
    flexWrap: "wrap",
    gap: 2,
    marginTop: "10px",
    alignItems: "center",
    justifyContent: "center",
  },
  architectureBox: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "0px",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontWeight: "bold",
  },
  close: {
    color: "white",
    marginLeft: "10px",
    "&:hover": { color: "white" },
  },
  notFound: {
    color: "red",
    fontSize: "12px",
    fontWeight: "bold",
  },
  tblFun: {
    mb: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};
