import customConsole from "../../config/customConsole";
import actionType from "../actionTypes";

const initialState = {
  error: false,
  msg: "",
  variantInfo: [],
};

const variantReducer = (state = initialState, action) => {
  customConsole(action);
  switch (action.type) {
    case actionType.ADD_VARIANTS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GET_VARIANTS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        variantInfo: action.payload.error ? [] : action.payload.data,
      };

    case actionType.UPDATE_VS_VARIANT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.UPDATE_VS_VEHICLE_FUNCTION:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.UPDATE_VS_VEHICLE_COMPONENTS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.RESET_PROJECT_CONFIG:
      return initialState;
    default:
      return state;
  }
};

export default variantReducer;
