/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 29-06-2024
 * @description Reusable Multi-select dropdown component with checkboxes.
 */
import {
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React from "react";

const CustomMenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 220,
      overflowX: "auto", // Allow horizontal scrolling
      // whiteSpace: "nowrap", // Prevent text from wrapping to the next line
      overflowX: "hidden", // Hide horizontal overflow
      scrollbarWidth: "thin", // Firefox
      msOverflowStyle: "none", // IE and Edge
      "&::-webkit-scrollbar": {
        height: "8px", // Adjust scrollbar height
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888", // Scrollbar thumb color
        borderRadius: "4px", // Scrollbar thumb rounded corners
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1", // Scrollbar track color
      },
    },
  },
};

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 29-06-2024
 * @description Reusable Multi-select dropdown component with checkboxes.
 * @param { size, label, optionsList, selectedValues, setSelectedValues, sltFldStyle }
 * @return The rendered multi-select dropdown component.
 */
function MultiSelectTextFieldN({
  size,
  label,
  optionsList,
  selectedValues,
  setSelectedValues,
  sltFldStyle,
}) {
  // Handles change events for the multi-select dropdown.
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValues(
      typeof value === "string" ? value.split(",").map(Number) : value
    );
  };

  return (
    <FormControl variant="standard" size={size} sx={sltFldStyle}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={selectedValues}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) =>
          selected
            .map(
              (id) =>
                optionsList.find((option) => option.id === id)?.abbreviation
            )
            .join(", ")
        }
        MenuProps={CustomMenuProps}
      >
        {optionsList?.map((data) => (
          <MenuItem
            key={data.id}
            value={data.id}
            sx={{
              whiteSpace: "normal",
              wordBreak: "break-word",
              lineHeight: 1.5,
            }}
          >
            <Checkbox checked={selectedValues.indexOf(data.id) > -1} />
            <ListItemText primary={`${data.abbreviation} - ${data.name}`} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultiSelectTextFieldN;
