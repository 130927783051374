/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 01-09-2024
 * @description Dashboard API details to perform a particular action
*/
import actionType from "../actionTypes";

const dashboardAction = {
    // Get tickets status
    get_tickets_status: {
        api_name: "/PCM/dashboard/tickets/status",
        https_method: "GET",
        action_type: actionType.GET_TICKETS_STATUS,
        description: "",
    },
    get_dash_board_status: {
        api_name: "/PCM/dashboard/status",
        https_method: "GET",
        action_type: actionType.GET_DASH_BOARD_STATUS,
        description: ""
    },
    get_dash_board_activities: {
        api_name: "/PCM/dashboard/recent_activities",
        https_method: "GET",
        action_type: actionType.GET_DASH_BOARD_ACTIVITY,
        description: ""
    },
    get_tickets: {
        api_name: "/ACM/intersections/tickets",
        https_method: "GET",
        action_type: actionType.GET_TICKETS,
        description: "Get Tickets",
    },
}
export default dashboardAction;