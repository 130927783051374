/**
 * @author Bharathan - bharathan@au79consulting.com
 * @date 17-08-2024
 * @description This is Page to Update ticket status
 */

//Importing the required components/functions from the libraries
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { CustomUpdateDialog } from "../../../components";
import { HandleApiActions } from "../../../redux/actions/actionHandler";
import { useDispatch, useSelector } from "react-redux";
import SelectTextField from "../../../components/text-fields/SelectTextField";
import moment from "moment";
import settingsApis from "../../../redux/actions/settings";
import typeApprovalAPI from "../../../redux/actions/typeApprovalAPI";

//Component to handle update ticket status
function UpdateTicketStatus({
  openStatus,
  boxes,
  setOpenStatus,
  intersectionId,
}) {
  //Status code for tickets
  const ticketStatus = {
    100: "DRAFT",
    200: "CREATED",
    201: "IN_REVIEW",
    300: "APPROVED",
    40: "REJECTED",
    500: "CANCELLED",
  };

  //Diapatch and Selector function to handle redux store
  const dispatch = useDispatch();
  const ticketStatusDetails = useSelector(
    (state) => state.settingsReducer?.metaData?.ticket_status
  );

  const ticketStatusList = Object.keys(ticketStatusDetails);

  // State hooks for managing component state
  const [selectTS, setSelectTS] = useState("");
  const [ticketData, setTicketData] = useState([]);

  //useEffect to handle get app meta data
  useEffect(() => {
    dispatch(HandleApiActions({ ...settingsApis.get_app_meta_data }));
  }, []);

  //useEffect to handle ticket status
  useEffect(() => {
    let ticketStatusData = ticketStatusList?.map((el, idx) => {
      return {
        value: idx,
        label: formatString(el),
      };
    });
    setTicketData(ticketStatusData);
  }, [ticketStatusDetails]);

  //useEffect to set ticket status
  useEffect(() => {
    setSelectTS(ticketStatus[boxes?.status]);
  }, [boxes]);

  function formatString(str) {
    let result = str.charAt(0).toUpperCase() + str.slice(1);
    result = result
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return result;
  }

  //Function to handle edit status
  const handleClickEditStatus = (index) => {
    const reqObj = {
      intr_id: parseInt(index),
      status: parseInt(ticketStatusDetails[ticketStatusList[selectTS]]),
      current_time: moment().format(),
    };
    dispatch(
      HandleApiActions({
        ...typeApprovalAPI.update_project_status_tickets,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...typeApprovalAPI.get_tickets,
          params: Number(
            intersectionId?.row?.allData?.project_details?.project_id
          ),
        })
      );
    });
    setOpenStatus(false);
  };

  return (
    <div>
      <CustomUpdateDialog
        open={openStatus}
        onClose={() => setOpenStatus(false)}
        dialogTitle={"Update Status"}
        dialogContent={
          <Stack spacing={2}>
            <Box sx={{ marginTop: "10px" }}>
              <SelectTextField
                required
                size={"small"}
                label={"Ticket Status"}
                optionsList={ticketData}
                selectedValue={selectTS}
                setSelectedValue={setSelectTS}
                variant={"standard"}
                sltFldStyle={{
                  width: "200px",
                }}
              />
            </Box>
          </Stack>
        }
        onConfirm={() => handleClickEditStatus(intersectionId?.row?.intr_id)}
      />
    </div>
  );
}

export default UpdateTicketStatus;
