/**
 * @author Abdul Rahim M-abdul@au79consulting.com
 * @date 20-07-2024
 * @description  Project Config API documentation Apis
 */

import actionType from "../actionTypes";
const projectConfigAPI = {
  get_project: {
    api_name: "/PCM/project/details",
    https_method: "GET",
    action_type: actionType.GET_PROJECT,
    description: "Get the Admin Settings",
  },
  add_project: {
    api_name: "/PCM/project/create",
    https_method: "POST",
    action_type: actionType.ADD_PROJECT,
    description: "Create Project",
  },
  update_project: {
    api_name: "/PCM/project/details",
    https_method: "PUT",
    action_type: actionType.UPDATE_PROJECT,
    description: "Update Project",
  },
  update_project_status: {
    api_name: "/PCM/project/status",
    https_method: "PUT",
    action_type: actionType.UPDATE_PROJECT_STATUS,
    description: "Update Project Status",
  },
  // Change logs
  get_project_change_logs: {
    api_name: "/PCM/project/change-logs",
    https_method: "GET",
    action_type: actionType.GET_PROJECT_CHANGE_LOGS,
    description: "",
  },

  get_users_list: {
    api_name: "/UCM/user/all",
    https_method: "GET",
    action_type: actionType.GET_USERS_DETAILS_LIST,
    description: "",
  },
  get_variant_change_logs: {
    api_name: "/PCM/project/variant/change-logs",
    https_method: "GET",
    action_type: actionType.GET_VARIANT_CHANGE_LOGS,
    description: "",
  },

  //Variant Details
  get_variant_details: {
    api_name: "/PCM/project/variants",
    https_method: "GET",
    action_type: actionType.GET_VARIANTS,
    description: "Get the Variant Details",
  },
  add_variant: {
    api_name: "/PCM/project/variant",
    https_method: "POST",
    action_type: actionType.ADD_VARIANTS,
    description: "Add the Variant Details",
  },
  update_vehicle_status: {
    api_name: "/PCM/project/variant/status",
    https_method: "PUT",
    action_type: actionType.UPDATE_VS_VARIANT,
    description: "Update the VS Variant Details",
  },
  update_vehicle_function: {
    api_name: "/PCM/project/variant/vf",
    https_method: "PUT",
    action_type: actionType.UPDATE_VS_VEHICLE_FUNCTION,
    description: "Update the VF Variant Details",
  },
  update_vehicle_components: {
    api_name: "/PCM/project/variant/components",
    https_method: "PUT",
    action_type: actionType.UPDATE_VS_VEHICLE_COMPONENTS,
    description: "Update the VC Variant Details",
  },
};

export default projectConfigAPI;
