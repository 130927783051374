/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 25-07-2024
 * @description Reducer function for handling lab car related actions and state changes.
 */
import actionType from '../actionTypes';

const initialState = {
    projectDetailsMindMap: [],
    projectDetails: [],
    eeComponentsList: [],
    eeComponentsMainList: [],
    eeComponentsLinkedList: [],
    eeComponentsMainLinkedList: [],
    swModulesList: [],
    swModulesMainList: [],
    swModulesLinkedList: [],
    selectedProject: null,
    selectedVariant: null,
}

const labCarReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_PROJECT_DETAILS_MIND_MAP:
            return {
                ...state,
                projectDetailsMindMap: action.payload.error ? [] : action.payload.data,
            }
        case actionType.GET_PROJECT_DETAILS:
            return {
                ...state,
                projectDetails: action.payload.error ? [] : action.payload.data,
            }
        case actionType.GET_EE_COMPONENTS:
            return {
                ...state,
                eeComponentsList: action.payload.error ? [] : action.payload.data,
            }
        case actionType.GET_SW_MODULES:
            return {
                ...state,
                swModulesList: action.payload.error ? [] : action.payload.data,
            }
        case actionType.GET_MAIN_SW_MODULES:
            return {
                ...state,
                swModulesMainList: action.payload.error ? [] : action.payload.data,
            }
        case actionType.GET_LINKED_SW_MODULES:
            return {
                ...state,
                swModulesLinkedList: action.payload.error ? [] : action.payload.data,
            }
        case actionType.GET_EE_COMPONENTS_MAIN:
            return {
                ...state,
                eeComponentsMainList: action.payload.error ? [] : action.payload.data,
            }
        case actionType.GET_EE_COMPONENTS_LINKED:
            return {
                ...state,
                eeComponentsLinkedList: action.payload.error ? [] : action.payload.data,
            }
        case actionType.GET_EE_COMPONENTS_MAIN_LINKED:
            return {
                ...state,
                eeComponentsMainLinkedList: action.payload.error ? [] : action.payload.data,
            }
        case actionType.SET_SELECTED_PROJECT:
            return {
                ...state,
                selectedProject: action.payload,
            }
        case actionType.SET_SELECTED_VARIANT:
            return {
                ...state,
                selectedVariant: action.payload,
            }
        case actionType.RESET_LAB_CAR_DATA:
            return initialState;
        default:
            return state;
    }
}

export default labCarReducer;
  