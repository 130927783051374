/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 19-07-2024
 * @description : To Link SWM To SWM & EE TO EE.
 */
import React, { useEffect, useState } from "react";
import {
  Box,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  CustomButton,
  LinkModuleType,
  ProjectVariantDetails,
  SearchSelectTextField,
  SelectTextFieldN,
} from "../../../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions, labCar } from "../../../redux/actions";

import customConsole from "../../../config/customConsole";
import softwareIntersectionAPI from "../../../redux/actions/softwareIntersectionActions";
import LinkSWM from "./LinkSWM";
import LinkEE from "./LinkEE";
import { useLocation } from "react-router-dom";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 19-07-2024
 * @description : Page for handling the software modules linking.
 * @param : selectedProjectId, selectedProjectIsZonal, selectedProjectZonesList, selectedProjectName, selectedProjectArchitecture, selectedProjectTradeMark, selectedProjectVehiclePlatform, isLinkSoftwareModulesScreen, setIsLinkSoftwareModulesScreen,
 * @return : The rendered Link Software Modules page.
 */
function LinkModulesMain({
  selectedProjectId,
  selectedProjectZonesList,
  isLinkSoftwareModulesScreen,
  selectedProjectIsZonal,
  setSelectedView,
  setIsLinkSoftwareModulesScreen,
  selectedProjectName,
  selectedProjectArchitecture,
  selectedProjectTradeMark,
  selectedProjectVehiclePlatform,
  selectedProjectTotalZones,
  selectedProjectTotalEEComponents,
  selectedProjectDetails,
  selectedSingleVariantDetails,
  projectEECount,
}) {
  const linkModuleType = { SWM: 1, EE: 2 };

  const [selectedLinkModuleType, setSelectedLinkModuleType] = useState(
    linkModuleType?.EE
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryLinkModuleType = queryParams.get("linkModuleType");

  useEffect(() => {
    if (queryLinkModuleType) {
      setSelectedLinkModuleType(Number(queryLinkModuleType));
    }
  }, [queryLinkModuleType]);

  return (
    <Box sx={styles.mainContainer}>
      {/* Back button */}
      <Box sx={styles.topContainer}>
        <Box sx={styles.backBtnContainer}>
          <CustomButton
            startIcon={<ArrowBackIcon />}
            size={"medium"}
            variant={"contained"}
            btnName={"Back"}
            handleOnClick={() => {
              setIsLinkSoftwareModulesScreen(false);
              setSelectedView("CAR");
            }}
          />
        </Box>

        {/* Link Software modules button */}
        <Typography
          variant="h6"
          fontWeight={"bold"}
          align="center"
          sx={styles.linkSoftwareModulesTxt}
        >
          Link{" "}
          {selectedLinkModuleType === linkModuleType?.SWM
            ? "Software Modules"
            : "EE Components"}
        </Typography>
      </Box>

      {/* Project details */}
      <ProjectVariantDetails
        projectDetails={selectedProjectDetails}
        variantDetails={selectedSingleVariantDetails}
      />

      <Box
        sx={{
          mt: 2.5,
        }}
      >
        <LinkModuleType
          selectedLMBtn={selectedLinkModuleType}
          setSelectedLMBtn={setSelectedLinkModuleType}
          linkModuleType={linkModuleType}
          queryLinkModuleType={queryLinkModuleType}
        />
      </Box>

      {/* Linking Software modules or EE Components */}
      <Box sx={{ mt: 1.4 }}>
        {/* Linking Software modules */}
        {selectedLinkModuleType === linkModuleType?.SWM ? (
          <LinkSWM
            selectedSingleVariantDetails={selectedSingleVariantDetails}
            selectedProjectId={selectedProjectId}
            selectedProjectZonesList={selectedProjectZonesList}
            isLinkSoftwareModulesScreen={isLinkSoftwareModulesScreen}
            selectedProjectIsZonal={selectedProjectIsZonal}
            setIsLinkSoftwareModulesScreen={setIsLinkSoftwareModulesScreen}
          />
        ) : (
          <>
            {/* Linking EE Components */}
            <LinkEE
              selectedSingleVariantDetails={selectedSingleVariantDetails}
              selectedProjectId={selectedProjectId}
              selectedProjectZonesList={selectedProjectZonesList}
              isLinkSoftwareModulesScreen={isLinkSoftwareModulesScreen}
              selectedProjectIsZonal={selectedProjectIsZonal}
            />
          </>
        )}
      </Box>
    </Box>
  );
}

export default LinkModulesMain;

// Styles
const styles = {
  mainContainer: {
    mb: "70px",
    width: "100%",
  },
  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mx: 1,
    mb: 2,
  },
  backBtnContainer: {
    display: "flex",
    alignItems: "center",
  },
  prjDetailsContainer: {
    px: 2,
    py: 1,
    mx: 4,
    mt: 2,
  },
  prjDetailsTxt: {
    textAlign: "start",
    pl: 2,
  },
  txtBold: {
    fontWeight: "bold",
  },
  linkSoftwareModulesTxt: {
    flexGrow: 1,
  },
  searchSelectTextField: {
    width: "215px",
    backgroundColor: "white",
  },
};
