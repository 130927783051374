/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @date 11-06-2024
 * @description This is the Main Dashboard Layout common for all pages and Redirections
 */
import React from "react";
import { Outlet } from "react-router-dom";
import CustomLoaderDialog from "../components/dialog-boxes/CustomSpinner";
import { useDispatch, useSelector } from "react-redux";
import "./dashboard.css";
import TopBar from "./TopBar/TopBar";
import SideBar from "./SideBar/SideBar";
import { Box } from "@mui/material";

import "./dashboard.css";
import { GetAppMetaData } from "../redux/actions/settingsAction";

import { HandleApiActions } from "../redux/actions/actionHandler";
import profileActions from "../redux/actions/profile";
import settingsApis from "../redux/actions/settings";
import customConsole from "../config/customConsole";

export default function Dashboard() {
  const dispatch = useDispatch();
  const [openProgressBar, setOpenProgressBar] = React.useState(false);
  const spinnerState = useSelector((state) => state.spinnerReducer);

  //Use Effect to auto close or open the spinner when we made an API call & Got the response

  React.useEffect(() => {
    dispatch(HandleApiActions({ ...settingsApis.get_app_meta_data }));
  }, []);

  React.useEffect(() => {
    dispatch(HandleApiActions({ ...profileActions.get_profile_details }));
  }, []);

  React.useEffect(() => {
    // customConsole(spinnerState);
    // customConsole(process.env.REACT_APP_ENV === "DEV");
    if (spinnerState.isLoading) {
      setOpenProgressBar(true);
    } else {
      setTimeout(() => {
        setOpenProgressBar(false);
      }, 500);
    }
  }, [spinnerState]);
  return (
    <Box
      sx={{
        width: "100vW",
        height: "100vH",
        maxWidth: "100vw",
        maxHeight: "100vh",
        overflow: "hidden",
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        // backgroundColor: "lightgrey", // For visibility, you can remove or change this
      }}
    >
      <TopBar />

      <div className="app">
        {/* <Box> */}
        <SideBar />
        {/* </Box> */}
        <Box
          sx={{
            width: "95vw",
            height: "95vh",
            maxWidth: "95vW",
            maxHeight: "95vH",
            overflow: "auto",
            margin: "10px",

            // width: "100vw",
            // maxWidth: "100vW",
            // marginY: "10px",
            // backgroundColor: "#F5F5F5",
          }}
          className="container"
        >
          <main className="content">
            <Outlet />
          </main>
        </Box>
        {openProgressBar && (
          <CustomLoaderDialog
            open={openProgressBar}
            handleClose={() => setOpenProgressBar(false)}
          />
        )}
      </div>
    </Box>
  );
}
