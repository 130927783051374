import { Typography } from "@mui/material";
import React from "react";

function Templates() {
  return (
    <div>
      {" "}
      <Typography sx={{ marginTop: "20px" }}>
        Templated related content here
      </Typography>
    </div>
  );
}

export default Templates;
